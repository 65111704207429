import React from 'react';
import PageIndex from './PageIndex.component';

export default function Page(props) {

    return (
        <div className="container-fluid">
            <div className="row">
                <section className="col-lg">
                    <PageIndex user={props.user}
                        showEmployeeDetails={true}
                        allowPrint={true}
                    />
                </section>
            </div>
        </div>
    )
}