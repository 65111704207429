import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Swal from 'sweetalert2';

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'


export default function AddNewEmployee({ showCreateModal, setShowCreateModal, fetchListService}) {

 //Close Modal
  const handleClose = () => {
    setShowCreateModal(false);
    };
    
  
  const [getEmployeeID, setEmployeeID] = useState('');
  const [EmployeeIDExist, setEmployeeIDExist ] = useState(false);

  const [getSurname, setSurname] = useState('');
  const [getFirstname, setFirstname] = useState('');
  const [getMiddlename, setMiddlename] = useState('');

  

    const [getStatus, setStatus] = useState('');
    //Display of offcices
    const [fetchOffices, setOffices] = useState([]);
    // get the office ID
    const [officeId, setOfficeId] = useState('');
    //Display of vcant position 
    const [OptionPosition, setOptionPosition] = useState([]);
    // get the id position 
    const [postNo, setPostNo] = useState('');

    const [fetchPosDetails, setPosDetails] = useState({
    postno:'',
    AMOUNT: '', 
    SEQUENCE:'',
    STEP:'',
    SGRADE: '', 
  
    });
  
  
  // Validation for username existing
  const handleUsernameChange = async (event) => {
    const EmpNO = event.target.value  
    setEmployeeID(EmpNO);

    try {
      const response = await axios.get(`http://localhost:8000/api/checkEmpNO/${EmpNO}`);
        setEmployeeIDExist(response.data.exist);
    } catch (error) {
      // console.error("Error checking username:", error);
      setEmployeeIDExist(false);
    }
  }
  
  
  
  const handleSelectedStatus = (e) => {

    setStatus(e.target.value);

    setOfficeId("");
     setPostNo("");
     setPosDetails({
      ...fetchPosDetails,
      SGRADE: '',
    });
    
  };
  
  

      // Office
    useEffect(() => {

    const getOffice = async () => {
      await axios.get(`http://localhost:8000/api/Listoffices`).then((res) => {
         
        setOffices(res.data.offices)
      
      })
      }
      getOffice()
   }, [])

    
    // get the office ID    
     const handleOfficeChange = (e) => {
        setOfficeId(e.target.value);

    };
    
    useEffect(() => {
    if (getStatus === 'Permanent' || getStatus === 'Co-Terminous' ) {

        if (officeId) {
          const getPosition = async () => {
            await axios.get(`http://localhost:8000/api/vcantPosition/${officeId}`).then((res) => {
        
            setOptionPosition(res.data.vcantPosition)

            })
          }
          getPosition()
      }
      else {
          setOptionPosition([]);
          // setSalaryPos("")
      }

    }
    else if (getStatus === 'JO' || getStatus === 'CA'|| getStatus === 'CON') {
        const getPosition = async () => {
            await axios.get(`http://localhost:8000/api/vcantPositionNonReg`).then((res) => {
        
            setOptionPosition(res.data.vcantPositionNonReg)

            })
          }
          getPosition()
        }
    else {
      setOptionPosition([]);
      // setSalaryPos([]);
    }
      
      
  }, [officeId, getStatus]);     

   const handlePositionChange = (e) => {
    setPostNo(e.target.value);
  }



  // position Details
  useEffect(() => {

     if (getStatus === 'Permanent' || getStatus === 'Co-Terminous' ) {
      
        if (officeId && postNo) {
          axios.get(`http://localhost:8000/api/PositionDetails/${officeId}/${postNo}`).then((res) => {
              
            setPosDetails(res.data.salaryVcantPosition[0]);
            
          })
        }
        else {
           setPosDetails("")
        }
   
    } else if (getStatus === 'JO' || getStatus === 'CA'|| getStatus === 'CON')
    {
        setPosDetails("")
    }
    else {
      setPosDetails("")
    }

  }, [officeId, postNo, getStatus])

 const handleSalaryChange = (e) => {
    setPosDetails(fetchPosDetails =>({...fetchPosDetails, [e.target.name]: e.target.value}))
  }



  //------------------------------------------------------
  //INSERT FUNCTION


  const [error, setError] = useState([]);

  const submitEmployee = async (e) => {
    e.preventDefault();

    await axios.post(`http://localhost:8000/api/NewEmployee`, {
      OFFCODE: officeId,
      POSTNO: postNo, 
      SURNAME: getSurname,
      FINAME: getFirstname, 
      MIDNAME: getMiddlename,
      SEQUENCE: fetchPosDetails.SEQUENCE,
      STEP: fetchPosDetails.STEP, 
      ESTA: getStatus,
      IDNO: getEmployeeID, 
    }).then((res) => {

      
        if (res.data.status === 422) {
        Swal.fire({
            icon: 'error',
            title: 'All fields are required',
            })
        setError(res.data.errors);

        }
    else {
            Swal.fire({
                icon: "success",
                title: res.data.message,
            }).then((res) => {
              // fetchListService();
              setShowCreateModal(false);

              setEmployeeID('');
              setFirstname('');
              setMiddlename('');
              setSurname('');
              setStatus('');
              setOfficeId('');
              setPostNo('');
              setPosDetails('');
            })
        }
    })

    
  }

    
  return (
    <>

    <Modal show={showCreateModal}  backdrop="static"  keyboard={false}  size="xl">
        <Modal.Header >
          <Modal.Title>Create New Employee</Modal.Title>
        </Modal.Header>
            <Form onSubmit={submitEmployee}>
              <Modal.Body>
        
                <div className="form-row">
                    <div className="form-group col-md-3">
                      <label>Employee IDNO:</label>      
                      <input type="text" className={`form-control ${error.IDNO ? 'is-invalid' : EmployeeIDExist ? 'is-invalid' : ''}`} id="EmployeeID_id" name="EmployeeID" value={getEmployeeID} onChange={handleUsernameChange} />
                      <div  className="invalid-feedback"> { error.IDNO ? error.IDNO : EmployeeIDExist ? 'Employee IDNO is already Exist' : ''} </div>
                  </div> 
                </div>

                <div className="form-row">
                    <div className="form-group col-md-4">
                    <label>First Name:</label>
                      <input type="text" className={`form-control ${error.FINAME ? 'is-invalid' : ''}`} id="FNAME_id" name="FNAME" value={getFirstname} onChange={(e) => setFirstname(e.target.value)} />
                      <div  className="invalid-feedback"> { error.FINAME } </div>
                  </div> 
                  <div className="form-group col-md-4">
                    <label>Middle Name:</label>
                      <input type="text" className={`form-control ${error.MIDNAME ? 'is-invalid' : ''}`} id="MNAME_id" name="MNAME" value={getMiddlename} onChange={(e) => setMiddlename(e.target.value)} />
                      <div  className="invalid-feedback"> { error.MIDNAME } </div>
                  </div> 
                  <div className="form-group col-md-4">
                    <label>Last Name:</label>
                      <input type="text" className={`form-control ${error.SURNAME ? 'is-invalid' : ''}`} id="LNAME_id" name="LNAME" value={getSurname} onChange={(e) => setSurname(e.target.value)} />
                      <div  className="invalid-feedback"> { error.SURNAME } </div>
                  </div> 
                </div>

                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label>Employment Status</label>
                      <select className={`form-control ${error.ESTA ? 'is-invalid' : ''}`} id="id_emp_status" name="emp_status" value={getStatus} onChange={handleSelectedStatus}>
                      <option value="">Choose...</option>
                      <option value="Permanent">Permanent</option>
                      <option value="CA">Casual</option>
                      <option value="JO">Job Order</option>
                      <option value="Co-Terminous">Co-Terminous</option> 
                      <option value="CON">Consultant</option>
                    </select>
                    <div  className="invalid-feedback"> { error.ESTA } </div>
                  </div>
                  
            
                  <div className="form-group col-md-7">
                    <label>Select Office</label>
                    <select className={`form-control ${error.OFFCODE ? 'is-invalid' : ''}`} id="officesId_id" name="officeId" value={officeId} onChange={handleOfficeChange}>
                      <option value="">Choose...</option>
                      {
                      fetchOffices.map((option, index) => (
                        <option key={index} value={option.offcode} >{option.offdesc }</option>
                      ))

                      }
                    </select>
                    <div  className="invalid-feedback"> { error.OFFCODE } </div>
                  </div>
                  
                </div>
                {/* form-row */}
            
                <div className="form-row">
              
                  <div className="form-group col-md-7">
                    <label>Position</label>
                    
                        <select className={`form-control ${error.POSTNO ? 'is-invalid' : ''}`} id="position_id" name="position" value={postNo ?? ""} onChange={handlePositionChange} >
                            <option value="">Choose...</option>
                                {
                                OptionPosition.map((option, index) => (     
                                    <option key={index} value={option.postno} >{option.Position}</option>
                                    ))
                                }
                        </select>
                      <div  className="invalid-feedback"> { error.POSTNO } </div>
                  </div>  
                  
                  <div className="form-group col-md-2">
                    <label>Salary Grade</label>
                      <input type="text" className="form-control" id="Sgrade_id" name="SGRADE" value={fetchPosDetails.SGRADE ?? ""} onChange={handleSalaryChange} disabled={true}   />
                  </div>
            
                          
                </div>

              </Modal.Body>
        
              <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>
                  Close
                </Button>
                <Button type='submit' variant="success">Save</Button>
              </Modal.Footer>
            </Form>
    </Modal>
          

    </>
  )
}
