import React, { useEffect, useState, useRef } from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import axios from '../../../services/axios';
import Swal from 'sweetalert2';
import DatePicker, { DateObject } from "react-multi-date-picker";
import Modal from 'react-bootstrap/Modal';
import ReactToPrint from 'react-to-print';

import EmployeeList from '../../Global/EmployeeList.component';
import CreateDivisionModal from './CreateDivisionModal.component';

export default function Page(props) {

    const [employeeList, setEmployeeList] = useState([]);
    const [divisionList, setDivisionList] = useState([]);
    const [selectedDivision, setSelectedDivision] = useState('');
    const [selectedEmployee, setSelectedEmployee] = useState([]);

    const [showDivisionModal, setShowDivisionModal] = useState(false);
    const openDivisionModal = () => setShowDivisionModal(true);
    const closeDivisionModal = () => setShowDivisionModal(false);

    const fetchEmployeeList = async () => {
        await axios.post(`/api/office/query/${props.user.office.offcode}`, { employee: true, leavecredits: false, cto: false }).then(({ data }) => {
            //console.log('employee list', data);
            setDivisionList(data.office.divisions);
            setEmployeeList(data.office.employees);
        })
    }

    useEffect(() => {
        fetchEmployeeList();
    }, []);

    const handleSelectChange = (event, setFunction) => {
        setFunction(event.target.value);
    };

    const deleteDivision = async () => {
        if (selectedDivision != "") {
            Swal.fire({
                icon: 'info',
                text: 'Processing...',
                showConfirmButton: false
            });
            await axios.post(`/api/division/delete`, {
                selectedDivision
            }).then(({ data }) => {
                if (data.result) {
                    setSelectedDivision(0);
                    fetchEmployeeList();
                    Swal.update({
                        icon: "success",
                        text: data.message,
                        showConfirmButton: true
                    })
                } else {
                    Swal.update({
                        icon: "error",
                        text: data.message,
                        showConfirmButton: true
                    })
                }
            }).catch((error) => {
                Swal.update({
                    icon: "error",
                    text: error.response.data.message,
                    showConfirmButton: true
                })
            })
        } else {
            Swal.fire({
                icon: "warning",
                text: "Please select the division."
            });
        }
    }

    const assignEmployees = async () => {
        //console.log(selectedDivision, selectedEmployee);
        if (selectedDivision != "" && selectedEmployee.length > 0) {
            Swal.fire({
                icon: 'info',
                text: 'Processing...',
                showConfirmButton: false
            });
            await axios.post(`/api/division/assign`, {
                selectedDivision,
                selectedEmployee
            }).then(({ data }) => {
                if (data.result) {
                    setSelectedEmployee([]);
                    fetchEmployeeList();
                    Swal.update({
                        icon: "success",
                        text: data.message,
                        showConfirmButton: true
                    })
                } else {
                    Swal.update({
                        icon: "error",
                        text: data.message,
                        showConfirmButton: true
                    })
                }
            }).catch((error) => {
                Swal.update({
                    icon: "error",
                    text: error.response.data.message,
                    showConfirmButton: true
                })
            })
        } else {
            Swal.fire({
                icon: "warning",
                text: "Please select the division and employees to be assigned to the division."
            });
        }
    }

    // useEffect(() => {
    //     console.log(selectedDivision);
    // }, [selectedDivision])

    return (
        <>
            <Modal show={showDivisionModal} onHide={closeDivisionModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Division</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateDivisionModal
                        user={props.user}
                        closeModal={closeDivisionModal}
                        setDivisionList={setDivisionList}
                    />
                </Modal.Body>
            </Modal>

            <div className="d-flex flex-row h-100 w-100">
                <div style={{ width: '18%' }}>
                    <EmployeeList
                        divisionList={divisionList}
                        employeeList={employeeList}
                        selectedEmployee={selectedEmployee}
                        selectEffect={setSelectedEmployee}
                        allowMultiple={true}
                        unselectOnEmployeeListChange={true}
                        show={{ position: true, division: true }}
                    />
                </div>
                <div className="d-flex flex-column" style={{ width: '82%' }}>
                    <Row className="p-1 m-0 cus-color-2">
                        <Col className="ps-1 pe-1" sm="auto">
                            <Form.Select className=""
                                onChange={event => { handleSelectChange(event, setSelectedDivision) }}
                            >
                                <option value=''>Select Division</option>
                                {Object.entries(divisionList).map(([key, div], i) => {
                                    return <option key={i} value={div.id}>{div.name}</option>
                                })}
                            </Form.Select>
                        </Col>
                        <Col className="ps-1 pe-1" sm="auto">
                            <Button size="md" onClick={openDivisionModal}>Create Division</Button>
                        </Col>
                        <Col className="ps-1 pe-1" sm="auto">
                            <Button variant="danger" size="md" onClick={deleteDivision}>Delete Division</Button>
                        </Col>
                    </Row>
                    <Row className="p-2">
                        {/* <Col sm="auto">
                            <Form.Select className="query-input"
                                onChange={event => { handleSelectChange(event, setSelectedDivision) }}
                            >
                                <option value=''>Select Division</option>
                                {Object.entries(divisionList).map(([key, div], i) => {
                                    return <option key={i} value={div.id}>{div.name}</option>
                                })}
                            </Form.Select>
                        </Col> */}
                        <Col className="" sm="auto">
                            <Button variant="primary" size="md" onClick={assignEmployees}>Assign To Division</Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}