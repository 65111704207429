import React from 'react';
import PageIndex from './PageIndex.component.js';

export default function Page(props) {
    return (
        <PageIndex user={props.user}
            showEmployeeDetails={true}
        //allowPrint={true}
        />
    )
}