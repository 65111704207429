import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from '../../services/axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom'
import { DateObject } from "react-multi-date-picker";
import { ListGroup } from 'react-bootstrap';

export default function Comments(props) {
    const navigate = useNavigate();

    const [commentList, setCommentList] = useState([]);
    const [comment, setComment] = useState('');

    useEffect(() => {
        fetchComments()
    }, [])

    const fetchComments = async () => {
        await axios.post(`/api/post/comments`, {
            post: props.postID
        }).then(({ data }) => {
            //console.log(data);
            setCommentList(data.comments);
        }).catch((error) => {
            Swal.fire({
                text: error.response.data.message,
                icon: "error"
            })
        })
    }

    const saveComment = async () => {
        if (comment !== '') {
            await axios.post(`/api/comment/save`, {
                post: props.postID,
                comment: comment
            }).then(({ data }) => {
                setCommentList(data.comments);
                setComment('');
            }).catch((error) => {
                Swal.fire({
                    text: error.response.data.message,
                    icon: "error"
                })
            })
        }
    }

    return (
        <>
            <Row className="comment-module-container-title">
                <Col>
                    <Row>
                        <Col>
                            <Form.Label>
                                Comments
                            </Form.Label>
                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <Form.Control className="comment-text-area" as="textarea" row={1} placeholder="Enter comment..." value={comment} onChange={(e) => {
                                setComment(e.target.value);
                            }} />
                        </Col>
                    </Row>
                    <Row className="mt-2"><Col className="text-right"><Button variant="primary" onClick={(e) => {
                        saveComment();
                    }}>Save</Button></Col></Row>
                </Col>
            </Row>
            <hr />
            <Row className="comment-module-container-comments overflow-auto">
                <Col className="">
                    <ListGroup className="emp-list-container" variant="flush">
                        {commentList.map((comment, key) => {
                            let comD = Number(new Date(comment.created_at));
                            let com = comment.comment;
                            if (comment.comment_type === 'system') {
                                com = com.split(';');
                            }
                            return (
                                <ListGroup.Item key={key}>
                                    <Form.Label className="ms-0 me-2 mb-0 mt-0">
                                        {`${comment.user.FINAME} ${comment.user.MIDNAME ? comment.user.MIDNAME.split(" ").map(word => word[0].toUpperCase() + ".").join("") : ""} ${comment.user.SURNAME}`}
                                        <label style={{ fontSize: '10px' }} className="text-secondary p-0 m-1 me-0 mt-0 mb-0">
                                            {new DateObject(comD).format('MMMM DD, YYYY hh:mm A')}
                                        </label>
                                    </Form.Label>
                                    <br />
                                    {comment.comment_type !== 'system' ? com :
                                        (<label style={{ fontSize: '14px' }} className="m-0 p-0 text-primary">
                                            {com.map((c, key) => (
                                                <React.Fragment key={key}>
                                                    {key !== 0 ? <br /> : ''}
                                                    {c}
                                                </React.Fragment>
                                            ))}
                                        </label>)
                                    }
                                </ListGroup.Item>
                            )
                        })}
                    </ListGroup>
                </Col>
            </Row>
        </>
    )
}