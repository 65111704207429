import React, { useEffect, useState, useRef } from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from '../../../services/axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom'
import DatePicker, { Calendar, DateObject } from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';

import EmployeeListSearchable from '../../Global/EmployeeListSearchable.component';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';

export default function CreateModal(props) {

    const [selectedEmployee, setSelectedEmployee] = useState([]);
    const [empJOCert, setEmpJOCert] = useState([]);
    const prevSelectedEmployee = useRef(selectedEmployee);
    //const [pgoToNo, setPgoToNo] = useState('');
    const [event, setEvent] = useState('');
    const [station, setStation] = useState('');
    const [toDate, setToDate] = useState([]);
    const [itinerary, setItinerary] = useState([""]);
    const [travel, setTravel] = useState(0);
    const [charge, setCharge] = useState(0);
    const [chargeSpecify, setChargeSpecify] = useState('');
    const [reqAtt, setReqAtt] = useState(false);

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [fileChkFail, setFileChkFail] = useState([]);
    const fileInputRef = useRef();

    const handleFileChange = (event) => {
        let files = Array.from(event.target.files);
        const label = document.getElementById('fileLabel');

        const allowedFileType = ["jpg", "jpeg", "png", "pdf", "docx", "doc"];
        const maxSize = 2 * 1024 * 1024;

        if (files.length > 0) {
            let newSelectedFiles = [...selectedFiles];
            let newFileChkFail = [];
            files.map((file, key) => {
                let ext = file.name.split('.').pop();
                let size = file.size;
                let dupe = newSelectedFiles.filter(ff => { return ff.name === file.name }).length ? true : false;
                //console.log("dupe", dupe);
                if (allowedFileType.includes(ext) && size <= maxSize && !dupe) {
                    newSelectedFiles.push(file);
                } else {
                    newFileChkFail.push(`${file.name} : ${allowedFileType.includes(ext) ? "" : `${ext} file type is now allowed${(size <= maxSize || dupe) ? "" : ", "}`}${size <= maxSize ? "" : `file size is larger than 2mb${dupe ? ", " : ""}`}${dupe ? "duplicate file name was chosen, remove existing file or rename the current file" : ""}`);
                }
            })
            setSelectedFiles(newSelectedFiles);
            setFileChkFail(newFileChkFail);
        } else {
            //console.log("Nein Files");
        }
    }

    useEffect(() => {
        const added = selectedEmployee.filter(emp => !(prevSelectedEmployee.current.find(prevEmp => emp.id === prevEmp.id)));
        const removed = prevSelectedEmployee.current.filter(prevEmp => !(selectedEmployee.find(emp => prevEmp.id === emp.id)));
        let updateCert = [...empJOCert];

        if (added.length) {
            added.forEach(add => {
                if (add.ESTA === "JO") {
                    updateCert.push({
                        empId: add.id,
                        ESTA: add.ESTA,
                        name: add.name,
                        purpose: [""],
                        availability: false,
                        task: false,
                        presence: false,
                        others: false,
                        others_specify: ""
                    });
                }
            })
        }

        if (removed.length) {
            removed.forEach(remove => {
                if (remove.ESTA === "JO") {
                    updateCert = updateCert.filter(cert => cert.id !== remove.id);
                }
            });
        }

        prevSelectedEmployee.current = selectedEmployee;

        setEmpJOCert(updateCert);
    }, [selectedEmployee]);

    const setCheckSelectedEmployee = (emps) => {
        if (selectedEmployee !== emps) {
            setSelectedEmployee(emps);
        }
    }

    const createTravelOrder = async () => {
        //console.log(selectedEmployee);
        //console.log(empJOCert);
        if (!validateFields()) {
            const formData = new FormData()
            //formData.append('selectedEmployee', JSON.stringify(selectedEmployee))
            selectedEmployee.forEach((emp) => {
                formData.append('selectedEmployees[]', emp.id)
            });
            empJOCert.forEach((cert) => {
                formData.append('employeeCert[]', JSON.stringify(cert))
            });
            formData.append('event', event)
            toDate.forEach((date) => {
                formData.append('dates[]', date.format("YYYY-MM-DD"))
            });
            formData.append('station', station)
            itinerary.forEach((itin) => {
                formData.append('itineraries[]', itin)
            });
            formData.append('travel', travel)
            formData.append('charge', charge)
            formData.append('charge_specify', chargeSpecify)
            formData.append('office', props.user.office.offcode)
            formData.append('att_required', reqAtt ? 1 : 0)

            for (let i = 0; i < selectedFiles.length; i++) {
                formData.append('document[]', selectedFiles[i]);
            }

            Swal.fire({
                showConfirmButton: false,
                text: selectedFiles.length > 0 ? `File Uploading: 0%` : 'Saving...'
            });
            await axios.post(`/api/travelorder/save`, formData, {
                onUploadProgress: (data) => {
                    if (selectedFiles.length > 0) {
                        Swal.update({
                            text: `File Uploading: ${Math.round((data.loaded / data.total) * 100)}%`
                        });
                    }
                },
            }).then(({ data }) => {
                Swal.update({
                    icon: "success",
                    text: data.message
                })
                props.closeCreateFetchTO();
            }).catch((error) => {
                Swal.update({
                    icon: "error",
                    text: error.response.data.message,
                    showConfirmButton: true
                })
            })
        }
    }

    const validateFields = () => {
        let invalid = false;
        let requiredFields = document.getElementById("travel-order-form").querySelectorAll("[required]");
        requiredFields.forEach((el) => {
            if (el.value == 0 || el.value == "") {
                invalid = true;
                el.classList.add("is-invalid");
            } else {
                el.classList.remove("is-invalid");
            }
        })
        let participant = document.getElementById("participants-list");
        if (selectedEmployee.length === 0) {
            invalid = true;
            participant.classList.add("text-danger");
        } else {
            participant.classList.remove("text-danger");
        }

        let dates = document.getElementById("dates-list");
        if (toDate.length === 0) {
            invalid = true;
            dates.classList.add("text-danger");
        } else {
            dates.classList.remove("text-danger");
        }

        return invalid;
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Travel Order Request</Modal.Title>
            </Modal.Header>
            <Modal.Body className="travel-order-create-modal-body">
                <Row id="travel-order-form" className="travel-order-create-modal-body-to overflow-auto">
                    <Col className="travel-order-view-modal-body-to overflow-auto" sm={6}>
                        <Row>
                            <Col className="mb-2" sm={12}>
                                <Form.Label className="fw-bold">EVENT/PURPOSE</Form.Label>
                                <Form.Control name="eventInput" as="input" value={event} required onChange={(event) => {
                                    setEvent(event.target.value);
                                }} />
                            </Col>
                            <Col className="mb-2" sm={12}>
                                <Form.Label className="fw-bold mb-0 me-1">Attachments (If needed)</Form.Label>
                                <Form.Control name="attachments" as="input" type="file" ref={fileInputRef} hidden onChange={handleFileChange} multiple={true} />
                                <Button className="" variant="primary" size="sm" onClick={() => fileInputRef.current.click()}>
                                    Upload File
                                </Button>
                            </Col>
                            <Col className="" sm={12}>
                                {selectedFiles.map((file, key) => {
                                    return (
                                        <div className="card ms-3 me-3 mb-2" key={key}>
                                            <div className="card-body pt-1 pb-1">
                                                <Form.Label className="fw-normal p-0 m-0">{file.name}</Form.Label>
                                                <Button className="float-right" variant="danger" size="sm" title={`Remove "${file.name}"`} onClick={(e) => {
                                                    let newSelectedFiles = [...selectedFiles];
                                                    newSelectedFiles.splice(key, 1);
                                                    setSelectedFiles(newSelectedFiles);
                                                }}>X</Button>
                                            </div>
                                        </div>
                                    );
                                })}
                            </Col>
                            <Col className="mb-2" sm={12}>
                                <ListGroup className="ps-3 pe-3" variant="flush">
                                    {fileChkFail.map((file, key) => {
                                        return (
                                            <ListGroup.Item className="p-1" key={key}>
                                                <Form.Label className="fw-normal p-0 m-0 me-2 text-danger">{file}</Form.Label>
                                            </ListGroup.Item>
                                        );
                                    })}
                                </ListGroup>
                            </Col>
                            <Col className="mb-3" sm={12}>
                                <Form.Label className="fw-bold" id="dates-list">TRAVEL DATES</Form.Label>
                                <Calendar className="text-center"
                                    multiple
                                    value={toDate}
                                    onChange={setToDate}
                                    plugins={[
                                        <DatePanel sort="date" />
                                    ]}
                                />
                            </Col>
                            <Col className="mb-3" sm={12}>
                                <Form.Label className="fw-bold" >ATTENDANCE REQUIRED</Form.Label>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" checked={reqAtt} id="reqAttChkbox" onChange={() => {
                                        setReqAtt(!reqAtt);
                                    }} />
                                    <label className="form-check-label">
                                        If enabled, employee will still need attendance through the biometrics.
                                    </label>
                                </div>
                            </Col>
                            <Col className="mb-2" sm={12}>
                                <Form.Label className="fw-bold">PERMANENT STATION</Form.Label>
                                <Form.Control name="stationInput" as="input" value={station} required onChange={(event) => {
                                    setStation(event.target.value)
                                }} />
                            </Col>
                            <Col className="mb-2" sm={12}>
                                <Form.Label className="fw-bold mb-2">PLACE/S TO BE VISITED (Please specify Itinerary)
                                </Form.Label>
                                <Button className="ms-1 mb-2 fw-bold" variant="primary" onClick={(e) =>
                                    setItinerary([...itinerary, ""])
                                }>+</Button>
                                {itinerary.map((item, key) => {
                                    return (
                                        <Row key={key}>
                                            <Col sm={10}>
                                                <Form.Control className="mb-1" name={`place${item}`} key={key} as="input" value={item} required onChange={(event) => {
                                                    //let editItem = itinerary.find((iti, itikey) => itikey === key);
                                                    //editItem = event.target.value;
                                                    let editItinerary = [...itinerary];
                                                    editItinerary[key] = event.target.value;
                                                    setItinerary(editItinerary);
                                                }} />
                                            </Col>
                                            <Col>
                                                <Button variant="danger" onClick={(e) => { setItinerary(itinerary.filter((iti, itikey) => itikey !== key)) }
                                                }>X</Button>
                                            </Col>
                                        </Row>)
                                })}
                            </Col>
                            <Col className="mb-2" sm={12}>
                                <Form.Label className="fw-bold" >Travel is on:</Form.Label>
                                <Form.Select name="toOnSelect" onChange={(event) => {
                                    setTravel(parseInt(event.target.value));
                                }}>
                                    {[{ text: "Official Business", value: 0 }, { text: "Official Time", value: 1 }].map((opt, key) => (
                                        <option key={opt.value}
                                            value={opt.value}
                                            defaultValue={0}
                                        >{opt.text}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col className="mb-2" sm={12}>
                                <Form.Label className="fw-bold" >Expenses:</Form.Label>
                                <Form.Select className="mb-1" name="chargeSelect" onChange={(event) => {
                                    setCharge(parseInt(event.target.value));
                                }}>
                                    {[{ text: "Office Funds", value: 0 }, { text: "Others", value: 1 }].map((opt) => (
                                        <option key={opt.value}
                                            value={opt.value}
                                            defaultValue={0}
                                        >{opt.text}</option>
                                    ))}
                                </Form.Select>
                                <Form.Control name="chargeSpecify" as="input" value={chargeSpecify} placeholder="Specify if others" onChange={(event) => {
                                    setChargeSpecify(event.target.value);
                                }} />
                            </Col>
                        </Row>
                    </Col>
                    <Col className="travel-order-view-modal-body-to overflow-auto" sm={6}>
                        <Row>
                            <Col className="mb-2" sm={12}>
                                <EmployeeListSearchable
                                    selectedEmployee={selectedEmployee}
                                    setCheckSelectedEmployee={setCheckSelectedEmployee}
                                    maxHeight={"250px"}
                                    minHeight={"250px"}
                                    allowMultiple={true}
                                />

                                <div className="card p-0 mt-2">
                                    <div className="card-header m-0 p-0">
                                        <Row className="">
                                            <Col className="text-center">
                                                <Form.Label className="fw-bold" id="participants-list">Name of Participants</Form.Label>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="card-body p-0 m-0 emp-list-container">
                                        <ListGroup className="" variant="flush">
                                            <div>
                                                {selectedEmployee.map((employee, key) => {
                                                    const joCert = empJOCert.find(cert => cert.empId === employee.id);
                                                    const joCerInd = empJOCert.findIndex(cert => cert.empId === employee.id);
                                                    return <ListGroup.Item
                                                        className=""
                                                        key={key}
                                                    // onClick={() => {
                                                    //     setSelectedEmployee(selectedEmployee.filter(emp => emp !== employee));
                                                    // }}
                                                    // action
                                                    >
                                                        <Row>
                                                            <Col>
                                                                <b>{employee.name}</b>
                                                                <br />
                                                                <i>{`${employee.ESTA} - ${employee.position}`}</i>
                                                            </Col>
                                                            <Col sm="auto">
                                                                <Tooltip title="Remove" arrow><IconButton
                                                                    onClick={() => {
                                                                        setSelectedEmployee(selectedEmployee.filter(emp => emp !== employee));
                                                                    }}
                                                                ><CloseIcon fontSize="large" className="text-danger" /></IconButton></Tooltip>
                                                            </Col>
                                                        </Row>
                                                        {joCert ?
                                                            <>
                                                                <Row>
                                                                    <Col className="mb-2">
                                                                        <Form.Label className="fw-bold mb-2">Tasks
                                                                        </Form.Label>
                                                                        <Button className="ms-1 mb-2 fw-bold" variant="primary" onClick={(e) => {
                                                                            let updateCert = [...empJOCert];
                                                                            updateCert[joCerInd].purpose.push("");
                                                                            setEmpJOCert(updateCert);
                                                                        }}>+</Button>
                                                                        {joCert.purpose.map((item, key) => {
                                                                            return (
                                                                                <Row key={key}>
                                                                                    <Col sm={10}>
                                                                                        <Form.Control className="mb-1" name={`purpose${key}`} key={key} as="input" value={item} required onChange={(event) => {
                                                                                            let updateCert = [...empJOCert];
                                                                                            updateCert[joCerInd].purpose[key] = event.target.value;
                                                                                            setEmpJOCert(updateCert);
                                                                                        }} />
                                                                                    </Col>
                                                                                    <Col>
                                                                                        <Button variant="danger" onClick={(e) => {
                                                                                            let updateCert = [...empJOCert];
                                                                                            updateCert[joCerInd].purpose = updateCert[joCerInd].purpose.filter((purp, purpKey) => purpKey !== key);
                                                                                            setEmpJOCert(updateCert);
                                                                                            // setItinerary(itinerary.filter((iti, itikey) => itikey !== key));
                                                                                        }}>X</Button>
                                                                                    </Col>
                                                                                </Row>)
                                                                        })}
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col>
                                                                        <Form.Label>Reason:</Form.Label>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col sm="auto">
                                                                        <input className="form-check-input ms-1" type="checkbox" name="am_out_entry" checked={joCert.availability} onChange={(e) => {
                                                                            let updateCert = [...empJOCert];
                                                                            updateCert[joCerInd].availability = !joCert.availability;
                                                                            setEmpJOCert(updateCert);
                                                                        }} />
                                                                    </Col>
                                                                    <Col>
                                                                        <Form.Label className="m-auto">
                                                                            Unavalability of regular/permanent official employee to perform the task.
                                                                        </Form.Label>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col sm="auto">
                                                                        <input className="form-check-input ms-1" type="checkbox" name="am_out_entry" checked={joCert.task} onChange={(e) => {
                                                                            let updateCert = [...empJOCert];
                                                                            updateCert[joCerInd].task = !joCert.task;
                                                                            setEmpJOCert(updateCert);
                                                                        }} />
                                                                    </Col>
                                                                    <Col>
                                                                        <Form.Label className="m-auto">
                                                                            Task is critical to the function of the Job Order/Contract of Service and the attachment of organizational goals and objectives.
                                                                        </Form.Label>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col sm="auto">
                                                                        <input className="form-check-input ms-1" type="checkbox" name="am_out_entry" checked={joCert.presence} onChange={(e) => {
                                                                            let updateCert = [...empJOCert];
                                                                            updateCert[joCerInd].presence = !joCert.presence;
                                                                            setEmpJOCert(updateCert);
                                                                        }} />
                                                                    </Col>
                                                                    <Col>
                                                                        <Form.Label className="m-auto">
                                                                            Presence of the Job Order/Contract of Service is critical to the success of the activity to be attended.
                                                                        </Form.Label>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col sm="auto">
                                                                        <input className="form-check-input ms-1" type="checkbox" name="am_out_entry" checked={joCert.others} onChange={(e) => {
                                                                            let updateCert = [...empJOCert];
                                                                            updateCert[joCerInd].others = !joCert.others;
                                                                            setEmpJOCert(updateCert);
                                                                        }} />
                                                                    </Col>
                                                                    <Col>
                                                                        <Form.Label>
                                                                            Others, please specify:
                                                                        </Form.Label>
                                                                        <Form.Control name="eventInput" as="input" value={joCert.others_specify} onChange={(e) => {
                                                                            let updateCert = [...empJOCert];
                                                                            updateCert[joCerInd].others_specify = e.target.value;
                                                                            setEmpJOCert(updateCert);
                                                                        }} />
                                                                    </Col>
                                                                </Row>
                                                            </> : <></>}
                                                    </ListGroup.Item>
                                                })}
                                            </div>
                                        </ListGroup>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row className="m-auto">
                    <Col className="text-center">
                        <Button onClick={createTravelOrder}>Request</Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </>
    )
}