import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from '../../../services/axios';
import Swal from 'sweetalert2';
import DatePicker, { DateObject } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";

export default function EditModal(props) {
    const date = new DateObject(props.sched.date);

    const attendPair = {
        "am_in_entry": "attend_am_in",
        "am_out_entry": "attend_am_out",
        "pm_in_entry": "attend_pm_in",
        "pm_out_entry": "attend_pm_out",
        "ot_in_entry": "ot_in",
        "ot_out_entry": "ot_out",
    };

    const schedPair = {
        "am_in_entry": "sched_am_in",
        "am_out_entry": "sched_am_out",
        "pm_in_entry": "sched_pm_in",
        "pm_out_entry": "sched_pm_out",
    };

    const entryPair = {
        "attend_am_in": "am_in_entry",
        "attend_am_out": "am_out_entry",
        "attend_pm_in": "pm_in_entry",
        "attend_pm_out": "pm_out_entry",
        "ot_in": "ot_in_entry",
        "ot_out": "ot_out_entry"
    };

    const [sched, setSched] = useState(props.sched);
    const [selRecords, setSelRecords] = useState({});

    useEffect(() => {
        //console.log(sched);
        let date = new DateObject(sched.date);
        date.add(2, "days");
        let end = date.valueOf();
        date.subtract(3, "days");
        let start = date.valueOf();
        let tempRec = {};
        Object.keys(props.records).map((id, index) => {
            //let dateSep = props.records[id].split(" ");
            let recDate = new DateObject({
                date: props.records[id],
                format: "M/D/YYYY hh:mm A"
            });
            let val = recDate.valueOf();
            if (val <= end && val >= start) {
                tempRec[id] = props.records[id];
            }
        });
        setSelRecords(tempRec);
    }, [])

    // useEffect(()=>{
    //     console.log(sched);
    // }, [sched])

    const timeList = {
        "12:00 AM": "00:00", "12:30 AM": "00:30",
        "01:00 AM": "01:00", "01:30 AM": "01:30",
        "02:00 AM": "02:00", "02:30 AM": "02:30",
        "03:00 AM": "03:00", "03:30 AM": "03:30",
        "04:00 AM": "04:00", "04:30 AM": "04:30",
        "05:00 AM": "05:00", "05:30 AM": "05:30",
        "06:00 AM": "06:00", "06:30 AM": "06:30",
        "07:00 AM": "07:00", "07:30 AM": "07:30",
        "08:00 AM": "08:00", "08:30 AM": "08:30",
        "09:00 AM": "09:00", "09:30 AM": "09:30",
        "10:00 AM": "10:00", "10:30 AM": "10:30",
        "11:00 AM": "11:00", "11:30 AM": "11:30",
        "12:00 PM": "12:00", "12:30 PM": "12:30",
        "01:00 PM": "13:00", "01:30 PM": "13:30",
        "02:00 PM": "14:00", "02:30 PM": "14:30",
        "03:00 PM": "15:00", "03:30 PM": "15:30",
        "04:00 PM": "16:00", "04:30 PM": "16:30",
        "05:00 PM": "17:00", "05:30 PM": "17:30",
        "06:00 PM": "18:00", "06:30 PM": "18:30",
        "07:00 PM": "19:00", "07:30 PM": "19:30",
        "08:00 PM": "20:00", "08:30 PM": "20:30",
        "09:00 PM": "21:00", "09:30 PM": "21:30",
        "10:00 PM": "22:00", "10:30 PM": "22:30",
        "11:00 PM": "23:00", "11:30 PM": "23:30",
    }

    const timeList2 = {
        "CTO": "CTO",
        "12:00 AM": "00:00", "12:30 AM": "00:30",
        "01:00 AM": "01:00", "01:30 AM": "01:30",
        "02:00 AM": "02:00", "02:30 AM": "02:30",
        "03:00 AM": "03:00", "03:30 AM": "03:30",
        "04:00 AM": "04:00", "04:30 AM": "04:30",
        "05:00 AM": "05:00", "05:30 AM": "05:30",
        "06:00 AM": "06:00", "06:30 AM": "06:30",
        "07:00 AM": "07:00", "07:30 AM": "07:30",
        "08:00 AM": "08:00", "08:30 AM": "08:30",
        "09:00 AM": "09:00", "09:30 AM": "09:30",
        "10:00 AM": "10:00", "10:30 AM": "10:30",
        "11:00 AM": "11:00", "11:30 AM": "11:30",
        "12:00 PM": "12:00", "12:30 PM": "12:30",
        "01:00 PM": "13:00", "01:30 PM": "13:30",
        "02:00 PM": "14:00", "02:30 PM": "14:30",
        "03:00 PM": "15:00", "03:30 PM": "15:30",
        "04:00 PM": "16:00", "04:30 PM": "16:30",
        "05:00 PM": "17:00", "05:30 PM": "17:30",
        "06:00 PM": "18:00", "06:30 PM": "18:30",
        "07:00 PM": "19:00", "07:30 PM": "19:30",
        "08:00 PM": "20:00", "08:30 PM": "20:30",
        "09:00 PM": "21:00", "09:30 PM": "21:30",
        "10:00 PM": "22:00", "10:30 PM": "22:30",
        "11:00 PM": "23:00", "11:30 PM": "23:30",
    }

    const otList = {
        0: "No Overtime",
        1: "Extention",
        2: "Interval",
        3: "Open Time",
    }

    const updateSched = async (e) => {
        //console.log(sched);
        if (sched.am_in_entry === 1 || sched.am_in_entry === 3) {
            if (sched.attend_am_in === "") {
                Swal.fire({
                    icon: "warning",
                    text: `AM IN cannot be blank or uncheck AM IN`
                })
                return
            }
        }

        if (sched.am_out_entry === 1 || sched.am_out_entry === 3) {
            if (sched.attend_am_out === "") {
                Swal.fire({
                    icon: "warning",
                    text: `AM OUT cannot be blank or uncheck AM OUT`
                })
                return
            }
        }

        if (sched.pm_in_entry === 1 || sched.pm_in_entry === 3) {
            if (sched.attend_pm_in === "") {
                Swal.fire({
                    icon: "warning",
                    text: `PM IN cannot be blank or uncheck PM IN`
                })
                return
            }
        }

        if (sched.pm_out_entry === 1 || sched.pm_out_entry === 3) {
            if (sched.attend_pm_out === "") {
                Swal.fire({
                    icon: "warning",
                    text: `PM OUT cannot be blank or uncheck PM OUT`
                })
                return
            }
        }

        if (sched.ot_in_entry === 1 || sched.ot_in_entry === 3) {
            if (sched.ot_in === "") {
                Swal.fire({
                    icon: "warning",
                    text: `OT IN cannot be blank or uncheck OT IN`
                })
                return
            }
        }

        if (sched.ot_out_entry === 1 || sched.ot_out_entry === 3) {
            if (sched.ot_out === "") {
                Swal.fire({
                    icon: "warning",
                    text: `OT OUT cannot be blank or uncheck OT OUT`
                })
                return
            }
        }
        Swal.fire({
            icon: "info",
            text: "Saving...",
            showConfirmButton: false
        });
        await axios.post(`/api/employeeschedule/update`, sched).then(({ data }) => {
            if (data.result) {
                Swal.fire({
                    icon: "success",
                    text: data.message,
                    showConfirmButton: true
                }).then(() => {
                    props.closeFetchEdit();
                });
            } else {
                Swal.update({
                    icon: "error",
                    text: data.message,
                    showConfirmButton: true
                })
            }
        }).catch((error) => {
            Swal.update({
                icon: "error",
                text: error.response.data.message,
                showConfirmButton: true
            })
        })
    }

    const handleSchedChange = (e, id, keyid) => {
        const fieldName = e.target.name;
        let newValue = e.target.value;
        if (fieldName === "ot_indicator") {
            newValue = parseInt(newValue);
        }

        const newSched = {};
        Object.assign(newSched, sched);
        newSched[fieldName] = newValue;
        setSched(newSched);
    }

    const handleSwitch = (event) => {
        let val = event.target.checked;
        let name = event.target.name;
        const newSched = {};
        Object.assign(newSched, sched);
        if (val) {
            newSched[name] = 3;
            newSched[attendPair[name]] = new DateObject({ date: sched.date + " " + newSched[schedPair[name]], format: "YYYY-MM-DD HH:mm" }).valueOf();
            // newSched[attendPair[name]] = newSched[schedPair[name]];
        } else {
            newSched[name] = 0;
            newSched[attendPair[name]] = "";
        }
        setSched(newSched);
    }

    const handleAttendChange = (event) => {
        let newValue = event.target.value;
        let fieldName = event.target.name;
        if (newValue !== sched[fieldName]) {
            const newSched = {};
            Object.assign(newSched, sched);
            if (newSched[entryPair[fieldName]] === 0 && newValue !== "") {
                newSched[entryPair[fieldName]] = 1;
            } else if (newSched[entryPair[fieldName]] === 1 && newValue === "") {
                newSched[entryPair[fieldName]] = 0;
            }
            newSched[fieldName] = newValue;
            setSched(newSched);
        }
    }

    const handleAttendPickerChange = (event, el) => {
        //console.log(event.valueOf());
        const newValue = event.valueOf();
        const fieldName = el.input.name;
        if (newValue !== sched[fieldName]) {
            const newSched = {};
            Object.assign(newSched, sched);
            if (newSched[entryPair[fieldName]] === 0 && newValue !== "") {
                newSched[entryPair[fieldName]] = 1;
            } else if (newSched[entryPair[fieldName]] === 1 && newValue === "") {
                newSched[entryPair[fieldName]] = 0;
            }
            newSched[fieldName] = newValue;
            setSched(newSched);
        }
    }

    const attendOption = (msec) => {
        let recStr;
        if (props.records[msec]) {
            recStr = props.records[msec];
        } else {
            if (msec !== "" && msec !== null && msec !== "OB" && msec !== "VL" && msec !== "SL" && msec !== "HL" && msec !== "A" && msec !== "CTO") {
                let recTemp = new DateObject(parseInt(msec));
                let hours = recTemp.hour;
                let minutes = recTemp.minute;
                let ampm = hours >= 12 ? 'PM' : 'AM';
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                hours = (hours < 10 ? '0' : '') + hours;
                minutes = minutes < 10 ? '0' + minutes : minutes;
                recStr = recTemp.month.number + "/" + recTemp.day + "/" + recTemp.year + " " + hours + ":" + minutes + " " + ampm;
            } else {
                switch (msec) {
                    case "":
                        recStr = "";
                        break
                    case "OB":
                        recStr = "OB";
                        break;
                    case "VL":
                        recStr = "VL";
                        break;
                    case "SL":
                        recStr = "SL";
                        break;
                    case "HL":
                        recStr = "HL";
                        break;
                    case "A":
                        recStr = "Absent";
                        break;
                    case "CTO":
                        recStr = "CTO";
                        break;
                }
            }
        }

        return (
            <>
                {(msec !== "" && msec !== null) ? <option value=""></option> : ""}
                {(msec !== "A") ? <option value="A">Absent</option> : ""}
                {(msec !== "CTO") ? <option value="CTO">CTO</option> : ""}
                {(msec !== "OB") ? <option value="OB">OB</option> : ""}
                <option value={msec}>{recStr}</option>
                {Object.keys(selRecords).map((id, index) => {
                    let msVal = "" + new DateObject({
                        date: selRecords[id],
                        format: "MM/DD/YYYY HH:mm a"
                    }).valueOf();
                    return (selRecords[id] !== recStr ?
                        ((sched.attend_am_in !== msVal && sched.attend_am_in !== id) && (sched.attend_am_out !== msVal && sched.attend_am_out !== id) && (sched.attend_pm_in !== msVal && sched.attend_pm_in !== id) && (sched.attend_pm_out !== msVal && sched.attend_pm_out !== id)) ?
                            <option key={index} value={id}>{selRecords[id]}</option> : "" : "")
                })}
            </>
        );
    }

    const schedOption = (msec) => {
        let recStr;
        let checkVal = Object.keys(timeList2).find(key => timeList2[key] === msec);
        if (checkVal) {
            recStr = checkVal;
        } else {
            if (msec !== "" && msec !== null) {
                let recTemp = new DateObject(parseInt(msec));
                let hours = recTemp.hour;
                let minutes = recTemp.minute;
                let ampm = hours >= 12 ? 'PM' : 'AM';
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                hours = (hours < 10 ? '0' : '') + hours;
                minutes = minutes < 10 ? '0' + minutes : minutes;
                //recStr = recTemp.month.number+"/"+recTemp.day+" "+hours+":"+minutes+" "+ampm;
                recStr = hours + ":" + minutes + " " + ampm;
            } else {
                recStr = "";
            }
        }

        return (
            <>
                {(msec !== "" && msec !== null) ? <option value=""></option> : ""}
                <option value={msec}>{recStr}</option>
                {Object.keys(timeList2).map((time, index) => (
                    time !== recStr ? <option key={index} value={timeList2[time]}>{time}</option> : ""
                ))}
            </>
        );

    }

    // useEffect(() => {
    //     console.log(sched);
    // }, [sched])

    return (
        <Row>
            <Col>
                <div className="content-header text-center">
                    <h5 className="">{`${date.weekDay.name}, ${date.month.name} ${date.day}, ${date.year}`}</h5>
                </div>
                <section className="content">
                    <Row>
                        <Col className="border border-1 border-dark text-center">
                            <Row className="border-bottom border-1 border-dark">
                                <Col className="">
                                    <Form.Label className="m-auto">Schedule</Form.Label>
                                </Col>
                            </Row>
                            <Row className="">
                                <Col className="border-end border-1 border-dark">
                                    <Form.Label className="m-auto">AM IN</Form.Label>
                                </Col>
                                <Col className="border-end border-1 border-dark bg-light">
                                    <Form.Label className="m-auto">AM OUT</Form.Label>
                                </Col>
                                <Col className="border-end border-1 border-dark">
                                    <Form.Label className="m-auto">PM IN</Form.Label>
                                </Col>
                                <Col className="bg-light">
                                    <Form.Label className="m-auto">PM OUT</Form.Label>
                                </Col>
                            </Row>
                            <Row className="border-top border-1 border-dark">
                                <Col className="border-end border-1 border-dark p-0">
                                    <select className="schedule-select w-100 text-center" name="sched_am_in" value={(sched.sched_am_in === "" || sched.sched_am_in === null) ? "" : sched.sched_am_in}
                                        onChange={(e) => {
                                            handleSchedChange(e, props.schedID, props.keyID);
                                        }}>
                                        <option value={""}></option>
                                        {Object.keys(timeList).map((time, index) => (
                                            <option key={index} value={timeList[time]}>{time}</option>
                                        ))}
                                    </select>
                                </Col>
                                <Col className="border-end border-1 border-dark p-0">
                                    <select className="schedule-select w-100 text-center bg-light" name="sched_am_out" value={(sched.sched_am_out === "" || sched.sched_am_out === null) ? "" : sched.sched_am_out}
                                        onChange={(e) => {
                                            handleSchedChange(e, props.schedID, props.keyID);
                                        }}>
                                        <option value={""}></option>
                                        {Object.keys(timeList).map((time, index) => (
                                            <option key={index} value={timeList[time]}>{time}</option>
                                        ))}
                                    </select>
                                </Col>
                                <Col className="border-end border-1 border-dark p-0">
                                    <select className="schedule-select w-100 text-center" name="sched_pm_in" value={(sched.sched_pm_in === "" || sched.sched_pm_in === null) ? "" : sched.sched_pm_in}
                                        onChange={(e) => {
                                            handleSchedChange(e, props.schedID, props.keyID);
                                        }}>
                                        <option value={""}></option>
                                        {Object.keys(timeList).map((time, index) => (
                                            <option key={index} value={timeList[time]}>{time}</option>
                                        ))}
                                    </select>
                                </Col>
                                <Col className="border-1 border-dark p-0">
                                    <select className="schedule-select w-100 text-center bg-light" name="sched_pm_out" value={(sched.sched_pm_out === "" || sched.sched_pm_out === null) ? "" : sched.sched_pm_out}
                                        onChange={(e) => {
                                            handleSchedChange(e, props.schedID, props.keyID);
                                        }}>
                                        <option value={""}></option>
                                        {Object.keys(timeList).map((time, index) => (
                                            <option key={index} value={timeList[time]}>{time}</option>
                                        ))}
                                    </select>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <br />

                    <Row>
                        <Col className="border border-1 border-dark text-center">
                            <Row className="border-bottom border-1 border-dark">
                                <Col className="">
                                    <Form.Label className="m-auto">Attendance</Form.Label>
                                </Col>
                            </Row>
                            <Row className="">
                                <Col className="border-end border-1 border-dark">
                                    <Form.Label className="m-auto">AM IN
                                        <input className="form-check-input ms-1" type="checkbox" name="am_in_entry" checked={(sched.am_in_entry === 2 || sched.am_in_entry === 3) ? true : false} onChange={(event) => {
                                            handleSwitch(event);
                                        }} />
                                    </Form.Label>
                                </Col>
                                <Col className="border-end border-1 border-dark bg-light">
                                    <Form.Label className="m-auto">AM OUT
                                        <input className="form-check-input ms-1" type="checkbox" name="am_out_entry" checked={(sched.am_out_entry === 2 || sched.am_out_entry === 3) ? true : false} onChange={(event) => {
                                            handleSwitch(event);
                                        }} />
                                    </Form.Label>
                                </Col>
                                <Col className="border-end border-1 border-dark">
                                    <Form.Label className="m-auto">PM IN
                                        <input className="form-check-input ms-1" type="checkbox" name="pm_in_entry" checked={(sched.pm_in_entry === 2 || sched.pm_in_entry === 3) ? true : false} onChange={(event) => {
                                            handleSwitch(event);
                                        }} />
                                    </Form.Label>
                                </Col>
                                <Col className="bg-light">
                                    <Form.Label className="m-auto">PM OUT
                                        <input className="form-check-input ms-1" type="checkbox" name="pm_out_entry" checked={(sched.pm_out_entry === 2 || sched.pm_out_entry === 3) ? true : false} onChange={(event) => {
                                            handleSwitch(event);
                                        }} />
                                    </Form.Label>
                                </Col>
                            </Row>
                            <Row className="border-top border-1 border-dark">
                                <Col className="border-end border-1 border-dark p-0">
                                    {/* <select className="schedule-select w-100 text-center" style={{"fontSize":"10pt"}} name="attend_am_in" value={(sched.attend_am_in === "" || sched.attend_am_in === null) ? "" : sched.attend_am_in}
                                        onChange={(e)=>{
                                            handleAttendChange(e, props.schedID, props.keyID);
                                        }}
                                    >
                                        {(sched.am_in_entry === 2 || sched.am_in_entry === 3) ? schedOption(sched.attend_am_in) : attendOption(sched.attend_am_in)}
                                    </select> */}
                                    {
                                        (sched.am_in_entry === 2 || sched.am_in_entry === 3) ? <DatePicker
                                            name="attend_am_in"
                                            value={typeof sched.attend_am_in === "string" ? parseInt(sched.attend_am_in) : sched.attend_am_in}
                                            format="M/D/YYYY hh:mm A"
                                            onChange={(event, el) => handleAttendPickerChange(event, el)}
                                            inputClass="emp-sched-time-picker"
                                            plugins={[
                                                <TimePicker position="bottom" hideSeconds={true} />
                                            ]}
                                        /> :
                                            <select className="schedule-select w-100 text-center" style={{ "fontSize": "10pt" }} name="attend_am_in" value={(sched.attend_am_in === "" || sched.attend_am_in === null) ? "" : sched.attend_am_in}
                                                onChange={(e) => {
                                                    handleAttendChange(e, props.schedID, props.keyID);
                                                }}
                                            >
                                                {attendOption(sched.attend_am_in)}
                                            </select>
                                    }
                                </Col>
                                <Col className="border-end border-1 border-dark p-0">
                                    {/* <select className="schedule-select w-100 text-center bg-light" style={{"fontSize":"10pt"}} name="attend_am_out" value={(sched.attend_am_out === "" || sched.attend_am_out === null) ? "" : sched.attend_am_out}
                                        onChange={(e)=>{
                                            handleAttendChange(e, props.schedID, props.keyID);
                                        }}
                                    >
                                        {(sched.am_out_entry === 2 || sched.am_out_entry === 3) ? schedOption(sched.attend_am_out) : attendOption(sched.attend_am_out)}
                                    </select> */}
                                    {
                                        (sched.am_out_entry === 2 || sched.am_out_entry === 3) ? <DatePicker
                                            name="attend_am_out"
                                            value={typeof sched.attend_am_out === "string" ? parseInt(sched.attend_am_out) : sched.attend_am_out}
                                            format="M/D/YYYY hh:mm A"
                                            onChange={(event, el) => handleAttendPickerChange(event, el)}
                                            inputClass="emp-sched-time-picker"
                                            plugins={[
                                                <TimePicker position="bottom" hideSeconds={true} />
                                            ]}
                                        /> :
                                            <select className="schedule-select w-100 text-center" style={{ "fontSize": "10pt" }} name="attend_am_out" value={(sched.attend_am_out === "" || sched.attend_am_out === null) ? "" : sched.attend_am_out}
                                                onChange={(e) => {
                                                    handleAttendChange(e, props.schedID, props.keyID);
                                                }}
                                            >
                                                {attendOption(sched.attend_am_out)}
                                            </select>
                                    }
                                </Col>
                                <Col className="border-end border-1 border-dark p-0">
                                    {/* <select className="schedule-select w-100 text-center" style={{"fontSize":"10pt"}} name="attend_pm_in" value={(sched.attend_pm_in === "" || sched.attend_pm_in === null) ? "" : sched.attend_pm_in}
                                        onChange={(e)=>{
                                            handleAttendChange(e, props.schedID, props.keyID);
                                        }}
                                    >
                                        {(sched.pm_in_entry === 2 || sched.pm_in_entry === 3) ? schedOption(sched.attend_pm_in) : attendOption(sched.attend_pm_in)}
                                    </select> */}
                                    {
                                        (sched.pm_in_entry === 2 || sched.pm_in_entry === 3) ? <DatePicker
                                            name="attend_pm_in"
                                            value={typeof sched.attend_pm_in === "string" ? parseInt(sched.attend_pm_in) : sched.attend_pm_in}
                                            format="M/D/YYYY hh:mm A"
                                            onChange={(event, el) => handleAttendPickerChange(event, el)}
                                            inputClass="emp-sched-time-picker"
                                            plugins={[
                                                <TimePicker position="bottom" hideSeconds={true} />
                                            ]}
                                        /> :
                                            <select className="schedule-select w-100 text-center" style={{ "fontSize": "10pt" }} name="attend_pm_in" value={(sched.attend_pm_in === "" || sched.attend_pm_in === null) ? "" : sched.attend_pm_in}
                                                onChange={(e) => {
                                                    handleAttendChange(e, props.schedID, props.keyID);
                                                }}
                                            >
                                                {attendOption(sched.attend_pm_in)}
                                            </select>
                                    }
                                </Col>
                                <Col className="border-1 border-dark p-0">
                                    {/* <select className="schedule-select w-100 text-center bg-light" style={{"fontSize":"10pt"}} name="attend_pm_out" value={(sched.attend_pm_out === "" || sched.attend_pm_out === null) ? "" : sched.attend_pm_out}
                                        onChange={(e)=>{
                                            handleAttendChange(e, props.schedID, props.keyID);
                                        }}
                                    >
                                        {(sched.pm_out_entry === 2 || sched.pm_out_entry === 3) ? schedOption(sched.attend_pm_out) : attendOption(sched.attend_pm_out)}
                                    </select> */}
                                    {
                                        (sched.pm_out_entry === 2 || sched.pm_out_entry === 3) ? <DatePicker
                                            name="attend_pm_out"
                                            value={typeof sched.attend_pm_out === "string" ? parseInt(sched.attend_pm_out) : sched.attend_pm_out}
                                            format="M/D/YYYY hh:mm A"
                                            onChange={(event, el) => handleAttendPickerChange(event, el)}
                                            inputClass="emp-sched-time-picker"
                                            plugins={[
                                                <TimePicker position="bottom" hideSeconds={true} />
                                            ]}
                                        /> :
                                            <select className="schedule-select w-100 text-center" style={{ "fontSize": "10pt" }} name="attend_pm_out" value={(sched.attend_pm_out === "" || sched.attend_pm_out === null) ? "" : sched.attend_pm_out}
                                                onChange={(e) => {
                                                    handleAttendChange(e, props.schedID, props.keyID);
                                                }}
                                            >
                                                {attendOption(sched.attend_pm_out)}
                                            </select>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <br />

                    <Row>
                        <Col className="border border-1 border-dark text-center">
                            <Row className="border-bottom border-1 border-dark">
                                <Col className="">
                                    <Form.Label className="m-auto">Overtime</Form.Label>
                                </Col>
                            </Row>
                            <Row className="">
                                <Col className="border-end border-1 border-dark">
                                    <Form.Label className="m-auto">Indicator</Form.Label>
                                </Col>
                                <Col className="border-end border-1 border-dark">
                                    <Form.Label className="m-auto">Cut-Off</Form.Label>
                                </Col>
                                <Col className="border-end border-1 border-dark">
                                    <Form.Label className="m-auto">IN
                                        <input className="form-check-input ms-1" type="checkbox" name="ot_in_entry" checked={(sched.ot_in_entry === 2 || sched.ot_in_entry === 3) ? true : false} onChange={(event) => {
                                            handleSwitch(event);
                                        }} />
                                    </Form.Label>
                                </Col>
                                <Col className="bg-light">
                                    <Form.Label className="m-auto">OUT
                                        <input className="form-check-input ms-1" type="checkbox" name="ot_out_entry" checked={(sched.ot_out_entry === 2 || sched.ot_out_entry === 3) ? true : false} onChange={(event) => {
                                            handleSwitch(event);
                                        }} />
                                    </Form.Label>
                                </Col>
                            </Row>
                            <Row className="border-top border-1 border-dark">
                                <Col className="border-end border-1 border-dark p-0">
                                    <select className="schedule-select w-100 text-center" name="ot_indicator" value={sched.ot_indicator}
                                        onChange={(e) => {
                                            handleSchedChange(e, props.schedID, props.keyID);
                                        }}>
                                        {Object.keys(otList).map((ottype) => (
                                            <option key={ottype} value={ottype}>{otList[ottype]}</option>
                                        ))}
                                    </select>
                                </Col>
                                <Col className="border-end border-1 border-dark p-0">
                                    <select className="schedule-select w-100 text-center" name="ot_cutoff" value={(sched.ot_cutoff === "" || sched.ot_cutoff === null) ? "" : sched.ot_cutoff}
                                        onChange={(e) => {
                                            handleSchedChange(e, props.schedID, props.keyID);
                                        }}>
                                        <option value={""}></option>
                                        {Object.keys(timeList).map((time, index) => (
                                            <option key={index} value={timeList[time]}>{time}</option>
                                        ))}
                                    </select>
                                </Col>
                                <Col className="border-end border-1 border-dark p-0">
                                    {/* <select className="schedule-select w-100 text-center" name="ot_in" value={(sched.ot_in === "" || sched.ot_in === null) ? "" : sched.ot_in}
                                        onChange={(e)=>{
                                            handleAttendChange(e, props.schedID, props.keyID);
                                        }}>
                                        {(sched.ot_in_entry === 2 || sched.ot_in_entry === 3) ? schedOption(sched.ot_in) : attendOption(sched.ot_in)}
                                    </select> */}
                                    {
                                        (sched.ot_in_entry === 2 || sched.ot_in_entry === 3) ? <DatePicker
                                            name="ot_in"
                                            value={typeof sched.ot_in === "string" ? parseInt(sched.ot_in) : sched.ot_in}
                                            format="M/D/YYYY hh:mm A"
                                            onChange={(event, el) => handleAttendPickerChange(event, el)}
                                            inputClass="emp-sched-time-picker"
                                            plugins={[
                                                <TimePicker position="bottom" hideSeconds={true} />
                                            ]}
                                        /> :
                                            <select className="schedule-select w-100 text-center" style={{ "fontSize": "10pt" }} name="ot_in" value={(sched.ot_in === "" || sched.ot_in === null) ? "" : sched.ot_in}
                                                onChange={(e) => {
                                                    handleAttendChange(e, props.schedID, props.keyID);
                                                }}
                                            >
                                                {attendOption(sched.ot_in)}
                                            </select>
                                    }
                                </Col>
                                <Col className="p-0">
                                    {/* <select className="schedule-select w-100 text-center bg-light" name="ot_out" value={(sched.ot_out === "" || sched.ot_out === null) ? "" : sched.ot_out}
                                        onChange={(e)=>{
                                            handleAttendChange(e, props.schedID, props.keyID);
                                        }}>
                                        {(sched.ot_out_entry === 2 || sched.ot_out_entry === 3) ? schedOption(sched.ot_out) : attendOption(sched.ot_out)}
                                    </select> */}
                                    {
                                        (sched.ot_out_entry === 2 || sched.ot_out_entry === 3) ? <DatePicker
                                            name="ot_out"
                                            value={typeof sched.ot_out === "string" ? parseInt(sched.ot_out) : sched.ot_out}
                                            format="M/D/YYYY hh:mm A"
                                            onChange={(event, el) => handleAttendPickerChange(event, el)}
                                            inputClass="emp-sched-time-picker"
                                            plugins={[
                                                <TimePicker position="bottom" hideSeconds={true} />
                                            ]}
                                        /> :
                                            <select className="schedule-select w-100 text-center" style={{ "fontSize": "10pt" }} name="ot_out" value={(sched.ot_out === "" || sched.ot_out === null) ? "" : sched.ot_out}
                                                onChange={(e) => {
                                                    handleAttendChange(e, props.schedID, props.keyID);
                                                }}
                                            >
                                                {attendOption(sched.ot_out)}
                                            </select>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <br />

                    <Row>
                        <Col className="text-center">
                            <Row className="mb-1">
                                <Col className="">
                                    <Form.Label className="m-auto">Remarks</Form.Label>
                                </Col>
                            </Row>
                            <Row className="">
                                <Col className="">
                                    <Form.Control className="comment-text-area" as="textarea" row={2} placeholder="Enter remark..." value={sched.remarks || ""} onChange={(e) => {
                                        let newSched = { ...sched };
                                        newSched["remarks"] = e.target.value;
                                        setSched(newSched);
                                    }} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="text-center">
                            <Button className="" variant="primary" size="lg" block="block" onClick={(e) => {
                                updateSched(e);
                            }}>
                                Update
                            </Button>
                        </Col>
                    </Row>
                </section>
            </Col>
        </Row>
    )
}