import React, { useEffect, useState, useRef } from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from '../../../services/axios';
import { useNavigate } from 'react-router-dom'
import { DateObject } from "react-multi-date-picker";
import Modal from 'react-bootstrap/Modal';
import ReactToPrint from 'react-to-print';
import Swal from 'sweetalert2';

import LeaveApplicationModal from '../../Global/LeaveApplicationModal.component';
import { LeaveApplicationLayout } from '../../Global/PrintComponents/LeaveApplicationLayout.component';
import QueryFilter from '../../Global/QueryFilter.component';
import LeaveApplicationCreate from './CreateModal.component';
//import CreateCTOModal from './CreateCTOModal.component';

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import RemoveIcon from '@mui/icons-material/Remove';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PrintIcon from '@mui/icons-material/Print';

export default function PageIndex(props) {
    const navigate = useNavigate();
    const [leaves, setLeaves] = useState([]);
    const [offices, setOffices] = useState([]);
    const [leavesArranged, setLeavesArranged] = useState({});
    const [showLeaveID, setShowLeaveID] = useState(null);

    const [showCreate, setShowCreate] = useState(false);
    const openCreate = () => setShowCreate(true);
    const closeCreate = () => setShowCreate(false);
    const closeFetchCreate = () => {
        fetchLeaves(filter);
        setShowCreate(false);
    }

    // const [showCTOModal, setShowCTOModal] = useState(false);
    // const openCTOModal = () => setShowCTOModal(true);
    // const closeCTOModal = () => setShowCTOModal(false);

    const fetchOffices = async () => {
        await axios.get(`/api/office/assigned/leaveapplication`).then(({ data }) => {

            setOffices(data.offices);
        })
    }

    const [showLeaveModal, setShowLeaveModal] = useState(false);
    const openLeaveModal = () => setShowLeaveModal(true);
    const closeLeaveModal = () => setShowLeaveModal(false);
    const closeFetchLeaveModal = () => {
        fetchLeaves(filter);
        setShowLeaveModal(false);
    }

    const [showPrint, setShowPrint] = useState(false);

    const openPrint = (lvID) => {
        setShowLeaveID(lvID)
        setShowPrint(true);
    };
    const closePrint = () => setShowPrint(false);

    const [filter, setFilter] = useState({});
    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(20);

    useEffect(() => {
        let lvgr = {};
        leaves.forEach(lv => {
            if (!(lv.office.offdesc in lvgr)) {
                lvgr[lv.office.offdesc] = {};
            }
            let empName = lv.plantilla.FINAME + " " + lv.plantilla.MIDNAME + " " + lv.plantilla.SURNAME;
            if (!(empName in lvgr[lv.office.offdesc])) {
                lvgr[lv.office.offdesc][empName] = [];
            }
            lvgr[lv.office.offdesc][empName].push(lv.id);
        });
        setLeavesArranged(lvgr);
    }, [leaves])

    const fetchLeaves = async (filter) => {
        setFilter(filter);
        setLeavesArranged({});

        Swal.fire({
            icon: 'info',
            text: 'Loading...',
            showConfirmButton: false
        });

        await axios.post(`/api/office/leaveapplication/index`, { ...filter, page, perPage }).then(({ data }) => {
            setLeaves(data.index);
            //console.log(data.index);
            Swal.close();
            if (data.lastPage !== pages) {
                setPages(data.lastPage);
            }
        }).catch((error) => {
            Swal.update({
                icon: "error",
                text: error.response.data.message,
                showConfirmButton: true
            })
        })
    }

    useEffect(() => {
        fetchOffices();
    }, [])

    const collapseDiv = (div) => {
        var divElement = document.getElementById(div);
        if (divElement.style.display === "none") {
            divElement.style.display = "block"; // Show the div again if it's already collapsed
        } else {
            divElement.style.display = "none"; // Collapse the div by hiding it
        }
    }

    const componentRef = useRef();
    return (
        <>
            <Row className="page-index-controls cus-color-2">
                <Col className="p-1" sm="auto">
                    <Button className="" variant="primary" onClick={openCreate}>
                        Create Leave
                    </Button>
                </Col>
                <Col className="">
                    <QueryFilter
                        fetch={fetchLeaves}
                        perPage={perPage}
                        setPages={setPages}
                        page={page}
                        setPage={setPage}
                        inputs={{
                            office: {
                                input: 'select',
                                blankOption: { label: 'Office', value: '' },
                                selectOptions: offices,
                                optionMap: {
                                    label: 'offdesc',
                                    value: 'offcode'
                                }
                            },
                            leaveType: {
                                input: 'select',
                                blankOption: { label: 'Leave Type', value: '' },
                                selectOptions: [
                                    { label: "Vacation Leave", value: 1 },
                                    { label: "Mandatory/Forced Leave", value: 2 },
                                    { label: "Sick Leave", value: 3 },
                                    { label: "Maternity Leave", value: 4 },
                                    { label: "Paternity Leave", value: 5 },
                                    { label: "Special Privilege Leave", value: 6 },
                                    { label: "Solo Parent Leave", value: 7 },
                                    { label: "Study Leave", value: 8 },
                                    { label: "10-Day VAWC Leave", value: 9 },
                                    { label: "Special Leave Benefits for Women", value: 10 },
                                    { label: "Rehabilitation Privilege", value: 11 },
                                    { label: "Special Emergency (Calamity) Leave", value: 12 },
                                    { label: "Adoption Leave", value: 13 },
                                    { label: "Others - CTO", value: 14 },
                                    { label: "Others - Covid-19 Quarantine/Treatment Leave", value: 15 },
                                    { label: "Others - Covid-19 Quarantine Leave", value: 16 },
                                    { label: "Others - Monetization of Leave Credits", value: 17 },
                                    { label: "Others - Terminal Leave", value: 18 }
                                ],
                            },
                            leaveStatus: {
                                input: 'select',
                                selectOptions: [
                                    { label: 'Status', value: '' },
                                    { label: 'Pending', value: 0 },
                                    { label: 'Disapproved', value: 1 },
                                    { label: 'Approved', value: 2 },
                                    { label: 'Cancelled', value: 3 }
                                ]
                            },
                            dateFrom: {
                                input: 'date',
                                defaultValue: new DateObject().format("YYYY-MM") + '-01'
                            },
                            dateTo: {
                                input: 'date'
                            },
                        }}
                    />
                </Col>
            </Row>

            <Modal dialogClassName="leave-application-view-modal" show={showCreate} onHide={closeCreate}>
                <Modal.Header closeButton>
                    <Modal.Title>Leave Application</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LeaveApplicationCreate
                        user={props.user}
                        closeFetchCreate={closeFetchCreate}
                    />
                </Modal.Body>
            </Modal>

            <Modal dialogClassName="leave-application-view-modal" show={showLeaveModal} onHide={closeLeaveModal}>
                <LeaveApplicationModal
                    showEmpDetails={true}
                    showLeaveBalance={true}
                    showAppFor={false}
                    showLeaveSummary={false}
                    editWhenOffApp={true}
                    editWhenPgoApp={true}
                    editable={{
                        type: false,
                        attachment: true,
                        details: false,
                        noDays: true,
                        incDates: true,
                        commutation: true,
                        lvBalance: true,
                        appFor: false,
                        aoApp: props.user.abilities.includes('off_leave_manage') ? true : false,
                        offApp: false,
                        hrApp: false,
                        pgoApp: false,
                        cancel: true,
                        offDisReason: true
                    }}

                    showEdit={showLeaveModal}
                    closeEdit={closeLeaveModal}
                    closeFetchEdit={closeFetchLeaveModal}
                    leave={leaves.find(leave => leave.id === showLeaveID)}
                    user={props.user}
                />
            </Modal>

            <Modal dialogClassName="leave-application-print-modal" show={showPrint} onHide={closePrint}>
                <Modal.Header closeButton>
                    <Modal.Title>Leave Application</Modal.Title>
                    <ReactToPrint
                        trigger={() => <Button className="ms-3" variant="primary" >Print</Button>}
                        content={() => componentRef.current}
                    />
                </Modal.Header>
                <Modal.Body className="">
                    <LeaveApplicationLayout
                        closePrint={closePrint}
                        leave={leaves.find(leave => leave.id === showLeaveID)}
                        ref={componentRef}
                    />
                </Modal.Body>
            </Modal>

            <div>
                {pages > 1 ?
                    <Row className="mt-2">
                        <Col className="text-center">
                            {page > 1 ? <Button size='sm' onClick={(e) => {
                                setPage(page => page - 1);
                            }}>{'<'}</Button> : ""}
                            <Form.Label className="ms-2 me-2">{`Pages ${page} of ${pages}`}</Form.Label>
                            {page === pages ? "" : <Button size='sm' onClick={(e) => {
                                setPage(page => page + 1);
                            }}>{'>'}</Button>}
                        </Col>
                    </Row>
                    : ''}

                <Row className="mt-2 mb-2">
                    {
                        Object.entries(leavesArranged).map(([key, val]) => {
                            return (
                                <Col className="" key={key} sm={12}>
                                    <div className="card">
                                        <div className="card-header fw-bold">
                                            {key}
                                        </div>
                                        <div className="card-body pt-0 pb-0">
                                            <Row className="p-1 border-bottom border-1 fw-bold text-center">
                                                <Col>Employee</Col>
                                                <Col>Type</Col>
                                                <Col>Date Submitted</Col>
                                                <Col>Status</Col>
                                                <Col></Col>
                                            </Row>
                                            {
                                                Object.entries(val).map(([empKey, empVal]) => {
                                                    return (
                                                        <Row className="" key={empKey}>
                                                            <Col className="">
                                                                {/* <Row className="border-bottom border-1 p-1">
                                                        <Col className="fw-bold">
                                                            {empKey}
                                                        </Col>
                                                    </Row> */}
                                                                {
                                                                    empVal.map((leaveId) => {
                                                                        let leave = leaves.find(lv => lv.id === leaveId);
                                                                        const aoApp = leave.ao_approved;
                                                                        const offApp = leave.office_approved;
                                                                        //let offStat = aoApp === 1 && offApp === 1 ? 1 : aoApp === 0 || offApp === 0 ? 0 : null
                                                                        return (
                                                                            <Row className="border-bottom border-1 p-1 text-center" key={leave.id}>
                                                                                <Col className="" >
                                                                                    {empKey}
                                                                                </Col>
                                                                                <Col className="" >
                                                                                    {leave.leave_type.type}
                                                                                </Col>
                                                                                <Col className="" >{new DateObject(leave.created_at).format("MM/DD/YYYY")}</Col>
                                                                                <Col className="">
                                                                                    <div className="progress mt-1" style={{ height: "20px" }}>
                                                                                        {leave.status ? <>
                                                                                            <div className={`progress-bar ${aoApp !== 1 ? aoApp === null ? "bg-warning" : "bg-danger" : "bg-success"}`} style={{ width: `25%` }}>AO</div>
                                                                                            <div className={`progress-bar ${offApp !== 1 ? offApp === null ? "bg-warning" : "bg-danger" : "bg-success"}`} style={{ width: `25%` }}>Chief</div>
                                                                                            <div className={`progress-bar ${leave.hr_approved !== 1 ? leave.hr_approved === null ? "bg-warning" : leave.hr_approved === 2 ? "bg-secondary" : "bg-danger" : "bg-success"}`} style={{ width: `25%` }}>HR</div>
                                                                                            <div className={`progress-bar ${leave.signed !== 1 ? leave.signed === null ? "bg-warning" : leave.signed === 2 ? "bg-secondary" : "bg-danger" : "bg-success"}`} style={{ width: `25%` }}>PGO</div>
                                                                                        </> : <div className="progress-bar bg-danger" style={{ width: "100%" }}>Cancelled</div>}
                                                                                    </div>
                                                                                </Col>
                                                                                <Col className="">
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <button className="btn btn-sm btn-primary" data-key={leave.id} onClick={(e) => {
                                                                                                setShowLeaveID(leave.id);
                                                                                                openLeaveModal();
                                                                                            }}>
                                                                                                View
                                                                                            </button>
                                                                                        </Col>
                                                                                        <Col>
                                                                                            {(props.allowPrint) ?
                                                                                                // {(props.allowPrint && leave.hr_approved) ?
                                                                                                <PrintIcon className="" role="button" onClick={(e) => {
                                                                                                    openPrint(leave.id);
                                                                                                }} /> : ""}
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Col>
                                                                            </Row>
                                                                        );
                                                                    })
                                                                }
                                                            </Col>
                                                        </Row>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>

                {pages > 1 ?
                    <Row className="mb-2">
                        <Col className="text-center">
                            {page > 1 ? <Button size='sm' onClick={(e) => {
                                setPage(page => page - 1);
                            }}>{'<'}</Button> : ""}
                            <Form.Label className="ms-2 me-2">{`Pages ${page} of ${pages}`}</Form.Label>
                            {page === pages ? "" : <Button size='sm' onClick={(e) => {
                                setPage(page => page + 1);
                            }}>{'>'}</Button>}
                        </Col>
                    </Row>

                    : ''}
            </div>
        </>
    )
}