import React, { useEffect, useState } from 'react'

// import { format, parseISO } from 'date-fns';
// import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import DatePanel from "react-multi-date-picker/plugins/date_panel"
import { Calendar} from "react-multi-date-picker";
import { Table } from 'react-bootstrap';
import { DateObject } from "react-multi-date-picker";

export default function LeaveAppModal({ showModal, setShowModal, dataModal }) {
    
    const [modalData, setModalData] = useState({
        lt_type_id: '',
        no_days: '',
        less_sl: '',
        less_vl: '',
        vl_wo: '',
        sl_wo: '',
        vl_total: '',
        sl_total: '', 
    });

    useEffect(() => {
        if (dataModal) {
            setModalData({
                lt_type_id: dataModal.lt_type_id || '',
                no_days: dataModal.no_days || '',
                less_sl: dataModal.less_sl || '',
                less_vl: dataModal.less_vl || '',
                vl_wo: dataModal.vl_wo || '',
                sl_wo: dataModal.sl_wo || '',
                vl_total: dataModal.vl_total || '',
                sl_total: dataModal.sl_total || '',

            });
        }

    }, [dataModal]);


    const LT_type_ID = modalData.lt_type_id;
    const NoDays = modalData.no_days;
    const less_SL = modalData.less_sl;
    const less_VL = modalData.less_vl;
    const wo_VL = modalData.vl_wo;
    const wo_SL = modalData.sl_wo;
    const total_VL = modalData.vl_total;
    const total_SL = modalData.sl_total;
    


    const handleClose = () => {
        setShowModal(false);
    }

    //
    const formatDate = (dateString) => {
        try {
        // Parse the dateString to a Date object and format it to "MM/dd/yyyy"
        
        return new DateObject(dateString).format("MM/DD/YYYY");
        } catch (error) {
        console.error('Error formatting date:', error);
        return ''; // Return an empty string or some placeholder in case of an error
        }
    };
    const datesArray = dataModal && dataModal.dates ? dataModal.dates.split(',').map(date => date.trim()) : [];
    


  return (
    <>
        <Modal show={showModal} onHide={handleClose} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>Leave Application</Modal.Title>
            </Modal.Header>
        
            <Modal.Body style={modalBody}>
             <div className="row mb-3">
                <label className="col-sm-3 col-form-label fw-bold">DATE OF FILING </label>
                <div className="col-sm-3">
                     <input type="text" className="form-control" value={dataModal && dataModal.created_at ? formatDate(dataModal.created_at) : ''} readOnly />
                </div>
            </div>

            <div className="row mb-3">
                <label className="col-sm-3 col-form-label fw-bold">EMPLOYEE NAME </label>
                <div className="col-sm-8">
                          <input type="text" className="form-control"  value={dataModal.SURNAME+', '+ dataModal.FINAME +' '+ dataModal.MIDNAME } readOnly />
                </div>
            </div>
            
            <div className="row mb-3">
                <label className="col-sm-3 col-form-label fw-bold">OFFICE / DEPARTMENT </label>
                <div className="col-sm-8">
                          <input type="text" className="form-control" value={dataModal.offdesc} readOnly/>
                </div>
            </div>
            
            <label className="fs-4 fw-bolder mb-2">
                   DETAILS OF APPLICATION 
            </label>
                  
            <div className="row mb-3">
                <label className="col-sm-4 col-form-label fw-bold">TYPE OF LEAVE TO BE AVAILED </label>
                <div className="col-sm-7">
                     <input type="text" className="form-control" value={dataModal.type} readOnly/>
                </div>
            </div>
            
            <div className="row mb-3">
                <label className="col-sm-4 col-form-label fw-bold">DETAILS OF LEAVE </label>
                <div className="col-sm-7">
                     <input type="text" className="form-control" value={dataModal.detail} readOnly/>
                </div>
            </div>
            
            <div className="row mb-3">
                      <label className="col-sm-4 col-form-label fw-bold" >NUMBER OF WORKING DAYS APPLIED FOR </label>
                <div className="col-sm-3">
                     <input type="text" className="form-control" value={dataModal.no_days} readOnly/>
                </div>
                  </div>
                  
            <div className="row mb-3">
                      <label className="col-sm-4 col-form-label fw-bold" >COMMUTATION </label>
                <div className="col-sm-3">
                     <input type="text" className="form-control" value={dataModal.commutation === 1 ? "Requested" : "Not Requested" } readOnly/>
                </div>
            </div>      

            <div className="row mb-3">
                      <label className="col-sm-4 col-form-label fw-bold">INCLUSIVE DATES </label>
                <div className="col-sm-3">
                     
                          <Calendar className="m-auto"
                            multiple
                            buttons={false} 
                            value={datesArray}
                            customDayStyle={customDayStyle}
                          />   

                </div>
            </div>
                  
            <div className="mb-3">
                <label className="form-label fw-bold">LEAVE DEDUCTION </label>
                <div className="card">
                    <div className='card-body'>
                         <Table className="table table-borderd" responsive>
                        <thead>
                            <tr>
                                <th>
                                    <center>
                                        Type      
                                    </center>
                                </th>
                                <th>
                                    <center>
                                        Balance      
                                    </center>
                                </th>
                                <th>
                                    <center>
                                        Tentative
                                    </center>
                                </th>
                                
                                <th>
                                    <center>
                                        Recommended
                                    </center>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="text-center">Vacation</td>
                                <td className="text-center">{total_VL}</td>
                                <td className="text-center">
                                    {
                                        (LT_type_ID === 1 || LT_type_ID === 2) ?
                                        (parseFloat(total_VL - NoDays).toFixed(4)) >= 0 ? NoDays : 
                                        (parseFloat(NoDays-(NoDays - total_VL)).toFixed(4)) : 0
                                    }
                                </td>
                                <td className="text-center">
                                    {
                                        less_VL         
                                    }              
                                </td>
                            </tr>
                             <tr>
                                <td className="text-center">Sick</td>
                                <td className="text-center">
                                    {
                                       total_SL
                                    }
                                </td>
                                <td className="text-center">
                                    {
                                        (LT_type_ID === 3) ? 
                                        (parseFloat(total_SL - NoDays).toFixed(4)) >= 0 ? NoDays : 
                                        (parseFloat(NoDays-(NoDays-total_SL)).toFixed(4)) : 0
                                    }
                                </td>
                                <td className="text-center">{ less_SL }</td>
                            </tr>
                             <tr>
                                <td className="text-center">Vacation w/o Pay</td>
                                <td className="text-center">
                                   
                                </td>
                                <td className="text-center">
                                     {
                                        (LT_type_ID === 1 || LT_type_ID === 2) ? 
                                        (parseFloat(total_VL - NoDays).toFixed(4)) < 0  ? parseFloat(NoDays - total_VL).toFixed(4) : 0 : 0
                                    }
                                </td>
                                <td className="text-center">
                                    {wo_VL}
                                </td>
                            </tr>
                             <tr>
                                <td className="text-center">Sick w/o Pay</td>
                                <td className="text-center"></td>
                                <td className="text-center">
                                    {
                                        (LT_type_ID === 3) ? 
                                        (parseFloat(total_SL - NoDays).toFixed(4)) < 0 ? parseFloat(NoDays - total_SL).toFixed(4) : 0 : 0
                                    }
                                </td>
                                <td className="text-center">
                                    { wo_SL }
                                </td>
                            </tr>
                        </tbody>
                    </Table>   
                    </div>
                      
                </div>
                
            </div>




                  

            </Modal.Body>
        
            <Modal.Footer>
    
            </Modal.Footer>
         </Modal>
    </>
  )

}
const modalBody = {
    maxHeight: 'calc(100vh - 150px)',
    overflowY: 'auto',
};

 const customDayStyle = {
    backgroundColor: 'transparent', // set the background color to transparent to hide the circle
    border: 'none', // remove any border
  };