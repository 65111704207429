import React, { useEffect, useState, useRef } from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup'
import axios from '../../../services/axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom'
import DatePicker, { Calendar } from "react-multi-date-picker";
import "react-multi-date-picker/styles/colors/red.css"

import EmployeeList from '../../Global/EmployeeList.component';

export default function CreateCTOModal(props) {

    const [employeeList, setEmployeeList] = useState([]);
    const [divisionList, setDivisionList] = useState([]);
    const [selectedDivision, setSelectedDivision] = useState(0);
    const [cto_reason, setCtoReason] = useState("");
    const [selectedEmployee, setSelectedEmployee] = useState([]);
    const [hours, setHours] = useState(0);
    const [date, setDate] = useState('');
    const [employeeCheck, setEmployeeCheck] = useState(false);

    const fetchEmployeeList = async () => {
        await axios.post(`/api/office/query/${props.user.office.offcode}`, { employee: true, leavecredits: false, cto: false }).then(({ data }) => {
            //console.log(data);
            setDivisionList(data.office.divisions);
            setEmployeeList(data.office.employees);
        })
    }

    useEffect(() => {
        fetchEmployeeList();
    }, []);

    const setFetchEmployeeSchedule = (id) => {
        if (selectedEmployee !== id) {
            setSelectedEmployee(id);
        }
    }

    const saveCTO = async () => {
        if (!validateFields()) {
            Swal.fire({
                showConfirmButton: false,
                text: 'Saving...'
            });
            await axios.post(`/api/compensatorytimeoff/save`,
                {
                    employees: selectedEmployee,
                    office: props.user.office.offcode,
                    cto_reason,
                    hours,
                    date: date.format("YYYY-MM-DD"),
                }
            ).then(({ data }) => {
                Swal.update({
                    icon: "success",
                    text: data.message,
                    showConfirmButton: true
                });
                props.closeModal();
            }).catch((error) => {
                //console.log(response.data);
                Swal.update({
                    icon: "error",
                    text: error.response.data.message,
                    showConfirmButton: true
                })
            })
        }
    }

    const validateFields = () => {
        let invalid = false;
        let requiredFields = document.getElementById("createHolidayForm").querySelectorAll("[required]");
        requiredFields.forEach((el) => {
            if (el.value == 0) {
                invalid = true;
                el.classList.add("is-invalid");
            } else {
                el.classList.remove("is-invalid");
            }
        })
        if (!selectedEmployee.length) {
            setEmployeeCheck(true);
            invalid = true;
        } else {
            setEmployeeCheck(false);
        }
        return invalid;
    }

    return (
        <div id="createHolidayForm">
            <Row>
                <Col className="mb-2" sm={12}>
                    <Form.Label className="fw-bold">Reason/Task for COC</Form.Label>
                    <Form.Control className="mt-2" name="cto_reason" as="input" placeholder="Please enter a detailed description of the task/reason" value={cto_reason} required onChange={(event) => {
                        setCtoReason(event.target.value)
                    }} />
                </Col>
                <Col className="mb-2" sm={12}>
                    <Form.Label className="fw-bold" required>Select Employees</Form.Label>
                    <EmployeeList
                        divisionList={divisionList}
                        employeeList={employeeList}
                        selectedEmployee={selectedEmployee}
                        selectEffect={setFetchEmployeeSchedule}
                        allowMultiple={true}
                        maxHeight={"300px"}
                        required={employeeCheck}
                        show={
                            {
                                cto: false
                            }
                        }
                    />
                </Col>
                <Col className="mb-3" sm={12}>
                    <Form.Label className="fw-bold" >Date of COC</Form.Label>
                    <DatePicker
                        value={date}
                        onChange={setDate}
                        inputClass="form-control"
                        placeholder='Select Date'
                        required
                    />
                </Col>
                <Col className="mb-2" sm={12}>
                    <Form.Label className="fw-bold">No. of Hours</Form.Label>
                    <Form.Control className="" name="hours" as="input" type="number" min="0" step="1" pattern="\d+(\.\d{1})?" placeholder="Enter no. of hours to add" value={hours} required onChange={(event) => {
                        setHours(event.target.value)
                    }} />
                </Col>
                <Col className="" sm={12}>
                    <div className="d-grid gap-2">
                        <button className="btn btn-primary" onClick={saveCTO}>
                            Save
                        </button>
                    </div>
                </Col>
            </Row>
        </div>
    )
}