import React, { useEffect, useState, useRef } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup'
import axios from '../../../services/axios';
import Swal from 'sweetalert2';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal';
import DatePicker, { DateObject } from "react-multi-date-picker";

import CreateHolidayModal from './CreateHolidayModal.component';

export default function Page(props) {

    const [holidays, setHolidays] = useState([]);
    const removeHoliday = (id) => {
        setHolidays(holidays.filter(holiday => holiday.id !== id));
    };
    const [selectedYear, setSelectedYear] = useState(new DateObject());

    const [showHolidayModal, setShowHolidayModal] = useState(false);
    const openHolidayModal = () => setShowHolidayModal(true);
    const closeHolidayModal = () => setShowHolidayModal(false);
    const closeFetchHolidayModal = () => {
        setShowHolidayModal(false);
        fetchHolidayList();
    }

    useEffect(() => {
        fetchHolidayList();
    }, [selectedYear])

    const fetchHolidayList = async () => {
        Swal.fire({
            icon: 'info',
            text: 'Loading data...',
            showConfirmButton: false
        });
        await axios.get(`/api/holiday/list/${selectedYear.year}`).then(({ data }) => {
            if (data.result) {
                Swal.close();
                setHolidays(data.holidays);
            } else {
                Swal.update({
                    icon: "error",
                    text: data.message,
                    showConfirmButton: true
                });
            }
        }).catch((error) => {
            Swal.update({
                icon: "error",
                text: error.response.data.message,
                showConfirmButton: true
            })
        })
    }

    const cancelHoliday = async (id) => {
        Swal.fire({
            icon: 'info',
            text: 'Cancelling holiday...',
            showConfirmButton: false
        });
        await axios.post(`/api/holiday/cancel`, { id: id }).then(({ data }) => {
            if (data.result) {
                removeHoliday(id);
                Swal.close();
            } else {
                Swal.update({
                    icon: "error",
                    text: data.message,
                    showConfirmButton: true
                });
            }
        }).catch((error) => {
            Swal.update({
                icon: "error",
                text: error.response.data.message,
                showConfirmButton: true
            })
        })
    }

    const [showCreate, setShowCreate] = useState(false);
    const openCreate = () => setShowCreate(true);
    const closeCreate = () => setShowCreate(false);
    const closeFetchCreate = () => {
        setShowCreate(false);
        fetchHolidayList();
    }

    return (
        <>

            <Modal show={showCreate} onHide={closeCreate}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Holiday</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateHolidayModal
                        closeFetchCreate={closeFetchCreate}
                    />
                </Modal.Body>
            </Modal>
            <Row className="m-0 p-1">
                <Col className="m-0 pt-2 fw-bold" lg="auto">Filter by Year:</Col>
                <Col className="m-0 ps-0 sched-rmdp-container" sm={12} lg={"auto"}>
                    <DatePicker
                        format="YYYY"
                        value={selectedYear}
                        onChange={setSelectedYear}
                        onlyYearPicker
                        inputClass="form-control fw-bold m-0 p-0 text-center"
                    />
                </Col>
                <Col className="m-0 ps-0 pe-0 pt-1 pb-0">
                    <Button size="sm" onClick={() => {
                        openCreate();
                    }}>Add Holiday</Button>
                </Col>
            </Row>

            <Row className="m-0 p-1" style={{ height: "100%" }}>
                <Col className="m-0 p-0">
                    <div className="card-body pt-0 pb-1 ps-0 pe-0 m-0 overflow-auto emp-list-container">
                        <ListGroup className="" variant="flush">
                            {/* <ListGroup.Item>
                                    <Row>
                                        <Col sm="auto" className="fw-bold" >Holiday</Col>
                                        <Col sm="auto" className="fw-bold" >Date</Col>
                                        <Col sm="auto" ></Col>
                                    </Row>
                                </ListGroup.Item> */}
                            {holidays.map((holiday, key) => (
                                <ListGroup.Item key={key}>
                                    <Row>
                                        <Col className="fw-bold pe-0 me-0" xs={"auto"} lg={"auto"}>{holiday.description}</Col>
                                        <Col xs={"auto"} lg={"auto"}>
                                            <Button className="m-0 pt-0 pb-0 ps-1 pe-1" variant="danger" size="sm" onClick={(e) => {
                                                cancelHoliday(holiday.id);
                                            }}>X</Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>{new DateObject(holiday.date).format("MMMM, DD YYYY")}</Col>
                                    </Row>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </div>
                </Col>
            </Row>
        </>
    )
}