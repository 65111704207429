import React, { useEffect, useState } from "react";
import axios from "../../services/axios";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";

const Account = () => {
  const [signaturestat, setsignaturestat] = useState(false);
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [newpassword, setnewpassword] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState(null);
  const [sigpassword, setSigpassword] = useState("");
  const [signatureimage, setSignatureimage] = useState(null);
  const [signatureimagefile, setSignatureimagefile] = useState(null);

  const sigImageChange = (e) => {
    const file = e.target.files[0];
    setSignatureimagefile(e.target.files[0])
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSignatureimage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const showimport = () =>{
    setFile(null)
    setSigpassword("")
    setSignatureimage(null)
    setShowModal(true)
  }
  

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowModal(false)
    importDS()
    
  };

  const importDS = async () => {
    try {
        const formData = new FormData();
        formData.append('rtype', 'importsignature');
        formData.append('file', file);
        formData.append('imagefile', signatureimagefile);
        formData.append('password', sigpassword);
        const response = await axios.post('/api/account', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        if (response.data.message === "invalidpassword"){
            Swal.fire({
                text: "The password provided is invalid.",
                icon: "error",
              });
        }
        else if (response.data.message === "invalidfile"){
          Swal.fire({
              text: "The signature provided is invalid.",
              icon: "error",
            });
        }
        else if (response.data.message === "expired"){
          Swal.fire({
              text: "The signature provided has been expired.",
              icon: "error",
            });
        }
        else {
            Swal.fire({
              text: "Setting your digital signature was successful",
              icon: "success",
            });
            setsignaturestat(true)
        }
    } catch (error) {
        Swal.fire({
            title: "Error",
            text: error.response.data.message,
            icon: "error",
          });
      console.error("Error setting Digital Signature:", error);
    }
  };

  const getusername = async () => {
    try {
      const response = await axios.post(`/api/account`, {
        rtype: "getuser",
      });
      setusername(response.data.username);
    } catch (error) {
      console.error("Error obtaining username:", error);
    }
  };

  const getsignatureinfo = async () => {
    try {
      const response = await axios.post(`/api/account`, {
        rtype: "getsignature",
      });
      setsignaturestat(response.data.signature);
    } catch (error) {
      console.error("Error obtainig signature information:", error);
    }
  };

  const handleusername = () => {
    updateusername(username);
  };

  const handlepassword = () => {
    if (!password) {
      Swal.fire({
        title: "Error",
        text: "Please provide your existing password",
        icon: "error",
      });
    } else if (newpassword !== confirmpassword) {
      Swal.fire({
        title: "Error",
        text: "The new password and confirmation password did not match",
        icon: "error",
      });
    } else if (!newpassword || !confirmpassword) {
      Swal.fire({
        title: "Error",
        text: "Enter your new password and confirmation before proceeding",
        icon: "error",
      });
    } else {
      updatepassword();
    }
  };

  const updatepassword = async () => {
    try {
      const response = await axios.post(`/api/account`, {
        rtype: "updatepassword",
        password: password,
        newpassword: newpassword,
        newpassword_confirmation: confirmpassword,
      });
      if (response.data.message === "Incorrect") {
        Swal.fire({
          text: "The existing password provided was incorrect",
          icon: "error",
        });
      } else {
        Swal.fire({
          text: "Password has been updated",
          icon: "success",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error.response.data.message,
        icon: "error",
      });
      console.error("Error updating password:", error);
    }
  };

  const updateusername = async (newusername) => {
    try {
      const response = await axios.post(`/api/account`, {
        rtype: "updateuser",
        newusername: newusername,
      });
      setusername(response.data.username);
      Swal.fire({
        text: "Successful",
        icon: "success",
      });
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Username update was not successful",
        icon: "error",
      });
      console.error("Error updating username:", error);
    }
  };

  useEffect(() => {
    getusername();
    getsignatureinfo();
  }, []);

  return (
    <div
      className="d-flex flex-column align-items-center p-3"
      style={{ height: "100%" }}
    >
      <div
        className="d-flex flex-column border border-primary rounded m-2"
        style={{ width: "550px", backgroundColor: "white", overflow: "hidden" }}
      >
        <div
          className="m-0 p-0 border-bottom"
          style={{ backgroundColor: "#e0f7fa" }}
        >
          <div className="p-3 fw-bold">LOGIN INFORMATION</div>
        </div>
        <div className="m-0 p-0">
          <div className="p-3 d-flex flex-column">
            <div className="mb-2 fw-light">
              Update your account's login information.
            </div>
            <div>Username</div>
            <div>
              <input
                type="text"
                class="form-control"
                id="username"
                placeholder="Enter text"
                value={username}
                onChange={(e) => setusername(e.target.value)}
              />
            </div>
            <div className="d-flex flex-row-reverse pt-1">
              <button
                type="button"
                class="btn btn-primary"
                onClick={handleusername}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="d-flex flex-column border border-primary rounded m-2"
        style={{ width: "550px", backgroundColor: "white", overflow: "hidden" }}
      >
        <div
          className="m-0 p-0 border-bottom"
          style={{ backgroundColor: "#e0f7fa" }}
        >
          <div className="p-3 fw-bold">UPDATE PASSWORD</div>
        </div>
        <div className="m-0 p-0">
          <div className="p-3 d-flex flex-column">
            <div className="mb-2 fw-light">
              Ensure your account is using a long, random password to stay
              secure.
            </div>
            <div>Current Password</div>
            <div className="mb-2">
              <input
                type="password"
                class="form-control"
                id="password"
                placeholder="Enter current Password"
                value={password}
                onChange={(e) => setpassword(e.target.value)}
              />
            </div>
            <div>New Password</div>
            <div className="mb-2">
              <input
                type="password"
                class="form-control"
                id="newpassword"
                placeholder="Enter new Password"
                value={newpassword}
                onChange={(e) => setnewpassword(e.target.value)}
              />
            </div>
            <div>Confirm Password</div>
            <div className="mb-2">
              <input
                type="password"
                class="form-control"
                id="confirmpassword"
                placeholder="Confirm Password"
                value={confirmpassword}
                onChange={(e) => setconfirmpassword(e.target.value)}
              />
            </div>
            <div className="d-flex flex-row-reverse pt-1">
              <button
                type="button"
                class="btn btn-primary"
                onClick={handlepassword}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="d-flex flex-column border border-primary rounded m-2"
        style={{ width: "550px", backgroundColor: "white", overflow: "hidden" }}
      >
        <div
          className="m-0 p-0 border-bottom"
          style={{ backgroundColor: "#e0f7fa" }}
        >
          <div className="p-3 fw-bold">DIGITAL SIGNATURE</div>
        </div>
        <div className="m-0 p-0">
          <div className="p-3 d-flex flex-column">
            <div className="mb-2 fw-light">
              {signaturestat === true
                ? "Your Digital Signature has been set."
                : "Set your digital signature obtained from Certification Authority (CA), DICT."}
            </div>

            <div className="d-flex flex-row-reverse pt-1">
              <button
                type="button"
                className="btn btn-primary"
                onClick={showimport}
              >
                Import
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="modal-80w"
        centered="true"
      >
        <Modal.Header closeButton>
          <Modal.Title>Setting up Digital Signature</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="fw-light">Browse the Digital Signature file issued by the Certification Authority (CA), Department of Information and Communications Technology (DICT).</div>
            <form onSubmit={handleSubmit}>
              <div className="mb-1">
                <input
                  type="file"
                  className="form-control"
                  id="fileInput"
                  accept=".p12"
                  onChange={handleFileChange}
                  required
                />
              </div>
              <div>
                <input
                  type="password"
                  className="form-control"
                  id="passwordInput"
                  value={sigpassword}
                  placeholder="Digital Signature Password"
                  onChange={(e) => setSigpassword(e.target.value)}
                  required
                />
              </div>
              <div className="d-flex flex-column mt-4">
              <div className="fw-light">Browse scanned copy of your handwritten signature in white background.</div>
              <input type="file" className="form-control" accept="image/*" onChange={sigImageChange} required/>
                { signatureimage ?
                  <div className="border border-dark rounded mt-1">
                  <img className="rounded" src={signatureimage} alt="" style={{ height: '100px', width: '100%' }} />
                </div>:<></>
                }
              </div>
              <div className="d-flex flex-column">
                  <div className="fw-light"><small>By clicking on the Submit button, I consent to the collection of my digital signature. I understand that my digital signature will be stored securely in my account and can only be used by me when I log on to sign official documents related to attendance management only. I also understand that my digital signature will not be shared with any third party without my prior consent.</small></div>
              </div>
              <div className="d-flex flex-row justify-content-center">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
              
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Account;
