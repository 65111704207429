import React, { useEffect, useState, useRef } from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from '../../../services/axios';
import { useNavigate } from 'react-router-dom'
import { DateObject } from "react-multi-date-picker";
import Modal from 'react-bootstrap/Modal';
import ReactToPrint from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import Swal from 'sweetalert2';


import TravelOrderCreate from './CreateModal.component';
import TravelOrderModal from '../../Global/TravelOrderModal.component';
import { TravelOrderLayout } from '../../Global/PrintComponents/TravelOrderLayout.component';
import QueryFilter from '../../Global/QueryFilter.component';

export default function PageIndex(props) {
    const navigate = useNavigate();

    const [travelOrders, setTravelOrders] = useState([]);
    const [selectedTO, setSelectedTO] = useState(null);
    const [arrangedTOs, setArrangedTOs] = useState({});
    const [offices, setOffices] = useState([]);

    useEffect(() => {
        let togr = {};
        travelOrders.forEach(to => {
            if (!(to.office.offdesc in togr)) {
                togr[to.office.offdesc] = [];
            }
            togr[to.office.offdesc].push(to.id);
        });
        setArrangedTOs(togr);
    }, [travelOrders])

    //edit TO
    const [showTO, setShowTO] = useState(false);
    const openTO = () => setShowTO(true);
    const closeTO = () => setShowTO(false);
    const closeFetchTO = () => {
        fetchTOs(filter);
        closeTO(false);
    }

    //create TO
    const [showCreateTO, setShowCreateTO] = useState(false);
    const openCreateTO = (event) => {
        setShowCreateTO(true)
    };
    const closeCreateTO = () => setShowCreateTO(false);
    const closeCreateFetchTO = () => {
        fetchTOs(filter);
        setShowCreateTO(false);
    }

    //print TO
    const [showPrint, setShowPrint] = useState(false);
    const componentRef = useRef();
    const openPrint = () => setShowPrint(true);
    const closePrint = () => setShowPrint(false);

    //query index
    const [filter, setFilter] = useState({});
    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(20);

    const fetchTOs = async (filter) => {
        setFilter(filter);
        setArrangedTOs({});

        Swal.fire({
            icon: 'info',
            text: 'Loading...',
            showConfirmButton: false
        });

        await axios.post(`/api/office/travelorder/index`, { ...filter, page, perPage }).then(({ data }) => {
            //console.log(data.index);
            Swal.close();
            setTravelOrders(data.index);
            if (data.lastPage !== pages) {
                setPages(data.lastPage);
            }
        }).catch((error) => {
            Swal.update({
                icon: "error",
                text: error.response.data.message,
                showConfirmButton: true
            })
        })
    }

    const fetchOffices = async () => {
        await axios.get(`/api/office/assigned/travelorder`).then(({ data }) => {
            setOffices(data.offices);
        })
    }

    useEffect(() => {
        fetchOffices();
    }, [])

    const collapseDiv = (div) => {
        var divElement = document.getElementById(div);
        if (divElement.style.display === "none") {
            divElement.style.display = "block"; // Show the div again if it's already collapsed
        } else {
            divElement.style.display = "none"; // Collapse the div by hiding it
        }
    }

    return (
        <>
            <Row className="page-index-controls cus-color-2" style={{ borderBottomWidth: '1px', borderBottomColor: "#062b64", borderBottomStyle: 'solid' }}>
                <Col sm="auto">
                    <Row>
                        <Col className="pt-1 pb-1">
                            <Button variant="primary" onClick={openCreateTO}>
                                Request Travel Order
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <QueryFilter
                        fetch={fetchTOs}
                        perPage={perPage}
                        setPages={setPages}
                        page={page}
                        setPage={setPage}
                        inputs={{
                            office: {
                                input: 'select',
                                blankOption: { label: 'Office', value: '' },
                                selectOptions: offices,
                                defaultValue: '',
                                optionMap: {
                                    label: 'offdesc',
                                    value: 'offcode'
                                }
                            },
                            status: {
                                input: 'select',
                                selectOptions: [
                                    { label: 'Status', value: '' },
                                    { label: 'Pending', value: 0 },
                                    { label: 'Disapproved', value: 1 },
                                    { label: 'Approved', value: 2 },
                                    { label: 'Cancelled', value: 3 }
                                ],
                            },
                            dateFrom: {
                                input: 'date',
                                defaultValue: new DateObject().format("YYYY-MM") + '-01'
                            },
                            dateTo: {
                                input: 'date'
                            },
                        }}
                    />
                </Col>
            </Row>

            <Modal dialogClassName="travel-order-create-modal" show={showCreateTO} onHide={closeCreateTO}>
                <TravelOrderCreate
                    user={props.user}
                    closeCreateFetchTO={closeCreateFetchTO}
                />
            </Modal>

            <Modal dialogClassName="travel-order-view-modal" show={showTO} onHide={closeTO}>
                <TravelOrderModal
                    editable={{
                        to: true,
                        event: true,
                        attachment: true,
                        participant: true,
                        travelDate: true,
                        station: true,
                        itinerary: true,
                        travel: true,
                        charge: true,
                        offApp: props.user.abilities.includes('off_to_app') ? true : false,
                        pgoApp: false,
                        cancel: true,
                        attReq: true
                    }}
                    user={props.user}
                    selectedTO={travelOrders.find(to => to.id == selectedTO)}
                    closeFetchTO={closeFetchTO}
                />
            </Modal>

            <Modal dialogClassName="travel-order-print-modal" show={showPrint} onHide={closePrint}>
                <Modal.Header closeButton>
                    <Modal.Title>Travel Order</Modal.Title>
                    <ReactToPrint
                        trigger={() => <Button className="ms-3" variant="primary" >Print</Button>}
                        content={() => componentRef.current}
                    />
                </Modal.Header>
                <Modal.Body className="">
                    <TravelOrderLayout
                        closePrint={closePrint}
                        selectedTO={travelOrders.find(to => to.id == selectedTO)}
                        leaveAppID={selectedTO}
                        ref={componentRef}
                    />
                </Modal.Body>
            </Modal>

            <div>
                {pages > 1 ?
                    <Row className="mt-2">
                        <Col className="text-center">
                            {page > 1 ? <Button size='sm' onClick={(e) => {
                                setPage(page => page - 1);
                            }}>{'<'}</Button> : ""}
                            <Form.Label className="ms-2 me-2">{`Pages ${page} of ${pages}`}</Form.Label>
                            {page === pages ? "" : <Button size='sm' onClick={(e) => {
                                setPage(page => page + 1);
                            }}>{'>'}</Button>}
                        </Col>
                    </Row>
                    : ''}

                <Row className="mt-2 mb-2">
                    {
                        Object.entries(arrangedTOs).map(([key, val]) => {
                            return (
                                <Col className="" key={key} sm={12}>
                                    <div className="card">
                                        <div className="card-header fw-bold">
                                            {key}
                                        </div>
                                        <div className="card-body pt-0 pb-0">
                                            <Row className="p-1 border-bottom border-1 fw-bold text-center">
                                                <Col>Event</Col>
                                                <Col>Date Submitted</Col>
                                                <Col>Status</Col>
                                                <Col>TO NO.</Col>
                                                <Col></Col>
                                            </Row>
                                            {
                                                Object.entries(val).map(([toKey, toVal]) => {
                                                    let travelOrd = travelOrders.find(to => to.id === toVal);
                                                    return (
                                                        <Row className="border-bottom border-1 p-1 text-center" key={toKey}>
                                                            <Col>
                                                                {travelOrd.event}
                                                            </Col>
                                                            <Col className="">
                                                                {new DateObject(travelOrd.created_at).format("MM/DD/YYYY")}
                                                            </Col>
                                                            <Col>
                                                                <div className="progress mt-1" style={{ height: "20px" }}>
                                                                    {travelOrd.status ? <>
                                                                        <div className={`progress-bar ${travelOrd.office_approved !== 1 ? travelOrd.office_approved === null ? "bg-warning" : "bg-danger" : "bg-success"}`} style={{ width: `${travelOrd.office_approved === null ? 50 : 50}%` }}>Office</div>
                                                                        <div className={`progress-bar ${travelOrd.signed !== 1 ? travelOrd.signed === null ? "bg-warning" : "bg-danger" : "bg-success"}`} style={{ width: `${travelOrd.signed === null ? 50 : 50}%` }}>PGO</div>
                                                                    </> : <div className="progress-bar bg-danger" style={{ width: "100%" }}>Cancelled</div>}
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                {travelOrd.pgo_to_no}
                                                            </Col>
                                                            <Col>
                                                                <Row>
                                                                    <Col>
                                                                        <button className="btn btn-primary" data-key={travelOrd.id} onClick={(e) => {
                                                                            setSelectedTO(travelOrd.id);
                                                                            openTO();
                                                                        }}>
                                                                            View
                                                                        </button>
                                                                    </Col>
                                                                    <Col>
                                                                        <PrintIcon className="" role="button" onClick={(e) => {
                                                                            setSelectedTO(travelOrd.id);
                                                                            openPrint();
                                                                        }} />
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>

                {pages > 1 ?
                    <Row className="mb-2">
                        <Col className="text-center">
                            {page > 1 ? <Button size='sm' onClick={(e) => {
                                setPage(page => page - 1);
                            }}>{'<'}</Button> : ""}
                            <Form.Label className="ms-2 me-2">{`Pages ${page} of ${pages}`}</Form.Label>
                            {page === pages ? "" : <Button size='sm' onClick={(e) => {
                                setPage(page => page + 1);
                            }}>{'>'}</Button>}
                        </Col>
                    </Row>

                    : ''}
            </div>

        </>
    )
}