import React from 'react'
import PageIndex from './PageIndex.component'


export default function ApprovelPage(props) {
    
  return (
    <div className="container-fluid">
        <div className="row">
            <section className="col-lg">
                <PageIndex user={props.user}
                    showEmployeeDetails={true}
                    actor={props.actor}
                />
            </section>
        </div>
    </div>
  )
}