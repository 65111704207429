import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import Image from 'react-bootstrap/Image'
import { useNavigate } from 'react-router-dom';
import { DateObject } from "react-multi-date-picker";

export const TravelOrderLayout = React.forwardRef((props, ref) => {
    //console.log(props);
    const navigate = useNavigate();

    const fSize = 13;
    const marginTop = "20mm"
    const marginRight = "20mm"
    const marginBottom = "20mm"
    const marginLeft = "20mm"

    const getPageMargins = () => {
        return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
    };

    //useEffect(() => {console.log(props)}, [])

    return (
        <div className="" ref={ref} style={{ fontSize: `${fSize}pt`, fontFamily: `Times New Roman` }}>
            <style>{getPageMargins()}</style>
            <Row className="pt-0 mt-0">
                <Col className="" sm={3}>
                    <Row>
                        <Col className="text-end">
                            <Image
                                style={{
                                    maxWidth: "50%",
                                }}
                                src="https://upload.wikimedia.org/wikipedia/commons/3/38/Official_Seal_of_Pangasinan_2017.png"
                                roundedCircle
                            />
                        </Col>
                    </Row>
                </Col>
                <Col className="text-center" sm={6}>
                    <Row>
                        <Col>
                            <Form.Text className="text-dark m-0" style={{ fontSize: `${fSize + 1}pt` }}>Republic of the Philippines</Form.Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Text className="text-dark m-0" style={{ fontSize: `${fSize + 2}pt` }}>PROVINCE OF PANGASINAN</Form.Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Text className="text-dark fw-bold m-0" style={{ fontSize: `${fSize + 1}pt` }}>Office of The Governor</Form.Text>
                        </Col>
                    </Row><Row>
                        <Col>
                            <Form.Text className="text-dark m-0" style={{ fontSize: `${fSize + 1}pt` }}>2F Capitol Compound, Lingayen, Pangasinan</Form.Text>
                        </Col>
                    </Row>
                </Col>
                <Col sm={3}>
                    <Row>
                        <Col>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col className="" sm={8}>
                </Col>
                <Col className="ps-5">
                    <Form.Label className="mb-0">
                        ANNEX A''<br />
                        (Travel Order)
                    </Form.Label>
                </Col>
            </Row>
            <Row>
                <Col className="pe-2" sm={3}>
                    <Row>
                        <Col>DATE</Col>
                        <Col className="" sm="auto">:</Col>
                    </Row>
                </Col>
                <Col className="border-bottom border-dark text-center" sm={2}>
                    {new DateObject(props.selectedTO.created_at).format("MMMM DD, YYYY")}
                </Col>
                <Col className="text-end" sm={3}>
                    PGO Travel Order No. :
                </Col>
                <Col className="border-bottom border-dark text-center" sm={2}>
                    {/* {props.selectedTO.pgo_to_no} */} &nbsp;
                </Col>
                <Col sm={2}>
                    &nbsp;
                </Col>
            </Row>
            <Row className="mt-1">
                <Col className="pe-2" sm={3}>
                    <Row>
                        <Col >OFFICE/HOSPITAL</Col>
                        <Col className="" sm="auto">:</Col>
                    </Row>
                </Col>
                <Col className="" sm={7}>
                    <Row>
                        <Col className="border-bottom border-dark">
                            {`${props.selectedTO.office.offdesc} (${props.selectedTO.office.short})`}
                        </Col>
                    </Row>
                </Col>
                <Col sm={2}>
                    &nbsp;
                </Col>
            </Row>
            <Row className="mt-1">
                <Col className="pe-2" sm={3}>
                    <Row>
                        <Col>NAME & POSITION OF PARTICIPANTS</Col>
                        <Col className="" sm="auto">:</Col>
                    </Row>
                </Col>
                <Col className="" sm={7}>
                    {props.selectedTO.participants.map((part, key) => (
                        <Row key={key}>
                            <Col className="border-bottom border-dark">
                                <Form.Label className="m-0 p-0">
                                    {`${part.name} - 
                                    ${part.position}; 
                                    ${part.office.short}`}
                                </Form.Label>
                            </Col>
                        </Row>
                    ))}
                </Col>
                <Col sm={2}>
                    &nbsp;
                </Col>
            </Row>
            <Row className="mt-2">
                <Col className="pe-2" sm={3}>
                    <Row>
                        <Col >EVENT/PURPOSE</Col>
                        <Col className="" sm="auto">:</Col>
                    </Row>
                </Col>
                <Col className="" sm={7}>
                    <Row>
                        <Col className="">
                            <p className="m-0 p-0"><u>
                                {`${props.selectedTO.event}`}
                            </u></p>
                        </Col>
                    </Row>
                </Col>
                <Col sm={2}>
                    &nbsp;
                </Col>
            </Row>
            <Row className="mt-1">
                <Col className="pe-2" sm={3}>
                    <Row>
                        <Col >TRAVEL DATE/S</Col>
                        <Col className="" sm="auto">:</Col>
                    </Row>
                </Col>
                <Col className="" sm={7}>
                    <p className="m-0 p-0"><u>
                        {props.selectedTO.dates.map((date, key) => (
                            key === 0 ? `${new DateObject(date).format("MMMM DD, YYYY")}` : <>;&nbsp;&nbsp;{`${new DateObject(date).format("MMMM DD, YYYY")}`}</>
                        ))}
                    </u></p>
                </Col>
                <Col sm={2}>
                    &nbsp;
                </Col>
            </Row>
            <Row className="mt-1">
                <Col className="pe-2" sm={3}>
                    <Row>
                        <Col >PERMANENT STATION</Col>
                        <Col className="" sm="auto">:</Col>
                    </Row>
                </Col>
                <Col sm={7}>
                    <Row>
                        <Col>
                            <p className="m-0 p-0"><u>{`${props.selectedTO.station}`}</u></p>
                        </Col>
                    </Row>
                </Col>
                <Col sm={2}>
                    &nbsp;
                </Col>
            </Row>
            <Row className="mt-1">
                <Col className="pe-2" sm={3}>
                    <Row>
                        <Col >PLACE/S TO BE VISITED</Col>
                        <Col className="" sm="auto">:</Col>
                    </Row>
                    <Row>
                        <Col>(Please specify Itinerary)</Col>
                    </Row>
                </Col>
                <Col className="" sm={7}>
                    {props.selectedTO.itineraries.map((itin, key) => (
                        <Row key={key}>
                            <Col>
                                <p className="m-0 p-0"><u>{`${itin}`}</u></p>
                            </Col>
                        </Row>
                    ))}
                </Col>
                <Col sm={2}>
                    &nbsp;
                </Col>
            </Row>
            <Row className="mt-2">
                <Col className="pe-2" sm={3}>
                    <Row>
                        <Col >REQUIREMENTS</Col>
                        <Col className="" sm="auto">:</Col>
                    </Row>
                </Col>
                <Col sm={9}>
                    <Row>
                        <Col className="pe-1" sm="auto">1.</Col>
                        <Col className="ps-0">
                            {`Travel is on `}
                            <div className="p-0 m-0 form-check form-check-inline">
                                <input type="checkbox" className="p-0 ms-1 mt-0 me-1 mb-0" checked={props.selectedTO.travel === 0} readOnly />{`official business`}
                            </div>
                            <div className="p-0 m-0 form-check form-check-inline">
                                <input type="checkbox" className="p-0 ms-2 mt-0 me-1 mb-0" checked={props.selectedTO.travel === 1} readOnly />{`official time`}
                            </div>
                            {`.`}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="pe-1" sm="auto">2.</Col>
                        <Col className="ps-0 me-0 pe-0">
                            {`Expenses incidental thereto shall be charged against`}
                            <div className="p-0 m-0 form-check form-check-inline">
                                <input type="checkbox" className="p-0 ms-2 mt-0 me-1 mb-0" checked={props.selectedTO.charge === 0} readOnly />{`office funds`}
                            </div>
                            <div className="p-0 m-0 form-check form-check-inline">
                                <input type="checkbox" className="p-0 ms-2 mt-0 me-1 mb-0" checked={props.selectedTO.charge === 1} readOnly />{`others, specify`}
                            </div>
                            <span className="border-bottom border-dark">&nbsp;
                                {props.selectedTO.charge_specify ? props.selectedTO.charge_specify :
                                    <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                                &nbsp;</span>
                            <br />
                            {`subject to the usual accounting and auditing rules and regulations.`}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="pe-1" sm="auto">3.</Col>
                        <Col className="ps-0">
                            {`Terminal report addressed to the Governor, through the Provincial Administrator to be submitted upon completion of said travel.`}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mt-1">
                <Col className="pe-2" sm={3}>
                    <Row>
                        <Col >ATTACHMENTS</Col>
                        <Col className="" sm="auto">:</Col>
                    </Row>
                </Col>
                <Col sm={9}>
                    <Row>
                        <Col className="pe-1" sm="auto">1.</Col>
                        <Col className="ps-0">
                            {`Letter of Invitation, if applicable.`}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="pe-1" sm="auto">2.</Col>
                        <Col className="ps-0">
                            {`Budgetary Requirements for training/seminar/workshop/convention.`}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="pe-1" sm="auto">3.</Col>
                        <Col className="ps-0">
                            {`If travel is routinary, disregard item 1 and 2.`}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mt-2">
                {(props.selectedTO.office.offcode !== '101101' && !props.selectedTO.hasOfficeHead) ? <>
                    <Col className="">
                        <Row>
                            <Col>
                                Requested By:
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col>
                                <Form.Text className="text-dark fw-bold" style={{ fontSize: `${fSize + 1}pt` }}>
                                    {props.selectedTO.off_assign ?
                                        `${props.selectedTO.off_assign.plantilla.salutation ? props.selectedTO.off_assign.plantilla.salutation + " " : ""}${props.selectedTO.off_assign.plantilla.FINAME} ${props.selectedTO.off_assign.plantilla.MIDNAME.split(" ").map(word => word[0].toUpperCase() + ".").join("")} ${props.selectedTO.off_assign.plantilla.SURNAME}`.toUpperCase()
                                        : <br />
                                    }
                                </Form.Text>
                            </Col>
                        </Row>
                        <Row className="">
                            <Col className="">
                                {props.selectedTO.off_assign ? props.selectedTO.off_assign.position_title : <br />}
                            </Col>
                        </Row>
                    </Col></> :
                    <Col>
                        <br />
                        <br />
                        <br />
                        <br />
                    </Col>
                }
                <Col className="">
                    &nbsp;
                </Col>
            </Row>
            <Row className="mt-1">
                <Col className="">
                    &nbsp;
                </Col>
                <Col className="">
                    <Row>
                        <Col>
                            APPROVED
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mb-3">
                            For and By Authority of the Governor
                        </Col>
                    </Row>
                    <Row className="mt-3" >
                        <Col>
                            <Form.Text className="text-dark fw-bold" style={{ fontSize: `${fSize + 1}pt` }}>
                                {/* {`${props.selectedTO.pgo_assign.plantilla.salutation ? props.selectedTO.pgo_assign.plantilla.salutation+" " : ""}
                                ${props.selectedTO.pgo_assign.plantilla.FINAME} ${props.selectedTO.pgo_assign.plantilla.MIDNAME.split(" ").map(word => word[0].toUpperCase()+".").join("")} ${props.selectedTO.pgo_assign.plantilla.SURNAME}`.toUpperCase()} */}
                                {/* MR. MELICIO FLORES PATAGUE II */}
                                {!props.selectedTO.hasOfficeHead ? "ATTY. RONN DALE B. CASTILLO" : "MR. MELICIO FLORES PATAGUE II"}
                            </Form.Text>
                        </Col>
                    </Row>
                    <Row className="">
                        <Col>
                            {/* {props.selectedTO.pgo_assign.position_title} */}
                            {/* Provincial Administrator */}
                            {!props.selectedTO.hasOfficeHead ? "Executive Assistant III" : "Provincial Administrator"}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mt-1">
                <Col className="text-end">
                    <Image
                        style={{
                            maxWidth: "17%",
                        }}
                        src={`/resources/images/anggaling.png`}
                    />
                </Col>
            </Row>
            <Row className="mt-1 ms-0 me-0 mt-0">
                <Col className="border-start border-top border-bottom border-dark text-center">
                    <Form.Text className="text-dark fw-bold">
                        PGP-PGO-019
                    </Form.Text>
                </Col>
                <Col className="border-start border-top border-bottom border-dark text-center">
                    Revision: 00
                </Col>
                <Col className="border border-dark text-center">
                    Effectivity Date: January 23,2022
                </Col>
            </Row>
        </div>
    );
});