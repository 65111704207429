import React from 'react';
import { useEffect, useState, useRef} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from '../services/axios';
import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { useNavigate } from 'react-router-dom';
import { List } from 'react-bootstrap-icons';

const Header = (props) => {

  const [notifications, setNotifications] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [showNotif, setShowNotif] = useState(false);
  const modalRef = useRef();
  const navigate = useNavigate();

  const openNotif = () => {
    fetchNotif();
    setShowNotif(true);
  }
  const closeNotif = () => setShowNotif(false);

  const notifCountAbortControl = new AbortController();
  const notifAbortControl = new AbortController();

  const fetchNotifCount = async () => {
    await axios.get(`/api/user/notificationsCount`, {
      signal: notifCountAbortControl.signal
    })
    .then(({data}) =>{
        setNotificationsCount(data.notificationsCount);
    })
    .catch(error => {
      if(error.name === 'CanceledError'){
        console.log('Request Canceled', error);
      }else{
        console.log('Error fetching notification count', error);
      }
    })
  }

  const fetchNotif = async () => {
    //console.log('fetchnotifs');
    await axios.get(`/api/user/notifications`, {
      signal: notifAbortControl.signal
    })
    .then(({data})=>{
        setNotificationsCount(0);
        setNotifications(data.notifications);
    })
    .catch(error => {
      if(error.name === 'CanceledError'){
        console.log('Request Canceled', error);
      }else{
        console.log('Error fetching notification count', error);
      }
    })
  }

  useEffect(() => {
    fetchNotifCount();
    const intervalId = setInterval(() => {
      fetchNotifCount();
    }, 30000);
    document.addEventListener('mousedown', handleOutsideClick); 

    return () => {
      clearInterval(intervalId);
      document.removeEventListener('mousedown', handleOutsideClick);
      notifCountAbortControl.abort();
      notifAbortControl.abort();
    };

  }, []);
  
  const handleOutsideClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      //console.log("close notif");
      closeNotif();
    }
  };

  const updateNotif = async (notif, key) => {
    let update = [...notifications];
    update[key].status = 1;
    setNotifications(update);
    await axios.post(`/api/user/notifications/update`, {notificationID : notif.id}).then(({data})=>{
    //   if(data.result){
    //     console.log("notif viewed");
    //   }
    })
    .catch(error => {
      if(error.name === 'CanceledError'){
        console.log('Request Canceled', error);
      }else{
        console.log('Error updating notifications', error);
      }
    })
  }

  const notifGoTo = (notif, key) => {
    updateNotif(notif, key);
    navigate(notif.link);
  }

  return (
    <div className='text-light cus-color-1' id="header">

      <Row className="h-100 m-0 p-0" style={{'position':'relative'}}>
      <Modal 
          backdrop={false}
          scrollable={true}
          show={showNotif}
          onHide={closeNotif}
          dialogClassName="notification-modal"
        >
            <Modal.Body className="p-0 cus-color-2" ref={modalRef}>
              {notifications.length ? 
                <ListGroup className="">
                {notifications.map((notif, key) => {
                  return <ListGroup.Item
                          key={key}
                          className={`pt-3 pb-3 ${notif.status === 0 ? 'cus-color-2 text-light' : 'cus-color-1 text-secondary'} cus-list-hover-2 border-0 rounded-0`}
                          // className={`pt-3 pb-3 cus-color-1 cus-list-hover-2 text-secondary border-0 rounded-0`}
                          action
                          onClick={() => notifGoTo(notif, key)}
                        >
                          <b>{notif.counter > 1 ? notif.counter+" " : ""}</b>{notif.message}
                        </ListGroup.Item>
                })}
              </ListGroup>
              : <span className="text-light d-flex justify-content-center align-items-center">Loading...</span>}
            </Modal.Body>
        </Modal>
        <Col lg={'auto'} md={'auto'} sm={'auto'} xs={'auto'} className="h-100 m-0 p-2 d-flex justify-content-center align-items-center">
        <button className="btn btn-primary navB"  onClick={()=>props.togglenav(!props.navVisible)} aria-expanded={props.navVisible}>
          <List size={30}/>
        </button>
        
        </Col>
        <Col className="h-100 m-0 p-0 d-flex justify-content-center align-items-center">
          Welcome, {props.user.plantilla.FINAME}
        </Col>
        <Col lg={'auto'} md={'auto'} sm={'auto'} xs={'auto'} className="h-100 m-0 p-0 d-flex justify-content-end" role="button">
          <div className={`${showNotif ? 'cus-color-2' : ''} pe-3 ps-3 h-100`} style={{'position': 'relative'}} onClick={()  => {openNotif()}}>
            { notificationsCount ? 
              <div className="bg-danger rounded-circle ps-1 pe-1" style={{'height' : '15px', 'fontSize' : '11px', 'position' : 'absolute', 'top' : '4pt', 'right' : '6px'}}>{notificationsCount}</div>
              : <></>
            }
            <div className="h-100 d-flex justify-content-center align-items-center">{notificationsCount ? <NotificationsIcon /> : <NotificationsNoneIcon />}</div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Header
