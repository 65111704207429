import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from '../../services/axios';
import Button from 'react-bootstrap/Button'
import ListGroup from 'react-bootstrap/ListGroup'
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

export default function EmployeeListSearchable(props) {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [modeValue, setModeValue] = useState(0);

  const searchModes = [
    { name: 'Name', value: 0 },
    { name: 'IDNO', value: 1 },
    { name: 'Office', value: 2 },
  ];

  const searchPlantilla = async () => {
    if(searchTerm !== ''){
        let search = {
                    searchWord : searchTerm,
                    mode : modeValue,
                    ...(props.showUnique && { showUnique: true })
                }

      await axios.post(`/api/plantilla/searchEmployee`, search).then(({data})=>{
        //console.log(data);
        setEmployeeList(data.result);
      })
    }
  }

  useEffect(() => {
      const results = employeeList.filter((employee) =>
        employee.name.toLowerCase().includes(searchTerm.toLowerCase()) || props.selectedEmployee.includes(employee.id)
      );
      setSearchResults(results);
  }, [searchTerm]);

  useEffect(() => {
    setSearchResults(employeeList);
}, [employeeList]);

  /*useEffect(() => {
    props.setFetchSelectedEmployee(selectedEmployees);
    const results = employeeList.filter((employee) =>
      employee.name.toLowerCase().includes(searchTerm.toLowerCase()) || selectedEmployees.includes(employee.unique)
    );
    setSearchResults(results);
  }, [selectedEmployees]);*/

  const handleEmployeeClick = (employee) => {
    if (props.allowMultiple) {
      if (props.selectedEmployee.find(emp => emp.name === employee.name)) {
        props.setCheckSelectedEmployee(props.selectedEmployee.filter(emp => emp.id !== employee.id));
      } else {
        props.setCheckSelectedEmployee([...props.selectedEmployee, employee]);
      }
    } else {
        if(!props.selectedEmployee.includes(employee)){
          props.setCheckSelectedEmployee([employee]);
        }
    }
  };

  return (
    <div className={`card p-0 ms-0 me-0 mt-0 mb-0${props.required ? " border-danger" : ""}`} style={props.minHeight || props.maxHeight ? {minHeight: `${props.minHeight || "300px"}`, maxHeight: `${props.maxHeight || '300px'}`} : props.subHeight ? {height:`calc(100% - ${props.subHeight})`} : {height : '100%'}}>
      <div className="card-header">
        
        {/* <Row className="">
          <Col className="text-center">
            <Form.Label>Employee Search</Form.Label>
          </Col>
        </Row> */}

        <Row>
          <Col className="m-0 p-0">
            <Form.Control
              type="text"
              placeholder="Search Employee"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Col>
        </Row>

        <Row>
          <Col className="ms-0 me-0 mb-0 mt-2 p-0 text-center">
            <ButtonGroup className="ms-1 me-1">
              {searchModes.map((mode, idx) => (
                <ToggleButton
                  className={idx === 0 ? "toggle-button-first-item" : ""}
                  key={idx}
                  id={`mode-${idx}`}
                  type="radio"
                  variant='outline-primary'
                  name="mode"
                  value={mode.value}
                  checked={modeValue === mode.value}
                  onChange={(e) => setModeValue(parseInt(e.currentTarget.value))}
                >
                  {mode.name}
                </ToggleButton>
              ))}
            </ButtonGroup>
            <Button variant="primary" block="block" onClick={searchPlantilla}>Search</Button>
          </Col>
          {/* <Col className="ms-0 me-0 mb-0 mt-2 p-0 btn-block text-center" sm="auto">
          </Col> */}
        </Row>
      </div>
      <div className="card-body pt-0 pb-1 ps-0 pe-0 m-0 overflow-auto emp-list-container">
        <ListGroup className="" variant="flush">
          <div className=''>
            {searchResults.map((employee) => (
              <ListGroup.Item
                className=""
                key={employee.id}
                onClick={() => handleEmployeeClick(employee)}
                active={props.selectedEmployee.find(emp => emp.name === employee.name)}
                // active={props.selectedEmployee.includes(employee)}
                action
              >
                {employee.name}
                {props.showUnique ? <><br/>{employee.unique}</> : ""}
              </ListGroup.Item>
            ))}
          </div>
        </ListGroup>
      </div>
    </div>
  );
}
