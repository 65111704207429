import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom'
import DatePicker, { DateObject } from "react-multi-date-picker";
import SearchIcon from '@mui/icons-material/Search';


export default function QueryFilter(props) {
    const navigate = useNavigate();
    let curData = {};

    Object.entries(props.inputs).map(([key, val], i) => {
        curData[key] = val.input === "date" ? val.defaultValue || new DateObject().format('YYYY-MM-DD') : val.defaultValue !== undefined ? val.defaultValue : '';
    });

    const [inputData, setInputData] = useState(curData);

    useEffect(()=>{
        props.fetch(inputData, props.perPage);
    }, [props.page])

    return (
        <div className="d-flex flex-row align-items-center justify-content-end">
            {Object.entries(props.inputs).map(([key, val], i) => {
                let formInput = '';
                switch(val.input){
                    case 'select':
                        formInput = <Form.Select className="query-input" name={key} value={inputData[key]} onChange={(event) => {
                            let val = event.target.value;
                            if(val !== '' && val !== 'null'){
                                val = isNaN(val) ? val : parseInt(val);
                            }
                            let inData = {...inputData};
                            inData[key] = val;
                            setInputData(inData);
                        }} >
                            {val.blankOption ? <option value={val.blankOption.value}>{val.blankOption.label}</option> : ''}
                            {Object.entries(val.selectOptions).map(([key, opt], i) => {
                                if(val.optionMap){
                                    return (<option key={i} value={opt[val.optionMap.value]}>{opt[val.optionMap.label]}</option>)
                                }else{
                                    return (<option key={i} value={opt.value}>{opt.label}</option>)
                                }
                            })}
                        </Form.Select>
                        break;
                    case 'date':
                        formInput = <DatePicker
                            value={inputData[key]}
                            onChange={(date) => {
                                let inData = {...inputData};
                                inData[key] = date.format('YYYY-MM-DD');
                                setInputData(inData);
                            }}
                            inputClass="form-control query-input"
                        />
                        break;
                    case 'input':
                        formInput = <Form.Control className="query-input" as="input" placeholder="Enter TO NO." value={inputData[key]} onChange={(event)=>{
                            let val = event.target.value;
                            let inData = {...inputData};
                            inData[key] = val;
                            setInputData(inData);
                        }}/>
                    default:
                        break;
                }
                
                return (
                    <div className="p-1" sm={2} key={i}>
                        {formInput}
                    </div>
                )
            })}
            <Button className="" style={{ height: '80%' }} size="sm" onClick={(e) => {
                if(props.page === 1){
                    props.fetch(inputData);
                }else{
                    props.setPage(1);
                }
            }}>
                <SearchIcon />
            </Button>
        </div>  
    )
}