import { useRef, useState } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import axios from '../services/axios';
import { Link, useNavigate } from 'react-router-dom';

const Login = (props) => {
    const [invalid, setInvalid] = useState(false)
    const usernameRef = useRef(null)
    const passwordRef = useRef(null)
    const navigate = useNavigate();
    const loginUser = async () => {

        const username = usernameRef.current.value
        const password = passwordRef.current.value

        // Create the request body
        let Credentials = {
            username: username,
            password: password
        }

        await axios.post('/api/login', Credentials)
            .then((response) => {
                if (response.data?.modules?.length > 0) { response.data['firstApp'] = props.appModules.find(app => response.data?.modules[0]?.abilities[0]?.abilities_id === app.id) }
                //console.log(response.data);
                props.setUser(response.data)
                setInvalid(false)
                navigate("/")
            })
            .catch((error) => {
                setInvalid(true)
                //console.log(error)
            })
    }

    const requestforRegister = async () => {

        const username = usernameRef.current.value
        const password = passwordRef.current.value

        // Create the request body
        let registration_data = {
            username: username,
            password: password
        }
        //console.log(registration_data)
        await axios.post('/api/register', registration_data)
            //.then((response) => setUserData(response.data))
            .then((response) => console.log("User Registration Successful " + JSON.stringify(response.data)))
            .catch((error) => {
                //console.log(error.data)
            })
    }

    const handleRegister = (event) => {
        event.preventDefault()

        requestforRegister()

    }

    const handleLogin = (event) => {
        event.preventDefault()
        loginUser()
    }


    return (
        <div className="d-flex align-items-center vh-100">
            <div className="login-background-circle rounded-circle"></div>
            <div className="container">
                <Row className="justify-content-center">
                    <Col className="" sm={3} style={{ minWidth: '350px' }}>
                        <div className="card shadow-lg cus-color-1">
                            <div className="card-body text-center">
                                <div className="row mb-3">
                                    <div className="col">
                                        <div className="row">
                                            <div className="col">
                                                <Image
                                                    style={{
                                                        maxWidth: "80%",
                                                    }}
                                                    src="/resources/images/locker_dots.svg"
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col text-white">Human Resource Information System<br />Provincial Government of Pangasinan</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="row">
                                            <div className="col text-white mb-1">Enter Credentials</div>
                                        </div>
                                        <form onSubmit={handleLogin}>
                                            <div className="row">
                                                <div className="col">
                                                    <div className="input-group mb-3">
                                                        <input type="text" className="form-control" id="username" placeholder="Username" ref={usernameRef} aria-label="Username" required />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <div className="input-group mb-3">
                                                        <input type="password" className="form-control" id="password" placeholder="Password" ref={passwordRef} aria-label="Password" required />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <div className="d-grid mb-2">
                                                        <button className="btn btn-primary">Login</button>
                                                    </div>
                                                </div>
                                            </div>
                                            {invalid ? (<div style={{ textAlign: "center", color: "red", fontSize: "15px", marginTop: "5px" }}>Invalid Credentials. Please try again.</div>) : (<></>)}
                                        </form>
                                        <div className="row">
                                            <div className="col">
                                                <div className="d-grid">
                                                    <Link to="/registration">Registration</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Login;
