import React, { useState } from 'react'
import AddNewEmployee from '../components/AddNewEmployee';

import axios from 'axios'
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'


export default function SearchEmployee() {

    //loading effect
    const [isLoading, setIsLoading] = useState(false);

    // Modal for Cretae Employeee 
    const [showCreateModal, setShowCreateModal] = useState(false);

    //Modal for Cretae Employeee
    const handleCreateModalOpen = () => {
        setShowCreateModal(true);
    };

    //get the radio button value
    const [selectedSearchCategory, setselectedSearchCategory] = useState('');
    // Search 
    const [filterEmployee, setFilterEmployee] = useState('');

    // List of Employee
    const [fetchListEmp, setListEmp] = useState([]);

    //fetch the search category 
    const handleSearchCategoryChange = (event) => {
        setselectedSearchCategory(event.target.value);
      };

    const submitSearch = async (e) => {
        e.preventDefault();

        setIsLoading(true);

            await axios.post(`/api/SearchEmp`, {

                SearchCategory: selectedSearchCategory,
                FilterEmployee: filterEmployee,
                
            }).then((res) => {
                setListEmp(res.data.ListEmp)     
            }).finally(() => {
                setIsLoading(false);
            });
       
    }

    
    
return (
        <section className="content">
        <div className="row">
            <div className="col-md-12">
                <div className="card card-outline card-primary">
                    <div className="card-header">
                    <h3 className="card-title"><i className="fas fa-list" /> List of Employee</h3>
                    </div>
                     {/* /.card-header */}
                    <div className="card-body">

                    <div className="mb-3">
                        <Button type='button' variant="outline-primary" title='Cretae' onClick={handleCreateModalOpen}> <i className="fas fa-user-plus" /> Create New Employee</Button>  
                                    {/* <AddNewEmployee showCreateModal={showCreateModal} setShowCreateModal={setShowCreateModal} fetchListService={fetchListService} />           */}
                        <AddNewEmployee showCreateModal={showCreateModal} setShowCreateModal={setShowCreateModal}  />  
                    </div>

                                
                {/* Search Bar  */}
                <Form onSubmit={submitSearch}>
                
                    <div className="mb-2">
              
                        
                        <div className="form-check form-check-inline">
                     
                        <label className="form-check-label font-weight-bold" style={{ marginRight:'3px' }}>Search Category: </label>

                            <input className="form-check-input" type="radio" id="EmpNum_id" name="emp_category" value="emp_num" checked={selectedSearchCategory === 'emp_num'} onChange={handleSearchCategoryChange}  />
                            <label className="form-check-label font-weight-bold" htmlFor="EmpNum_id">Employee Number</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" id="EmpName_id" name="emp_category" value="emp_name" checked={selectedSearchCategory === 'emp_name'} onChange={handleSearchCategoryChange} />
                            <label className="form-check-label font-weight-bold" htmlFor="EmpName_id" >Employee Name (Ex. Juan Dela Cruz)</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" id="EmpOffice_id" name="emp_category" value="emp_office" checked={selectedSearchCategory === 'emp_office'} onChange={handleSearchCategoryChange} />
                            <label className="form-check-label font-weight-bold" htmlFor="EmpOffice_id">Office</label>
                        </div>
                    </div> 

                    <div className="form-row">
                        <div className="col-10">
                            <div className="input-group mb-2">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1"><i className="fas fa-search"/></span>
                                </div>
                                <input type="text" className="form-control" name="emp_search" value={filterEmployee} onChange={(e) => setFilterEmployee(e.target.value) }  placeholder="Search here ...." />

                            </div>
                        </div>                
                        <div className="col-2">
                            <Button type="submit" variant="outline-primary"><i className="fas fa-search"/> Search</Button>
                        </div>      

                    </div>
                </Form>     
                                
                        
                                  
                        <div className="row">
                            <div className="col-sm-12">
                                

                                    <div className="card-body table-responsive p-0" style={{ height: "600px", }}>
                                        <table className="table table-head-fixed text-nowrap" width="100%" cellSpacing="0">
                                        <thead>
                                                <tr>
                                                
                                                    <th>
                                                        ID Number
                                                    </th> 
                                                    <th>
                                                        Employee Name
                                                    </th>
                                                    <th>
                                                        Designation
                                                    </th> 
                                                    <th>
                                                        Office/Hospital
                                                    </th>
                                                    <th>
                                                        Type of Employment
                                                    </th>          
                                                    <th>
                                                        Status
                                                    </th>  
                                                    <th>
                                                    <center>Action</center>
                                                    </th>  
                                                
                                                </tr>
                                        </thead>
                                        
                                                
                     
                                                            
                                        <tbody>
                                            {
                                             isLoading ? (
                                                <tr>
                                                    <td colSpan={7} align="center">
                                                    <div className="spinner-border text-primary" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>         
                                                    </td>
                                                </tr>
                                            )  : 
                                                        
                                                fetchListEmp.length > 0 ?               
                                                    fetchListEmp.map((data) => {
                                                    
                                                        const {
                                                            SURNAME,
                                                            FINAME,
                                                            MIDNAME,
                                                            unique,
                                                            IDNO,
                                                            offdesc,
                                                            designation,
                                                            ESTA,
                                                            SERVICESTAT
                                                        } = data;
                                                        return (
                                                            <tr key={unique}>
                                                                <td>{ IDNO }</td>
                                                                <td>{ SURNAME+',  '+ FINAME +' '+ MIDNAME }</td>
                                                                <td>{ designation }</td>
                                                                <td>{ offdesc }</td>
                                                                <td>{ ESTA }</td>
                                                                <td>{SERVICESTAT}</td>
                                                                <td align="center">
                                                                     <Link to={`/employee-details/${unique}`} title="View" className="btn btn-outline-primary" ><i className="fas fa-eye" /></Link>
                                                                    {/* <button type="button" title="View" className="btn btn-outline-primary" onClick={() => navigate(`serviceRecord/`)} ><i className="fas fa-eye"></i></button> */}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })  
                                                :
                                                (
                                                    <tr>
                                                        <td colSpan={12} align="center" >No Data Found</td>
                                                    </tr>          
                                                )   
                                            }
                                        </tbody>       









                                                      


                                        </table>
                                    </div>
                                    {/* table-responsive */}



                           
                                
                            </div>
                        </div>
                     {/* row  */}
                    </div>
                    {/* card-body */}
                </div>
            </div>
            {/* /.col*/}
        </div>
        {/* ./row */}

        </section>

    
  )
}
