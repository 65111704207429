import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Swal from 'sweetalert2';
import axios from '../../../services/axios';
import DatePicker, { DateObject } from "react-multi-date-picker";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import EmployeeList from '../../Global/EmployeeList.component';

export default function CreateModal(props) {

    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [selectedDate, setSelectedDate] = useState([]);
    const [selectedMode, setSelectedMode] = useState(1);
    const [selectedWeekDays, setSelectedWeekDays] = useState({
        'Sun': 0,
        'Mon': 1,
        'Tue': 1,
        'Wed': 1,
        'Thu': 1,
        'Fri': 1,
        'Sat': 0,
    });

    const [sched, setSched] = useState({ amIn: "08:00", amOut: "12:00", pmIn: "13:00", pmOut: "17:00" });

    useEffect(() => {
        //console.log(props.sDate);
        let start = new DateObject(`${props.sDate.year}-${props.sDate.month.number}-01`);
        let end = new DateObject(`${props.sDate.year}-${props.sDate.month.number}-${props.sDate.month.length}`);
        setSelectedDate([start, end]);
    }, [])

    useEffect(() => {
        if (selectedMode === 1) {
            setSelectedWeekDays({
                'Sun': 0,
                'Mon': 1,
                'Tue': 1,
                'Wed': 1,
                'Thu': 1,
                'Fri': 1,
                'Sat': 0,
            });
            setSched({ amIn: "08:00", amOut: "12:00", pmIn: "13:00", pmOut: "17:00" });
        } else if (selectedMode === 2) {
            setSched({
                Sun: { amIn: "", amOut: "", pmIn: "", pmOut: "" },
                Mon: { amIn: "08:00", amOut: "12:00", pmIn: "13:00", pmOut: "17:00" },
                Tue: { amIn: "08:00", amOut: "12:00", pmIn: "13:00", pmOut: "17:00" },
                Wed: { amIn: "08:00", amOut: "12:00", pmIn: "13:00", pmOut: "17:00" },
                Thu: { amIn: "08:00", amOut: "12:00", pmIn: "13:00", pmOut: "17:00" },
                Fri: { amIn: "08:00", amOut: "12:00", pmIn: "13:00", pmOut: "17:00" },
                Sat: { amIn: "", amOut: "", pmIn: "", pmOut: "" }
            });
        } else if (selectedMode === 3) {
            setIrregularSched();
        }
    }, [selectedMode])

    useEffect(() => {
        if (selectedDate.length === 2 && selectedMode === 3) {
            setIrregularSched();
        }
    }, [selectedDate])

    const setIrregularSched = () => {
        let schedules = {}
        let start = new DateObject(selectedDate[0].format("YYYY-MM-DD"));
        let end = new DateObject(selectedDate[1].format("YYYY-MM-DD"));
        for (let i = start.day; start.valueOf() <= end.valueOf(); i++) {
            schedules[start.format("YYYY-MM-DD")] = {
                amIn: "",
                amOut: "",
                pmIn: "",
                pmOut: ""
            };
            start.add(1, 'days');
        }
        //console.log(schedules);
        setSched(schedules);
    }

    const setFetchEmployeeSchedule = (id) => {
        if (selectedEmployee !== id) {
            setSelectedEmployee(id);
        }
    }

    const handleSchedChange = (e, day = null) => {
        let name = e.target.name;
        let val = e.target.value;
        let newSched = { ...sched };

        if (selectedMode === 1) {
            newSched[name] = val;
        } else {
            newSched[day][name] = val;
        }
        setSched(newSched);
    }

    const createNewSchedule = async () => {
        let schedules = {};
        if (selectedMode === 1) {
            let start = new DateObject(selectedDate[0].format("YYYY-MM-DD"));
            let end = new DateObject(selectedDate[1].format("YYYY-MM-DD"));
            for (let i = start.day; start.valueOf() <= end.valueOf(); i++) {
                if (selectedWeekDays[start.weekDay.shortName]) {
                    schedules[start.format("YYYY-MM-DD")] = sched;
                } else {
                    schedules[start.format("YYYY-MM-DD")] = { amIn: "", amOut: "", pmIn: "", pmOut: "" };
                }
                start.add(1, 'days');
            }
        } else if (selectedMode === 2) {
            let start = new DateObject(selectedDate[0].format("YYYY-MM-DD"));
            let end = new DateObject(selectedDate[1].format("YYYY-MM-DD"));
            for (let i = start.day; start.valueOf() <= end.valueOf(); i++) {
                schedules[start.format("YYYY-MM-DD")] = {
                    amIn: sched[start.weekDay.shortName].amIn,
                    amOut: sched[start.weekDay.shortName].amOut,
                    pmIn: sched[start.weekDay.shortName].pmIn,
                    pmOut: sched[start.weekDay.shortName].pmOut
                };
                start.add(1, 'days');
            }
        } else if (selectedMode === 3) {
            schedules = sched;
        }

        let schedData = {
            employees: selectedEmployee,
            dateStart: selectedDate[0].format('YYYY-MM-DD'),
            dateEnd: selectedDate[1].format('YYYY-MM-DD'),
            schedules: schedules,
            mode: selectedMode
        }
        Swal.fire({
            icon: 'info',
            text: 'Creating Schedule...',
            showConfirmButton: false
        });
        await axios.post(`/api/employeeschedule/create`, schedData).then(({ data }) => {
            Swal.fire({
                icon: "success",
                text: "Schedules Created",
                showConfirmButton: false
            }).then(() => {
                props.closeFetchCreate();
            });
            // Swal.close();
            // props.closeFetchCreate();
        }).catch((error) => {
            Swal.update({
                icon: "error",
                text: error.response.data.message,
                showConfirmButton: true
            })
        })
    }

    const confirmCreateSchedule = () => {
        //console.log(selectedEmployee);
        if (selectedEmployee.length !== 0) {
            Swal.fire({
                title: 'Create new schedule?',
                text: 'This will overwrite existing schedules for the selected employees.',
                //showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: 'Yes',
                denyButtonText: 'No',
                customClass: {
                    actions: 'my-actions',
                    cancelButton: 'order-2',
                    confirmButton: 'order-1 right-gap',
                    //denyButton: 'order-2',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    createNewSchedule();
                }
            })
        } else {
            /*let message;
            if(selectedEmployee.length === 0){
                message = "Please select an employee."
            }else if(employeeSchedule.length !== 0){
                message = "Schedule already exists."
            }*/
            Swal.fire({
                icon: "warning",
                text: "Please select an employee."
            })
        }
    }

    const timeList = {
        "12:00 AM": "00:00", "12:30 AM": "00:30",
        "01:00 AM": "01:00", "01:30 AM": "01:30",
        "02:00 AM": "02:00", "02:30 AM": "02:30",
        "03:00 AM": "03:00", "03:30 AM": "03:30",
        "04:00 AM": "04:00", "04:30 AM": "04:30",
        "05:00 AM": "05:00", "05:30 AM": "05:30",
        "06:00 AM": "06:00", "06:30 AM": "06:30",
        "07:00 AM": "07:00", "07:30 AM": "07:30",
        "08:00 AM": "08:00", "08:30 AM": "08:30",
        "09:00 AM": "09:00", "09:30 AM": "09:30",
        "10:00 AM": "10:00", "10:30 AM": "10:30",
        "11:00 AM": "11:00", "11:30 AM": "11:30",
        "12:00 PM": "12:00", "12:30 PM": "12:30",
        "01:00 PM": "13:00", "01:30 PM": "13:30",
        "02:00 PM": "14:00", "02:30 PM": "14:30",
        "03:00 PM": "15:00", "03:30 PM": "15:30",
        "04:00 PM": "16:00", "04:30 PM": "16:30",
        "05:00 PM": "17:00", "05:30 PM": "17:30",
        "06:00 PM": "18:00", "06:30 PM": "18:30",
        "07:00 PM": "19:00", "07:30 PM": "19:30",
        "08:00 PM": "20:00", "08:30 PM": "20:30",
        "09:00 PM": "21:00", "09:30 PM": "21:30",
        "10:00 PM": "22:00", "10:30 PM": "22:30",
        "11:00 PM": "23:00", "11:30 PM": "23:30",
    }

    return (
        <Row>
            <Col xs={4}>
                <EmployeeList
                    employeeList={props.employeeList}
                    divisionList={props.divisionList}
                    selectedEmployee={selectedEmployee}
                    selectEffect={setFetchEmployeeSchedule}
                    maxHeight={"300px"}
                    allowMultiple={true}
                    show={{ position:true, division:true}}
                />
            </Col>
            <Col className="text-center pe-3" xs={8}>
                <Row className="mb-2">
                    <Col>
                        <Row>
                            <Col>
                                <Form.Label>Select Date Range</Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DatePicker style={{ height: "35px" }} className="m-auto"
                                    range={true}
                                    //render={<InputIcon/>}
                                    value={selectedDate}
                                    onChange={(e) => { setSelectedDate(e) }}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <Form.Label>Select Mode</Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Select className="text-center" name="toOnSelect" value={selectedMode} onChange={(event) => {
                                    let val = parseInt(event.target.value);
                                    setSelectedMode(val);
                                }}>
                                    {[{ text: "Daily", value: 1 }, { text: "Weekly", value: 2 }, { text: "Irregular", value: 3 }].map((opt, key) => (
                                        <option key={opt.value}
                                            value={opt.value}
                                            defaultValue={0}
                                        >{opt.text}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {selectedMode === 1 ?
                    <>
                        <Row className="mt-3">
                            <Col>
                                <Form.Label>Daily Schedule</Form.Label>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <ButtonGroup>
                                    {Object.keys(selectedWeekDays).map((wkDay, idx) => (
                                        <ToggleButton
                                            className={wkDay === "Sun" ? "toggle-button-first-item" : ""}
                                            key={idx}
                                            id={idx}
                                            type="checkbox"
                                            variant='outline-secondary'
                                            name="selectedWeekDay"
                                            value={selectedWeekDays[wkDay]}
                                            checked={selectedWeekDays[wkDay] === 1}
                                            onChange={(e) => {
                                                let sWD = { ...selectedWeekDays };
                                                sWD[wkDay] = sWD[wkDay] ? 0 : 1;
                                                setSelectedWeekDays(sWD);
                                            }}
                                        >
                                            {`${wkDay}`}
                                        </ToggleButton>
                                    ))}
                                </ButtonGroup>
                            </Col>
                        </Row>
                        <Row className="border-top border-start border-end border-1 border-dark">
                            <Col className="border-end border-1 border-dark">
                                <Form.Label className="m-auto">AM IN</Form.Label>
                            </Col>
                            <Col className="border-end border-1 border-dark bg-light">
                                <Form.Label className="m-auto">AM OUT</Form.Label>
                            </Col>
                            <Col className="border-end border-1 border-dark">
                                <Form.Label className="m-auto">PM IN</Form.Label>
                            </Col>
                            <Col className="bg-light">
                                <Form.Label className="m-auto">PM OUT</Form.Label>
                            </Col>
                        </Row>
                        <Row className="border border-1 border-dark mb-2">
                            <Col className="border-end border-1 border-dark p-0">
                                <select className="schedule-select w-100 text-center" name="amIn" value={sched.amIn || ""}
                                    onChange={(e) => {
                                        handleSchedChange(e);
                                    }}>
                                    <option value={""}></option>
                                    {Object.keys(timeList).map((time, index) => (
                                        <option key={index} value={timeList[time]}>{time}</option>
                                    ))}
                                </select>
                            </Col>
                            <Col className="border-end border-1 border-dark p-0">
                                <select className="schedule-select w-100 text-center bg-light" name="amOut" value={sched.amOut || ""}
                                    onChange={(e) => {
                                        handleSchedChange(e);
                                    }}>
                                    <option value={""}></option>
                                    {Object.keys(timeList).map((time, index) => (
                                        <option key={index} value={timeList[time]}>{time}</option>
                                    ))}
                                </select>
                            </Col>
                            <Col className="border-end border-1 border-dark p-0">
                                <select className="schedule-select w-100 text-center" name="pmIn" value={sched.pmIn || ""}
                                    onChange={(e) => {
                                        handleSchedChange(e);
                                    }}>
                                    <option value={""}></option>
                                    {Object.keys(timeList).map((time, index) => (
                                        <option key={index} value={timeList[time]}>{time}</option>
                                    ))}
                                </select>
                            </Col>
                            <Col className="border-1 border-dark p-0">
                                <select className="schedule-select w-100 text-center bg-light" name="pmOut" value={sched.pmOut || ""}
                                    onChange={(e) => {
                                        handleSchedChange(e);
                                    }}>
                                    <option value={""}></option>
                                    {Object.keys(timeList).map((time, index) => (
                                        <option key={index} value={timeList[time]}>{time}</option>
                                    ))}
                                </select>
                            </Col>
                        </Row>
                    </> :
                    (selectedMode === 2 && sched.Sun) ?
                        <>
                            <Row className="mt-3">
                                <Col>
                                    <Form.Label>Weekly Schedule</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row className="border-top border-start border-end border-1 border-dark">
                                        <Col className="border-end border-1 border-dark">

                                        </Col>
                                        <Col className="border-end border-1 border-dark">
                                            <Form.Label className="m-auto">AM IN</Form.Label>
                                        </Col>
                                        <Col className="border-end border-1 border-dark bg-light">
                                            <Form.Label className="m-auto">AM OUT</Form.Label>
                                        </Col>
                                        <Col className="border-end border-1 border-dark">
                                            <Form.Label className="m-auto">PM IN</Form.Label>
                                        </Col>
                                        <Col className="bg-light">
                                            <Form.Label className="m-auto">PM OUT</Form.Label>
                                        </Col>
                                    </Row>
                                    <Row className="border-top border-start border-end border-1 border-dark">
                                        <Col className="border-end border-1 border-dark p-0"><Form.Label className="m-auto">SUN</Form.Label></Col>
                                        <Col className="border-end border-1 border-dark p-0">
                                            <select className="schedule-select w-100 text-center" name="amIn" value={sched.Sun.amIn || ""}
                                                onChange={(e) => {
                                                    handleSchedChange(e, "Sun");
                                                }}>
                                                <option value={""}></option>
                                                {Object.keys(timeList).map((time, index) => (
                                                    <option key={index} value={timeList[time]}>{time}</option>
                                                ))}
                                            </select>
                                        </Col>
                                        <Col className="border-end border-1 border-dark p-0">
                                            <select className="schedule-select w-100 text-center bg-light" name="amOut" value={sched.Sun.amOut || ""}
                                                onChange={(e) => {
                                                    handleSchedChange(e, "Sun");
                                                }}>
                                                <option value={""}></option>
                                                {Object.keys(timeList).map((time, index) => (
                                                    <option key={index} value={timeList[time]}>{time}</option>
                                                ))}
                                            </select>
                                        </Col>
                                        <Col className="border-end border-1 border-dark p-0">
                                            <select className="schedule-select w-100 text-center" name="pmIn" value={sched.Sun.pmIn || ""}
                                                onChange={(e) => {
                                                    handleSchedChange(e, "Sun");
                                                }}>
                                                <option value={""}></option>
                                                {Object.keys(timeList).map((time, index) => (
                                                    <option key={index} value={timeList[time]}>{time}</option>
                                                ))}
                                            </select>
                                        </Col>
                                        <Col className="border-1 border-dark p-0">
                                            <select className="schedule-select w-100 text-center bg-light" name="pmOut" value={sched.Sun.pmOut || ""}
                                                onChange={(e) => {
                                                    handleSchedChange(e, "Sun");
                                                }}>
                                                <option value={""}></option>
                                                {Object.keys(timeList).map((time, index) => (
                                                    <option key={index} value={timeList[time]}>{time}</option>
                                                ))}
                                            </select>
                                        </Col>
                                    </Row>
                                    <Row className="border-top border-start border-end border-1 border-dark">
                                        <Col className="border-end border-1 border-dark p-0"><Form.Label className="m-auto">MON</Form.Label></Col>
                                        <Col className="border-end border-1 border-dark p-0">
                                            <select className="schedule-select w-100 text-center" name="amIn" value={sched.Mon.amIn || ""}
                                                onChange={(e) => {
                                                    handleSchedChange(e, "Mon");
                                                }}>
                                                <option value={""}></option>
                                                {Object.keys(timeList).map((time, index) => (
                                                    <option key={index} value={timeList[time]}>{time}</option>
                                                ))}
                                            </select>
                                        </Col>
                                        <Col className="border-end border-1 border-dark p-0">
                                            <select className="schedule-select w-100 text-center bg-light" name="amOut" value={sched.Mon.amOut || ""}
                                                onChange={(e) => {
                                                    handleSchedChange(e, "Mon");
                                                }}>
                                                <option value={""}></option>
                                                {Object.keys(timeList).map((time, index) => (
                                                    <option key={index} value={timeList[time]}>{time}</option>
                                                ))}
                                            </select>
                                        </Col>
                                        <Col className="border-end border-1 border-dark p-0">
                                            <select className="schedule-select w-100 text-center" name="pmIn" value={sched.Mon.pmIn || ""}
                                                onChange={(e) => {
                                                    handleSchedChange(e, "Mon");
                                                }}>
                                                <option value={""}></option>
                                                {Object.keys(timeList).map((time, index) => (
                                                    <option key={index} value={timeList[time]}>{time}</option>
                                                ))}
                                            </select>
                                        </Col>
                                        <Col className="border-1 border-dark p-0">
                                            <select className="schedule-select w-100 text-center bg-light" name="pmOut" value={sched.Mon.pmOut || ""}
                                                onChange={(e) => {
                                                    handleSchedChange(e, "Mon");
                                                }}>
                                                <option value={""}></option>
                                                {Object.keys(timeList).map((time, index) => (
                                                    <option key={index} value={timeList[time]}>{time}</option>
                                                ))}
                                            </select>
                                        </Col>
                                    </Row>
                                    <Row className="border-top border-start border-end border-1 border-dark">
                                        <Col className="border-end border-1 border-dark p-0"><Form.Label className="m-auto">TUE</Form.Label></Col>
                                        <Col className="border-end border-1 border-dark p-0">
                                            <select className="schedule-select w-100 text-center" name="amIn" value={sched.Tue.amIn || ""}
                                                onChange={(e) => {
                                                    handleSchedChange(e, "Tue");
                                                }}>
                                                <option value={""}></option>
                                                {Object.keys(timeList).map((time, index) => (
                                                    <option key={index} value={timeList[time]}>{time}</option>
                                                ))}
                                            </select>
                                        </Col>
                                        <Col className="border-end border-1 border-dark p-0">
                                            <select className="schedule-select w-100 text-center bg-light" name="amOut" value={sched.Tue.amOut || ""}
                                                onChange={(e) => {
                                                    handleSchedChange(e, "Tue");
                                                }}>
                                                <option value={""}></option>
                                                {Object.keys(timeList).map((time, index) => (
                                                    <option key={index} value={timeList[time]}>{time}</option>
                                                ))}
                                            </select>
                                        </Col>
                                        <Col className="border-end border-1 border-dark p-0">
                                            <select className="schedule-select w-100 text-center" name="pmIn" value={sched.Tue.pmIn || ""}
                                                onChange={(e) => {
                                                    handleSchedChange(e, "Tue");
                                                }}>
                                                <option value={""}></option>
                                                {Object.keys(timeList).map((time, index) => (
                                                    <option key={index} value={timeList[time]}>{time}</option>
                                                ))}
                                            </select>
                                        </Col>
                                        <Col className="border-1 border-dark p-0">
                                            <select className="schedule-select w-100 text-center bg-light" name="pmOut" value={sched.Tue.pmOut || ""}
                                                onChange={(e) => {
                                                    handleSchedChange(e, "Tue");
                                                }}>
                                                <option value={""}></option>
                                                {Object.keys(timeList).map((time, index) => (
                                                    <option key={index} value={timeList[time]}>{time}</option>
                                                ))}
                                            </select>
                                        </Col>
                                    </Row>
                                    <Row className="border-top border-start border-end border-1 border-dark">
                                        <Col className="border-end border-1 border-dark p-0"><Form.Label className="m-auto">WED</Form.Label></Col>
                                        <Col className="border-end border-1 border-dark p-0">
                                            <select className="schedule-select w-100 text-center" name="amIn" value={sched.Wed.amIn || ""}
                                                onChange={(e) => {
                                                    handleSchedChange(e, "Wed");
                                                }}>
                                                <option value={""}></option>
                                                {Object.keys(timeList).map((time, index) => (
                                                    <option key={index} value={timeList[time]}>{time}</option>
                                                ))}
                                            </select>
                                        </Col>
                                        <Col className="border-end border-1 border-dark p-0">
                                            <select className="schedule-select w-100 text-center bg-light" name="amOut" value={sched.Wed.amOut || ""}
                                                onChange={(e) => {
                                                    handleSchedChange(e, "Wed");
                                                }}>
                                                <option value={""}></option>
                                                {Object.keys(timeList).map((time, index) => (
                                                    <option key={index} value={timeList[time]}>{time}</option>
                                                ))}
                                            </select>
                                        </Col>
                                        <Col className="border-end border-1 border-dark p-0">
                                            <select className="schedule-select w-100 text-center" name="pmIn" value={sched.Wed.pmIn || ""}
                                                onChange={(e) => {
                                                    handleSchedChange(e, "Wed");
                                                }}>
                                                <option value={""}></option>
                                                {Object.keys(timeList).map((time, index) => (
                                                    <option key={index} value={timeList[time]}>{time}</option>
                                                ))}
                                            </select>
                                        </Col>
                                        <Col className="border-1 border-dark p-0">
                                            <select className="schedule-select w-100 text-center bg-light" name="pmOut" value={sched.Wed.pmOut || ""}
                                                onChange={(e) => {
                                                    handleSchedChange(e, "Wed");
                                                }}>
                                                <option value={""}></option>
                                                {Object.keys(timeList).map((time, index) => (
                                                    <option key={index} value={timeList[time]}>{time}</option>
                                                ))}
                                            </select>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="border-top border-start border-end border-1 border-dark">
                                <Col className="border-end border-1 border-dark p-0"><Form.Label className="m-auto">THU</Form.Label></Col>
                                <Col className="border-end border-1 border-dark p-0">
                                    <select className="schedule-select w-100 text-center" name="amIn" value={sched.Thu.amIn || ""}
                                        onChange={(e) => {
                                            handleSchedChange(e, "Thu");
                                        }}>
                                        <option value={""}></option>
                                        {Object.keys(timeList).map((time, index) => (
                                            <option key={index} value={timeList[time]}>{time}</option>
                                        ))}
                                    </select>
                                </Col>
                                <Col className="border-end border-1 border-dark p-0">
                                    <select className="schedule-select w-100 text-center bg-light" name="amOut" value={sched.Thu.amOut || ""}
                                        onChange={(e) => {
                                            handleSchedChange(e, "Thu");
                                        }}>
                                        <option value={""}></option>
                                        {Object.keys(timeList).map((time, index) => (
                                            <option key={index} value={timeList[time]}>{time}</option>
                                        ))}
                                    </select>
                                </Col>
                                <Col className="border-end border-1 border-dark p-0">
                                    <select className="schedule-select w-100 text-center" name="pmIn" value={sched.Thu.pmIn || ""}
                                        onChange={(e) => {
                                            handleSchedChange(e, "Thu");
                                        }}>
                                        <option value={""}></option>
                                        {Object.keys(timeList).map((time, index) => (
                                            <option key={index} value={timeList[time]}>{time}</option>
                                        ))}
                                    </select>
                                </Col>
                                <Col className="border-1 border-dark p-0">
                                    <select className="schedule-select w-100 text-center bg-light" name="pmOut" value={sched.Thu.pmOut || ""}
                                        onChange={(e) => {
                                            handleSchedChange(e, "Thu");
                                        }}>
                                        <option value={""}></option>
                                        {Object.keys(timeList).map((time, index) => (
                                            <option key={index} value={timeList[time]}>{time}</option>
                                        ))}
                                    </select>
                                </Col>
                            </Row>
                            <Row className="border-top border-start border-end border-1 border-dark">
                                <Col className="border-end border-1 border-dark p-0"><Form.Label className="m-auto">FRI</Form.Label></Col>
                                <Col className="border-end border-1 border-dark p-0">
                                    <select className="schedule-select w-100 text-center" name="amIn" value={sched.Fri.amIn || ""}
                                        onChange={(e) => {
                                            handleSchedChange(e, "Fri");
                                        }}>
                                        <option value={""}></option>
                                        {Object.keys(timeList).map((time, index) => (
                                            <option key={index} value={timeList[time]}>{time}</option>
                                        ))}
                                    </select>
                                </Col>
                                <Col className="border-end border-1 border-dark p-0">
                                    <select className="schedule-select w-100 text-center bg-light" name="amOut" value={sched.Fri.amOut || ""}
                                        onChange={(e) => {
                                            handleSchedChange(e, "Fri");
                                        }}>
                                        <option value={""}></option>
                                        {Object.keys(timeList).map((time, index) => (
                                            <option key={index} value={timeList[time]}>{time}</option>
                                        ))}
                                    </select>
                                </Col>
                                <Col className="border-end border-1 border-dark p-0">
                                    <select className="schedule-select w-100 text-center" name="pmIn" value={sched.Fri.pmIn || ""}
                                        onChange={(e) => {
                                            handleSchedChange(e, "Fri");
                                        }}>
                                        <option value={""}></option>
                                        {Object.keys(timeList).map((time, index) => (
                                            <option key={index} value={timeList[time]}>{time}</option>
                                        ))}
                                    </select>
                                </Col>
                                <Col className="border-1 border-dark p-0">
                                    <select className="schedule-select w-100 text-center bg-light" name="pmOut" value={sched.Fri.pmOut || ""}
                                        onChange={(e) => {
                                            handleSchedChange(e, "Fri");
                                        }}>
                                        <option value={""}></option>
                                        {Object.keys(timeList).map((time, index) => (
                                            <option key={index} value={timeList[time]}>{time}</option>
                                        ))}
                                    </select>
                                </Col>
                            </Row>
                            <Row className="border-top border-bottom border-start border-end border-1 border-dark">
                                <Col className="border-end border-1 border-dark p-0"><Form.Label className="m-auto">SAT</Form.Label></Col>
                                <Col className="border-end border-1 border-dark p-0">
                                    <select className="schedule-select w-100 text-center" name="amIn" value={sched.Sat.amIn || ""}
                                        onChange={(e) => {
                                            handleSchedChange(e, "Sat");
                                        }}>
                                        <option value={""}></option>
                                        {Object.keys(timeList).map((time, index) => (
                                            <option key={index} value={timeList[time]}>{time}</option>
                                        ))}
                                    </select>
                                </Col>
                                <Col className="border-end border-1 border-dark p-0">
                                    <select className="schedule-select w-100 text-center bg-light" name="amOut" value={sched.Sat.amOut || ""}
                                        onChange={(e) => {
                                            handleSchedChange(e, "Sat");
                                        }}>
                                        <option value={""}></option>
                                        {Object.keys(timeList).map((time, index) => (
                                            <option key={index} value={timeList[time]}>{time}</option>
                                        ))}
                                    </select>
                                </Col>
                                <Col className="border-end border-1 border-dark p-0">
                                    <select className="schedule-select w-100 text-center" name="pmIn" value={sched.Sat.pmIn || ""}
                                        onChange={(e) => {
                                            handleSchedChange(e, "Sat");
                                        }}>
                                        <option value={""}></option>
                                        {Object.keys(timeList).map((time, index) => (
                                            <option key={index} value={timeList[time]}>{time}</option>
                                        ))}
                                    </select>
                                </Col>
                                <Col className="border-1 border-dark p-0">
                                    <select className="schedule-select w-100 text-center bg-light" name="pmOut" value={sched.Sat.pmOut || ""}
                                        onChange={(e) => {
                                            handleSchedChange(e, "Sat");
                                        }}>
                                        <option value={""}></option>
                                        {Object.keys(timeList).map((time, index) => (
                                            <option key={index} value={timeList[time]}>{time}</option>
                                        ))}
                                    </select>
                                </Col>
                            </Row>
                        </> :
                        sched[selectedDate[0].format("YYYY-MM-DD")] ?
                            <>
                                <Row className="mt-3">
                                    <Col>
                                        <Form.Label>Irregular Schedule</Form.Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Row className="border-top border-start border-end border-1 border-dark">
                                            <Col className="border-end border-1 border-dark">
                                                Date
                                            </Col>
                                            <Col className="border-end border-1 border-dark">
                                                <Form.Label className="m-auto">AM IN</Form.Label>
                                            </Col>
                                            <Col className="border-end border-1 border-dark bg-light">
                                                <Form.Label className="m-auto">AM OUT</Form.Label>
                                            </Col>
                                            <Col className="border-end border-1 border-dark">
                                                <Form.Label className="m-auto">PM IN</Form.Label>
                                            </Col>
                                            <Col className="bg-light">
                                                <Form.Label className="m-auto">PM OUT</Form.Label>
                                            </Col>
                                        </Row>
                                        {Object.keys(sched).map((key, iKey) => {
                                            let sch = sched[key];
                                            return (
                                                <Row key={iKey} className="border-top border-start border-end border-1 border-dark">
                                                    <Col className="border-end border-1 border-dark p-0"><Form.Label className="m-auto">{new DateObject(key).format('MM/DD/YYYY')}</Form.Label></Col>
                                                    <Col className="border-end border-1 border-dark p-0">
                                                        <select className="schedule-select w-100 text-center" name="amIn" value={sch.amIn || ""}
                                                            onChange={(e) => {
                                                                handleSchedChange(e, key);
                                                            }}>
                                                            <option value={""}></option>
                                                            {Object.keys(timeList).map((time, index) => (
                                                                <option key={index} value={timeList[time]}>{time}</option>
                                                            ))}
                                                        </select>
                                                    </Col>
                                                    <Col className="border-end border-1 border-dark p-0">
                                                        <select className="schedule-select w-100 text-center bg-light" name="amOut" value={sch.amOut || ""}
                                                            onChange={(e) => {
                                                                handleSchedChange(e, key);
                                                            }}>
                                                            <option value={""}></option>
                                                            {Object.keys(timeList).map((time, index) => (
                                                                <option key={index} value={timeList[time]}>{time}</option>
                                                            ))}
                                                        </select>
                                                    </Col>
                                                    <Col className="border-end border-1 border-dark p-0">
                                                        <select className="schedule-select w-100 text-center" name="pmIn" value={sch.pmIn || ""}
                                                            onChange={(e) => {
                                                                handleSchedChange(e, key);
                                                            }}>
                                                            <option value={""}></option>
                                                            {Object.keys(timeList).map((time, index) => (
                                                                <option key={index} value={timeList[time]}>{time}</option>
                                                            ))}
                                                        </select>
                                                    </Col>
                                                    <Col className="border-1 border-dark p-0">
                                                        <select className="schedule-select w-100 text-center bg-light" name="pmOut" value={sch.pmOut || ""}
                                                            onChange={(e) => {
                                                                handleSchedChange(e, key);
                                                            }}>
                                                            <option value={""}></option>
                                                            {Object.keys(timeList).map((time, index) => (
                                                                <option key={index} value={timeList[time]}>{time}</option>
                                                            ))}
                                                        </select>
                                                    </Col>
                                                </Row>
                                            )
                                        })}
                                        <Row className="border-top border-1 border-dark">
                                        </Row>
                                    </Col>
                                </Row>
                            </> : <></>}
                <Row className="mt-2">
                    <Col className="text-center">
                        <Button className="" variant="primary" onClick={(e) => {
                            confirmCreateSchedule();
                        }}>Save Schedule</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}