import React, { useEffect, useState, useRef } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup'
import axios from '../../../services/axios';
import Swal from 'sweetalert2';

export default function Page(props) {

    const [biometrics, setBiometrics] = useState([]);

    useEffect(() => {
        fetchBiometricList();
    }, [])

    const fetchBiometricList = async () => {
        Swal.fire({
            icon: 'info',
            text: 'Loading data...',
            showConfirmButton: false
        });
        await axios.get(`/api/biometrics/index`).then(({ data }) => {
            //console.log(data);
            if (data.result) {
                Swal.close();
                setBiometrics(data.biometrics);
            } else {
                Swal.update({
                    icon: "error",
                    text: data.message,
                    showConfirmButton: true
                });
            }
        }).catch((error) => {
            Swal.update({
                icon: "error",
                text: error.response.data.message,
                showConfirmButton: true
            })
        })
    }

    return (
        <Row className="m-0 p-1" style={{ height: "100%" }}>
            <Col className="m-0 p-0">
                <div className="card-body pt-0 pb-1 ps-0 pe-0 m-0 overflow-auto emp-list-container">
                    <ListGroup className="" variant="flush">
                        <ListGroup.Item>
                            <Row>
                                <Col className="fw-bold" >ID</Col>
                                <Col className="fw-bold" >Device Name</Col>
                                <Col className="fw-bold" >Location</Col>
                                <Col className="fw-bold" >Last Connection</Col>
                                <Col className="fw-bold" >Last Attendance</Col>
                            </Row>
                        </ListGroup.Item>
                        {biometrics.map((bio, key) => (
                            <ListGroup.Item key={key}>
                                <Row>
                                    <Col>{bio.id}</Col>
                                    <Col>{bio.name}</Col>
                                    <Col>{bio.location}</Col>
                                    <Col>{bio.last_connection}</Col>
                                    <Col>{bio.last_attendance}</Col>
                                </Row>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </div>
            </Col>
        </Row>
    )
}