import React, { useEffect, useState, useRef } from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup'
import axios from '../../../services/axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom'
import DatePicker, { Calendar } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import "react-multi-date-picker/styles/colors/red.css"

export default function CreateHolidayModal(props) {

    const [description, setDescription] = useState("");
    const [type, setType] = useState(1);
    const [date, setDate] = useState("");

    const saveHoliday = async () => {
        if (!validateFields()) {
            Swal.fire({
                showConfirmButton: false,
                text: 'Saving...'
            });
            await axios.post(`/api/holiday/save`, { description, type, date: date.format("YYYY-MM-DD") }).then(({ data }) => {
                Swal.update({
                    icon: "success",
                    text: data.message,
                    showConfirmButton: true
                });
                props.closeFetchCreate();
            }).catch((error) => {
                Swal.update({
                    icon: "error",
                    text: error.response.data.message,
                    showConfirmButton: true
                })
            })
        }
    }

    const validateFields = () => {
        let invalid = false;
        let requiredFields = document.getElementById("createHolidayForm").querySelectorAll("[required]");
        requiredFields.forEach((el) => {
            if (el.value == 0) {
                invalid = true;
                el.classList.add("is-invalid");
            } else {
                el.classList.remove("is-invalid");
            }
        })

        if (!date) {
            invalid = true;
        }

        return invalid;
    }

    return (
        <div id="createHolidayForm">
            <Row>
                <Col className="mb-2" sm={12}>
                    <Form.Label className="fw-bold">Description</Form.Label>
                    <Form.Control className="" name="description" as="input" placeholder="Description of Holiday/Suspension" value={description} required onChange={(event) => {
                        setDescription(event.target.value)
                    }} />
                </Col>
                <Col className="mb-2" sm={12}>
                    <Form.Label className="fw-bold">Select Type of Holiday</Form.Label>
                    <Form.Select name="type" required onChange={(event) => {
                        event.target.classList.remove("is-invalid");
                        setType(event.target.value);
                    }}>
                        <option value={1}>Holiday</option>
                        <option value={2}>Suspension</option>
                    </Form.Select>
                </Col>
                <Col className="mb-3" sm={12}>
                    <Form.Label className="fw-bold" >Date of Holiday/Suspension</Form.Label>
                    {/* <Calendar className="text-center"
                        id="date"
                        value={date} 
                        onChange={setDate}
                        plugins={[
                            <DatePanel sort="date" />
                        ]}
                    /> */}
                    <DatePicker
                        value={date}
                        onChange={setDate}
                        inputClass="form-control"
                        required
                    />
                </Col>
                <Col className="" sm={12}>
                    <div className="d-grid gap-2">
                        <button className="btn btn-primary" onClick={saveHoliday}>
                            Create
                        </button>
                    </div>
                </Col>
            </Row>
        </div>
    )
}