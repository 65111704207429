import React from 'react';

import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import { DateObject, Calendar} from "react-multi-date-picker";
// import { format, parseISO } from 'date-fns';

export default function TravelOrderModal({ showModal, setShowModal, dataModal }) {


  const handleClose = () => {
    setShowModal(false)
  }

  const datesArray = dataModal && dataModal.dates ? dataModal.dates.split(',').map(date => date.trim()) : [];

  const formatDate = (dateString) => {
      try {
      // Parse the dateString to a Date object and format it to "MM/dd/yyyy"
      return new DateObject(parseInt(dateString)).format("MM/dd/yyyy");
      // return format(parseISO(dateString), 'MM/dd/yyyy');
      } catch (error) {
      console.error('Error formatting date:', error);
      return ''; // Return an empty string or some placeholder in case of an error
      }
  };

  return (
    <>
      <Modal show={showModal} onHide={handleClose} size='xl'>
      <Modal.Header closeButton>
        <Modal.Title>Travel Order</Modal.Title>
      </Modal.Header>
      <Modal.Body style={modalBody}>
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <Card>
                <Card.Header>List of Participant</Card.Header>
                <Card.Body>
                  <div className="table-responsive">
                    <Table  bordered hover>
                    <tbody>
                      {
                      dataModal.participants && typeof dataModal.participants === 'string' ?
                        dataModal.participants.split(',').map((participant, index) => {
                          const rowId = index + 1;
                          return (
                            <tr key={index}>
                              <td>{rowId}</td>
                              <td>{participant.trim()}</td>
                            </tr>
                          );
                        })
                        : null
                      }
                    </tbody>

                    </Table>
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-8">
              <Card>
                <Card.Body>

                  <div className="row">
                    <div className="col-md-5">

                        <div className="mb-3 row">
                          <label className="col-sm-6 col-form-label fw-bold">DATE OF FILING</label>
                          <div className="col-sm-6">
                            <input type="text" className="form-control" value={dataModal && dataModal.created_at ? new DateObject(dataModal.created_at).format("YYYY/MM/DD") : ''} readOnly/>
                          </div>
                        </div>

                      </div>
                      
                      <div className="col-md-7">
                        <div className="mb-3 row">
                          <label className="col-sm-6 col-form-label fw-bold">TRAVEL ORDER NO.</label>
                          <div className="col-sm-6">
                            <input type="text" className="form-control" value={dataModal.pgo_to_no || ""} readOnly/>
                          </div>
                        </div>

                    </div>
                  </div>
                  

                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label fw-bold">OFFICE/HOSPITAL</label>
                    <div className="col-sm-9">
                      <input type="text" className="form-control" value={dataModal.offdesc || ""} readOnly/>
                    </div>
                  </div> 
                  
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label fw-bold">EVENT/PURPOSE</label>
                    <div className="col-sm-9">
                      <input type="text" className="form-control" value={dataModal.event || ""} readOnly/>
                    </div>
                  </div> 

                    <div className="row mb-3">
                      <label className="col-sm-4 col-form-label fw-bold">TRAVEL DATES </label>
                        <div className="col-sm-3">
                            
                                  <Calendar className="m-auto"
                                    multiple
                                    buttons={false} 
                                    value={datesArray}
                                    customDayStyle={customDayStyle}
                                  />   

                        </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="col-sm-4 col-form-label fw-bold">PERMANENT STATION</label>
                      <div className="col-sm-8">
                        <input type="text" className="form-control" value={dataModal.offdesc || ""} readOnly/>
                      </div>
                    </div> 
                    
                   <div className="mb-3 row">
                    <div className="row">
                       <div className="col-md-5"> 
                        <label className=" col-form-label fw-bold">PLACE/S TO BE VISITED <br/>(Please specify Itinerary)</label>
                      </div>
                      
                        <div className="col-md-7">
                          {
                            dataModal.places && typeof dataModal.places === 'string' ?
                              dataModal.places.split('/').map((place, index) => {
                              
                                return (
                                  <div key={index}>
                                    <input type="text" className="form-control mb-2" value={place.trim()} readOnly />
                                  </div>
                                );
                              })
                              : null
                            }
                         
                      </div>
                      
                    </div>
                  </div>
                  
                  <div className="mb-3 row">
                    <label className="col-sm-4 col-form-label fw-bold">ATTENDANCE REQUIREMENTS</label>
                    <div className="col-sm-8">
                      <input type="text" className="form-control" value={dataModal.att_required === 1 ? "REQUIRED" : dataModal.att_required === 0 ? "NOT REQUIRED" : ""} readOnly/>
                    </div>
                  </div>
                  
                  <div className="mb-3 row">
                    <label className="col-sm-4 col-form-label fw-bold">TRAVEL IS ON</label>
                    <div className="col-sm-8">
                      <input type="text" className="form-control" value={dataModal.travel === 1 ? "Official Time" : dataModal.travel === 0 ? "Official Business" : ""} readOnly/>
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="col-sm-4 col-form-label fw-bold">EXPENSES</label>
                    <div className="col-sm-8">
                      <input type="text" className="form-control" value={dataModal.charge === 1 ? "Others" : dataModal.charge === 0 ? "Office Funds" : ""} readOnly/>
                    </div>
                  </div>
                  
                  <div className="mb-3 row">
                    <label className="col-sm-4 col-form-label fw-bold"></label>
                    <div className="col-sm-8">
                      <input type="text" className="form-control" value={dataModal.charge === 1 ? dataModal.charge_specify : ""} readOnly/>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {/* Add footer content if needed */}
      </Modal.Footer>
    </Modal>
    </>
  )
}
const modalBody = {
    maxHeight: 'calc(100vh - 180px)',
    overflowY: 'auto',
};
 const customDayStyle = {
    backgroundColor: 'transparent', // set the background color to transparent to hide the circle
    border: 'none', // remove any border
  };