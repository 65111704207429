import React, { useState } from "react";
import { Link } from "react-router-dom";
import { appModules } from "../utilities/constants";
import { BoxArrowLeft } from 'react-bootstrap-icons';
import axios from "../services/axios";
import { Button, Collapse } from "react-bootstrap";

const Box = ({ items }) => {
  const [app, setApp] = useState("");
  const [module, setModule] =useState("")
  const logout = async () => {
    await axios
      .get("/api/logout")
      .then((response) => {window.location.reload()})
      .catch((error) => {
        //console.log(error);
      });
  };

  const handleLogout = (event) => {
    event.preventDefault();
    logout();
  };

  return (
    <div className="d-flex p-2 flex-column bg-white h-100">
      {items.map((item) => (
        <div key={item.title}>
          <div
            className="parentlink d-inline-flex align-items-center"
            onClick={() => setApp(item.title===app ? '' : item.title)}
            aria-expanded={app===item.title ? true : false}
            style={{ whiteSpace: "nowrap" }}
          >
            {item.title}
          </div>
          <Collapse in={app===item.title ? true : false}>
            <div id={item.title} >
              <div className="d-flex flex-column" style={{ paddingLeft: '1rem', paddingBottom: '.8rem' }}>
                {appModules.map((app) =>
                  item.abilities.map(
                    (ability) =>
                      app.id === ability.abilities_id && (
                        <Link to={app.url} style={{ height: '.7cm',  whiteSpace: "nowrap"}} key={app.id}>
                          <Button
                            className={
                              module === ability.description ? "cur" : ""
                            }
                            variant="outline-info"
                            size="sm"
                            onClick={() => setModule(ability.description)}
                            style={{  alignItems: 'center'}}
                          >
                            {app.icon}&nbsp;
                            {ability.description}
                          </Button>
                        </Link>
                      )
                  )
                )}
                {item.title==='ACCOUNT' && <div><Button
                            variant="outline-info"
                            size="sm"
                            onClick={handleLogout}
                            style={{ display: 'flex', height: '.7cm', alignContent: 'left', alignItems: 'center'}}
                          >
                          <BoxArrowLeft size={20} />&nbsp;Logout
                          </Button></div>}
              </div>
            </div>
          </Collapse>
        </div>
      ))}
    </div>
  );
};

export default Box;
