import React, { useEffect, useState, useRef } from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from '../../../services/axios';
import Swal from 'sweetalert2';
import DatePicker, { DateObject } from "react-multi-date-picker";
import Modal from 'react-bootstrap/Modal';
import ReactToPrint from 'react-to-print';

import EmployeeListSearchable from '../../Global/EmployeeListSearchable.component';

export default function Page(props) {

    const [selectedEmployee, setSelectedEmployee] = useState([]);
    const [empAbilities, setEmpAbilities] = useState(null);
    const [abilityList, setAbilityList] = useState([]);

    const setCheckSelectedEmployee = (emp) => {
        if (selectedEmployee !== emp) {
            setSelectedEmployee(emp);
        }
    }

    useEffect(() => {
        if (selectedEmployee.length > 0) {
            fetchEmployeeAbilities();
        }
    }, [selectedEmployee])

    useEffect(() => {
        if (abilityList.length === 0) {
            fetchAbilityList();
        }
    }, [])

    const fetchAbilityList = async () => {
        Swal.fire({
            icon: 'info',
            text: 'Loading data...',
            showConfirmButton: false
        });
        await axios.get(`/api/ability/list`).then(({ data }) => {
            //console.log('get ability list', data);
            if (data.result) {
                Swal.close();
                setAbilityList(data.abilities);
            } else {
                Swal.update({
                    icon: "error",
                    text: data.message,
                    showConfirmButton: true
                });
            }
        }).catch((error) => {
            Swal.update({
                icon: "error",
                text: error.response.data.message,
                showConfirmButton: true
            })
        })
    }

    const fetchEmployeeAbilities = async () => {
        let emp = selectedEmployee[0];
        if (emp !== null && emp.length !== 0) {
            Swal.fire({
                icon: 'info',
                text: 'Loading Ability...',
                showConfirmButton: false
            });
            await axios.get(`/api/ability/user/${emp.id}`).then(({ data }) => {
                //console.log('get user ability', data);
                if (data.result) {
                    Swal.close();
                    setEmpAbilities(data.abilities);
                } else {
                    Swal.update({
                        icon: "error",
                        text: data.message,
                        showConfirmButton: true
                    });
                }
            }).catch((error) => {
                Swal.update({
                    icon: "error",
                    text: error.response.data.message,
                    showConfirmButton: true
                })
            })
        }
    }

    const setAbility = (abi) => {
        let updateAbi = [...empAbilities];
        if (updateAbi.includes(abi)) {
            var index = updateAbi.indexOf(abi);
            if (index !== -1) {
                updateAbi.splice(index, 1);
            }
        } else {
            updateAbi.push(abi);
        }
        setEmpAbilities(updateAbi);
    }

    const saveAbilities = async () => {
        let emp = selectedEmployee[0];
        if (emp !== null && emp.length !== 0) {
            Swal.fire({
                icon: 'info',
                text: 'Saving Changes...',
                showConfirmButton: false
            });
            await axios.post(`/api/ability/user/${emp.id}`, { 'abilities': empAbilities }).then(({ data }) => {
                //console.log('save user ability', data);
                if (data.result) {
                    Swal.close();
                } else {
                    Swal.update({
                        icon: "error",
                        text: data.message,
                        showConfirmButton: true
                    });
                }
            }).catch((error) => {
                Swal.update({
                    icon: "error",
                    text: error.response.data.message,
                    showConfirmButton: true
                })
            })
        }
    }

    return (
        <>
            <Row className="m-0 p-2" style={{ height: "100%" }}>
                <Col className="ps-0 pe-1 pt-0 pb-0" sm="2" style={{ height: "100%" }}>
                    <EmployeeListSearchable
                        selectedEmployee={selectedEmployee}
                        setCheckSelectedEmployee={setCheckSelectedEmployee}
                        allowMultiple={false}
                        showUnique={true}
                    />
                </Col>
                <Col className="m-0 ps-0 pe-1 pt-0 pb-0" sm="10" style={{ height: "100%" }}>
                    {(selectedEmployee.length !== 0 && empAbilities !== null) ? <div className="card overflow-auto">
                        <div className="card-header">
                            <Row>
                                <Col sm="auto">
                                    {selectedEmployee[0].name}
                                </Col>
                                <Col>
                                    <Button size="sm" onClick={() => {
                                        saveAbilities();
                                    }}>Save</Button>
                                </Col>
                            </Row>
                        </div>
                        <div className="card-body">
                            <Row>
                                {abilityList.map((abi) => {
                                    return (<Col sm={4} key={abi.code}>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id={abi.code} checked={(empAbilities.includes(abi.code)) ? true : false} onChange={() => {
                                                setAbility(abi.code);
                                            }} />
                                            <label className="form-check-label" htmlFor={abi.code}>
                                                {abi.description}
                                            </label>
                                        </div>
                                    </Col>)
                                })}
                            </Row>
                        </div>
                    </div> : ""}
                </Col>
            </Row>
        </>
    )
}