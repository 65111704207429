import React, { useEffect, useState, useRef } from 'react';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup'
import axios from '../../../services/axios';
import Swal from 'sweetalert2';
import DatePicker, { DateObject } from "react-multi-date-picker";
import Button from 'react-bootstrap/Button'

import QueryFilter from '../../Global/QueryFilter.component';

export default function Page(props) {

    const [offices, setOffices] = useState([]);
    const [selectedOffice, setSelectedOffice] = useState('');
    const [selectedDate, setSelectedDate] = useState(new DateObject());

    const [undertimeMode, setUndertimeMode] = useState(1);

    const fetchOffices = async () => {
        await axios.get(`/api/office/index`).then(({ data }) => {
            setOffices(data.offices);
        })
    }

    const calculateUndertime = async () => {
        if (selectedOffice !== '') {
            Swal.fire({
                icon: 'info',
                text: 'Calculating Undertime...',
                showConfirmButton: false
            });

            await axios.post(`/api/trigger/calculate_undertime`, {
                office: selectedOffice,
                year: selectedDate.year,
                month: selectedDate.month.number,
                mode: parseInt(undertimeMode)
            }).then(({ data }) => {
                if (data.result) {
                    Swal.update({
                        icon: "success",
                        text: data.message,
                        showConfirmButton: true
                    })
                } else {
                    Swal.update({
                        icon: "error",
                        text: data.message,
                        showConfirmButton: true
                    })
                }
            }).catch((error) => {
                Swal.update({
                    icon: "error",
                    text: error.response.data.message,
                    showConfirmButton: true
                })
            })
        }
    }

    const handleSelectChange = (event, setFunction) => {
        setFunction(event.target.value);
    };

    useEffect(() => {
        fetchOffices();
    }, []);

    // useEffect(() => {
    //     console.log(selectedOffice, `${selectedDate.month}/${selectedDate.day}/${selectedDate.year}`, parseInt(undertimeMode));
    // }, [selectedOffice, selectedDate, undertimeMode]);

    return (
        <Row className="m-0 p-0" style={{ height: "100%" }}>
            <Col className="m-0 p-0">
                <Row className="cus-color-2 p-1 m-0">
                    <Col sm="auto">
                        <Form.Select className="query-input" onChange={event => { handleSelectChange(event, setSelectedOffice) }}>
                            <option value=''>Office</option>
                            {Object.entries(offices).map(([key, off], i) => {
                                return <option key={i} value={off.offcode}>{off.offdesc}</option>
                            })}
                        </Form.Select>
                    </Col>
                    <Col sm="auto">
                        <DatePicker
                            value={selectedDate}
                            onChange={setSelectedDate}
                            inputClass="form-control query-input"
                        />
                    </Col>
                </Row>
                <Row className="p-1 m-0">
                    <Col>
                        <Row>
                            <Col><Form.Label>Undertime Calculation</Form.Label></Col>
                        </Row>
                        <Row>
                            <Col sm="auto">
                                <Form.Select className="query-input" onChange={event => { handleSelectChange(event, setUndertimeMode) }}>
                                    <option value={1}>Permanent</option>
                                    <option value={2}>Non-Permanent 1st</option>
                                    <option value={3}>Non-Permanent 2nd</option>
                                </Form.Select>
                            </Col>
                            <Col sm="auto">
                                <Button variant="primary" size="md" onClick={calculateUndertime}>Run</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}