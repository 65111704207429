import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import './App.css';
import "bootstrap/dist/css/bootstrap.css";
import '@fortawesome/fontawesome-free/css/all.min.css';

const root = createRoot(document.getElementById('root'));

root.render(<App />);


