import Originalaxios from 'axios';

// Create a singleton instance of Axios with CSRF token logic
const axios = Originalaxios.create({
    // baseURL: 'http://localhost:8000',
    baseURL: 'https://backend.hris.pangasinan.io',
    headers: {
        'Accept': 'application/json',
    },
    withCredentials: true,
});

axios.interceptors.request.use(async (config) => {
    return config;
}, (error) => {
    //console.error('Error in request interceptor:', error);
    return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error.response && error.response.status === 401) {
        //console.error('API request failed:', error);
    } else {
        //console.error('API request failed (other reason):', error);
    }
    return Promise.reject(error);
});

export default axios;