import React from 'react';
import PageIndex from './PageIndex.component.js';

export default function Page(props) {

    return (
        <div className="content-wrapper">
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg">
                            <PageIndex user={props.user}
                                showEmployeeDetails={true}
                                allowPrint={false}
                            />
                        </section>
                    </div>
                </div>
            </section>
        </div>
    )
}