import React, { useEffect, useState, useRef } from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup'
import axios from '../../../services/axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom'
import DatePicker, { Calendar } from "react-multi-date-picker";
import "react-multi-date-picker/styles/colors/red.css"

export default function CreateCTOModal(props) {

    const [divisionName, setDivisionName] = useState("");
    const [divisionDesc, setDivisionDesc] = useState("");

    const validateFields = () => {
        let invalid = false;
        let requiredFields = document.getElementById("createHolidayForm").querySelectorAll("[required]");
        requiredFields.forEach((el) => {
            if (el.value == 0) {
                invalid = true;
                el.classList.add("is-invalid");
            } else {
                el.classList.remove("is-invalid");
            }
        })
        return invalid;
    }

    const saveDivision = async () => {
        if (!validateFields()) {
            Swal.fire({
                showConfirmButton: false,
                text: 'Saving...'
            });
            //console.log(divisionName, divisionDesc);
            await axios.post(`/api/division/create`,
                {
                    divisionName,
                    divisionDesc,
                    offcode : props.user.office.offcode
                }
            ).then(({ data }) => {
                Swal.update({
                    icon: "success",
                    text: data.message,
                    showConfirmButton: true
                });
                props.setDivisionList(data.divisionList);
                props.closeModal();
            }).catch((error) => {
                Swal.update({
                    icon: "error",
                    text: error.response.data.message,
                    showConfirmButton: true
                })
            })
        }
    }

    return (
        <div id="createHolidayForm">
            <Row>
                <Col className="">
                    <Form.Label className="fw-bold">Name</Form.Label>
                    <Form.Control className="" name="divisionName" as="input" placeholder="Enter the new Division's name" value={divisionName} required onChange={(event) => {
                        setDivisionName(event.target.value)
                    }} />
                </Col>
            </Row>
            <Row className="mt-2">
                <Col className="">
                    <Form.Label className="fw-bold">Description</Form.Label>
                    <Form.Control className="" name="divisionDesc" as="input" placeholder="Enter the new Division's description" value={divisionDesc} required onChange={(event) => {
                        setDivisionDesc(event.target.value)
                    }} />
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    <div className="d-grid gap-2">
                        <button className="btn btn-primary" onClick={saveDivision}>
                            Save
                        </button>
                    </div>
                </Col>
            </Row>
        </div>
    )
}