import React, { useEffect, useState, useRef } from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from '../../../services/axios';
import { useNavigate } from 'react-router-dom'
import { DateObject } from "react-multi-date-picker";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';

import QueryFilter from '../../Global/QueryFilter.component';
import CreateCTOModal from './CreateCTOModal.component';

export default function PageIndex(props) {
    //console.log(props.user);
    const navigate = useNavigate();
    const [ctos, setCTOs] = useState([]);
    //const [ctoArranged, setCTOsArranged] = useState({});
    const [showCTO, setShowCTOID] = useState(null);

    const [showCTOModal, setShowCTOModal] = useState(false);
    const openCTOModal = () => setShowCTOModal(true);
    const closeCTOModal = () => setShowCTOModal(false);

    const [filter, setFilter] = useState({});
    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(20);

    // useEffect(()=>{
    //     let lvgr = {};
    //     ctos.forEach(lv => {
    //         if(!(lv.office.offdesc in lvgr)){
    //             lvgr[lv.office.offdesc] = {};
    //         }
    //         let empName = lv.plantilla.FINAME+" "+lv.plantilla.MIDNAME+" "+lv.plantilla.SURNAME;
    //         if(!(empName in lvgr[lv.office.offdesc])){
    //             lvgr[lv.office.offdesc][empName] = [];
    //         }
    //         lvgr[lv.office.offdesc][empName].push(lv.id);
    //     });
    //     setLeavesArranged(lvgr);
    // }, [ctos])

    const fetchCTOs = async (filter) => {
        setFilter(filter);
        //setCTOsArranged({});
        await axios.post(`/api/office/compensatorytimeoff/index`, { ...filter, page, perPage, 'offCode': props.user.office.offcode }).then(({ data }) => {
            setCTOs(data.index);
            data.index.forEach(cto => {
                if (filter.status === 0) {
                    cto.checked = true;
                } else {
                    cto.checked = false;
                }
            });
            if (data.lastPage !== pages) {
                setPages(data.lastPage);
            }
        })
    }

    const approveCTOs = async ($action) => {
        let selected = [];

        for (const ctoK in ctos) {
            let cto = ctos[ctoK];
            if (cto.checked) {
                let id = cto.id
                selected.push(id);
            }
        }

        if (selected.length) {
            let data = {
                selected_cto: selected
            }
            if ($action === 1 || $action === 2) {
                data.action = $action;
            } else {
                data.action = 3;
            }
            Swal.fire({
                icon: "info",
                text: "Processing...",
                showConfirmButton: false
            })
            await axios.post(`/api/compensatorytimeoff/save`, data).then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    text: data.message
                }).then(() => {
                    fetchCTOs(filter);
                });
            })
        }
    }

    const componentRef = useRef();

    return (
        <>
            <Row className="page-index-controls cus-color-2">
                <Col className="p-1" sm="auto">
                    <Button className="" variant="primary" onClick={openCTOModal}>
                        Add COC
                    </Button>
                </Col>
                {/* {
                props.user.abilities.includes('off_cto') ? <>
                    <Col className="p-1" sm="auto">
                        <Button  className="" variant="success" onClick={() => {
                            approveCTOs(1)
                        }}>
                            Approve CTO
                        </Button>
                    </Col>
                    <Col className="p-1" sm="auto">
                        <Button  className="" variant="danger" onClick={() => {
                            approveCTOs(2)
                        }}>
                            Reject CTO
                        </Button>
                    </Col>
                </> : <></>
            } */}
                <Col className="p-1" sm="auto">
                    <Button className="" variant="danger" onClick={() => {
                        approveCTOs(3)
                    }}>
                        Cancel COC
                    </Button>
                </Col>
                <Col>
                    <QueryFilter
                        fetch={fetchCTOs}
                        perPage={perPage}
                        setPages={setPages}
                        page={page}
                        setPage={setPage}
                        inputs={{
                            status: {
                                input: 'select',
                                selectOptions: [
                                    { label: 'Status', value: '' },
                                    { label: 'Pending', value: 0 },
                                    { label: 'Disapproved', value: 1 },
                                    { label: 'Approved', value: 2 },
                                    { label: 'Cancelled', value: 3 }
                                ]
                            },
                            dateFrom: {
                                input: 'date',
                                defaultValue: new DateObject().format("YYYY-MM") + '-01'
                            },
                            dateTo: {
                                input: 'date'
                            },
                        }}
                    />
                </Col>
            </Row>

            <Modal show={showCTOModal} onHide={closeCTOModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Compensatory Overtime Credit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateCTOModal
                        user={props.user}
                        closeModal={closeCTOModal}
                    />
                </Modal.Body>
            </Modal>

            <div>
                {pages > 1 ?
                    <Row className="mt-2">
                        <Col className="text-center">
                            {page > 1 ? <Button size='sm' onClick={(e) => {
                                setPage(page => page - 1);
                            }}>{'<'}</Button> : ""}
                            <Form.Label className="ms-2 me-2">{`Pages ${page} of ${pages}`}</Form.Label>
                            {page === pages ? "" : <Button size='sm' onClick={(e) => {
                                setPage(page => page + 1);
                            }}>{'>'}</Button>}
                        </Col>
                    </Row>
                    : ''}

                <div className="card mt-2 mb-2">
                    <div className="card-body pt-0 pb-0">
                        <Row>
                            <Col>
                                <Row className="p-1 border-bottom border-1 fw-bold">
                                    <Col>Reason for COC</Col>
                                    <Col>Employee</Col>
                                    <Col className="text-center">Date</Col>
                                    <Col className="text-center">No. of Hours</Col>
                                    <Col className="text-center">Status</Col>
                                    <Col></Col>
                                </Row>
                                {
                                    Object.entries(ctos).map(([key, cto]) => {
                                        return (
                                            <Row className="p-1 border-bottom border-1" key={key} >
                                                <Col>{cto.cto_reason}</Col>
                                                <Col>{cto.employee.name}</Col>
                                                <Col className="text-center">{new DateObject(cto.created_at).format("MM/DD/YYYY")}</Col>
                                                <Col className="text-center">{cto.hours}</Col>
                                                <Col className="text-center">
                                                    <div className="progress mt-1" style={{ height: "20px" }}>
                                                        {cto.status ? <>
                                                            <div className={`progress-bar ${cto.off_approved !== 1 ? cto.off_approved === null ? "bg-warning" : "bg-danger" : "bg-success"}`} style={{ width: `${cto.off_approved === null ? 50 : 50}%` }}>Office</div>
                                                            <div className={`progress-bar ${cto.pgo_approved !== 1 ? cto.pgo_approved === null ? "bg-warning" : "bg-danger" : "bg-success"}`} style={{ width: `${cto.pgo_approved === null ? 50 : 50}%` }}>PGO</div>
                                                        </> : <div className="progress-bar bg-danger" style={{ width: "100%" }}>Cancelled</div>}
                                                    </div>
                                                </Col>
                                                <Col className="text-center">
                                                    <input className="form-check-input" type="checkbox" checked={cto.checked} onChange={() => {
                                                        let upCTOs = { ...ctos };
                                                        upCTOs[key].checked = !upCTOs[key].checked;
                                                        setCTOs(upCTOs);
                                                    }} />
                                                </Col>
                                            </Row>
                                        )
                                    })
                                }
                            </Col>
                        </Row>
                    </div>
                </div>
                {pages > 1 ?
                    <Row className="mb-2">
                        <Col className="text-center">
                            {page > 1 ? <Button size='sm' onClick={(e) => {
                                setPage(page => page - 1);
                            }}>{'<'}</Button> : ""}
                            <Form.Label className="ms-2 me-2">{`Pages ${page} of ${pages}`}</Form.Label>
                            {page === pages ? "" : <Button size='sm' onClick={(e) => {
                                setPage(page => page + 1);
                            }}>{'>'}</Button>}
                        </Col>
                    </Row>

                    : ''}
            </div>
        </>
    )
}